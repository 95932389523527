import router from '@/router';

const rteLinksDirective = {
    mounted(el: HTMLElement) {
        el.addEventListener('click', clickEventHandler);
    },
    onUnmounted(el: HTMLElement) {
        el.removeEventListener('click', clickEventHandler);
    },
};

function clickEventHandler(event: MouseEvent) {
    const anchorElem = (event.target as HTMLLinkElement | null);
    const href = anchorElem?.href;
    const anchor = anchorElem?.attributes ? anchorElem?.attributes['data-anchor']?.value : '';
    if (href && isInternalLink(href)) {
        event.preventDefault();
        router.push({ path: relativeHref(href), hash: anchor });
    }
}

function isInternalLink(href: string): boolean {
    try {
        return new URL(href).origin === location.origin;
    } catch {
        return false;
    }
}

function relativeHref(href: string): string {
    const url = new URL(href);
    let result = url.pathname;
    if (url.search) {
        const search = result.endsWith('/') ? url.search : `/${url.search}`;
        result += search;
    }
    return result;
}

export default rteLinksDirective;
