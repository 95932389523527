<template>
    <div class="flex flex-col p-20 h-full min-h-510 text-center">
        <div class="mt-auto font-fauli">
            <p class="my-20 text-28 md:my-0 md:text-32">
                {{ $translate('Product.TooLate.Expired') }}
            </p>
            <p class="mt-0 text-20 md:text-24">
                {{ $translate('Product.TooLate.BummerText') }}
            </p>
        </div>
        <div class="mt-auto mb-20 lg:mb-60">
            <CIcon name="feather-icon"
                   width="180px"
                   original
                   class="w-180"/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { trackError } from '../../tracking/tracking.service';

export default defineComponent({
    name: 'ProductExpired',
    components: {

    },
    setup() {
        trackError('Produktet ikke længere tilgængeligt');

        return {
        };
    },
});
</script>

<style scoped>

</style>
