<template>
    <div class="mt-auto w-full h-full">
        <NotFound/>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NotFound from '@/project/content/NotFound.vue';

export default defineComponent({
    name: '404Page',
    components: {
        NotFound,
    },
});
</script>

<style scoped>
    .error-page {
        height: 100vh;
        @apply flex justify-center items-center
    }

    .text {
        font-size: 25rem;
        @apply font-bold;
    }

</style>
