import { App } from 'vue';
import { init as initLogging } from '@/core/infrastructure/logging';
import { config as dictionaryConfig } from './dictionary/dictionary';
import responsiveConfig from './responsive';
import renderingConfig from './rendering';
import layoutConfig from './layout';
import formsConfig from './forms';
import navigationConfig from './navigation';
import domConfig from './dom';

export default async function config(app: App): Promise<void> {
    await initLogging();
    dictionaryConfig(app);
    responsiveConfig(app);
    formsConfig(app);
    domConfig(app);
    layoutConfig(app);
    navigationConfig(app);
    renderingConfig(app);
}
