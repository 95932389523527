<template>
    <DrawerDialog :title="$translate('Basket.Title')"
                  position="right"
                  :use-slide-animation="true">
        <div v-if="clientBasket.lineItems.length"
             class="line-items-wrap">
            <TransitionGroup name="fade"
                             mode="out-in">
                <LineItem v-for="(lineItem) in clientBasket.lineItems"
                          :key="lineItem.sku"
                          :line-item="lineItem"
                          class="mr-8 mb-20 md:mr-0"/>
            </TransitionGroup>
        </div>
        <div v-else
             class="flex flex-col w-full h-full text-18 lg:text-24">
            {{ $translate('Basket.EmptyBasketText') }}
            <CIcon v-if="culture"
                   :name="getEmptyBasketGraphics()"
                   title="unikmand"
                   class="m-auto mt-100 w-100"
                   original/>
        </div>
        <div v-if="clientBasket.lineItems.length"
             class="flex mt-auto">
            <CIcon name="unik-timer-small"
                   class="w-70 h-70"
                   original/>
            <div class="ml-15 md:max-w-330">
                <div class="mt-10 mb-20 font-fauli text-10 md:text-14">
                    <div>{{ $translate('Basket.Notice') }}!</div>
                    <div>{{ $translate('Basket.Info', reservation?.minutesToReserve.toString() ?? '') }}</div>
                </div>
            </div>
        </div>
        <div v-if="clientBasket.lineItems.length"
             class="flex flex-col mb-10 md:mb-30">
            <TransitionRoot :appear="true"
                            enter="transition-opacity duration-200"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="transition-opacity duration-200"
                            leave-from="opacity-100"
                            leave-to="opacity-0">
                <div v-if="isAllProductsSoldOut"
                     class="mb-20 font-fauli text-red-100">
                    {{ $translate('Basket.SoldOut') }}
                </div>
                <div v-else-if="isAllProductsReserved"
                     class="mb-20 font-fauli text-red-100">
                    <span v-html="$translate('Basket.AllReserved')"></span>
                </div>
                <div v-else-if="reservationErrorItemsInBasket.length"
                     class="mb-20 font-fauli text-red-100">
                    <span v-html="$translate('Basket.ReservationError')"></span>
                </div>
            </TransitionRoot>
            <div class="my-15">
                <div class="flex items-center font-fauli">
                    <div class="mr-auto text-16 md:text-24">
                        {{ $translate('Basket.SubtotalTitle') }}:
                    </div>
                    <div class="ml-auto text-26 md:text-34">
                        <Price :amount="clientBasket.subTotal"
                               :currency="clientBasket.currency"
                               :show-formatted-amount="true"/>
                    </div>
                </div>
                <div class="font-fauli text-12 md:text-16">
                    {{ $translate('Basket.ShippingPriceText') }}
                </div>
            </div>
            <CallToAction :disabled="isDisabled"
                          variant="PrimaryButton"
                          class="mt-[.6rem] mb-[1.7rem] text-20"
                          @click="goToCheckout()">
                {{ $translate('Basket.ButtonText') }}
            </CallToAction>
            <div v-if="showPaymentIcons"
                 class="flex flex-wrap justify-center items-center">
                <img v-for="(paymentIcon, index) in paymentIcons"
                     :key="index"
                     :src="paymentIcon.url"
                     class="mr-5 last:mr-0"
                     :alt="paymentIcon.name"
                     :title="paymentIcon.name"
                     width="50"
                     height="32"
                     loading="lazy">
            </div>
        </div>
        <CIcon name="minibasket-unikmand-body"
               original
               class="absolute top-0 left-0 -z-2 mt-[4.8rem] ml-[-6.6rem] w-[15.7rem] md:mt-[.3rem] md:ml-[-10.5rem] md:w-[25.1rem]"/>
        <CIcon name="minibasket-unikmand-head"
               original
               class="absolute top-0 left-0 mt-[4.5rem] ml-[-6.8rem] w-[9rem] md:mt-0 md:ml-[-10.8rem] md:w-[14.2rem]"/>
    </DrawerDialog>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import DrawerDialog from '../dialog/DrawerDialog.vue';
import LineItem from './LineItem.vue';
import { clientBasket, reservationErrors, reserveBasket } from '@/project/basket/basket.service';
import { TransitionRoot } from '@headlessui/vue';
import { closeDialog } from '@/project/dialog/dialog';
import { ReservationError, ReserveBasketViewModel } from '@/api/checkout';
import { PageNames } from '@/router';
import { useRouter } from 'vue-router';
import Price from '@/project/basket/Price.vue';
import CIcon from '../../core/layout/icons/CIcon.vue';
import { NameAndUrl, Reservation } from '@/api/content';
import content from '@/project/content/api/contentApi';
import { getCultureSettings } from '@/project/content/api/contentApi';

export default defineComponent({
    name: 'MiniBasketDialog',
    components: { DrawerDialog, LineItem, TransitionRoot, Price, CIcon },
    setup() {
        const busy = ref(false);
        const router = useRouter();
        const isAllProductsReserved = computed(() => {
            return clientBasket.value?.isAllProductsReserved || false;
        });

        const isAllProductsSoldOut = computed(() => {
            return clientBasket.value?.isAllProductsSoldOut || false;
        });

        const isDisabled = computed<boolean>(() => {
            return isAllProductsReserved.value || isAllProductsSoldOut.value || busy.value || !clientBasket.value?.lineItems.length || !!reservationErrorItemsInBasket.value.length;
        });

        const reservationErrorItemsInBasket = computed(() => {
            const items: ReservationError[] = [];
            clientBasket.value?.lineItems.forEach((l) => {
                const item = reservationErrors.value.find(e => e.sku === l.sku && l.quantity > e.availableQuantity);
                if (item) {
                    items.push(item);
                }
                busy.value = !!items.length;
            });

            return items;
        });

        const reservation = ref<Reservation>();
        const paymentIcons = ref<NameAndUrl[]>();
        const showPaymentIcons = ref<boolean>(false);

        content.settings.then(siteSettings => {
            if (siteSettings.reservation) {
                reservation.value = siteSettings.reservation;
            }
            if (siteSettings.paymentIconsCheckout) {
                paymentIcons.value = siteSettings.paymentIconsCheckout.paymentIcons?.map(p => p as NameAndUrl);
                showPaymentIcons.value = siteSettings.paymentIconsCheckout.showOnMiniBasket;
            }
        });

        function goToCheckout() {
            busy.value = true;
            reserveBasket().then((response: ReserveBasketViewModel | void) => {
                if (response && response.isReservationSuccessful) {
                    router.push({ name: PageNames.CheckoutInformation });
                    closeDialog();
                }
            }).finally(() => {
                busy.value = false;
            });
        }

        const culture = ref('');

        getCultureSettings().then(settings => {
            culture.value = settings.culture;
        });

        function getEmptyBasketGraphics() {
            return `mini-basket-dialog-unik_${culture.value}`;
        }

        return {
            clientBasket,
            isAllProductsReserved,
            isAllProductsSoldOut,
            goToCheckout,
            isDisabled,
            reservationErrorItemsInBasket,
            reservation,
            getEmptyBasketGraphics,
            culture,
            showPaymentIcons,
            paymentIcons,
        };
    },
});
</script>

<style scoped>
    .line-items-wrap {
        @apply w-full overflow-y-auto h-full pt-32 xl:pt-47;

        &:after {
            content: '';
            height: 10rem;
            width: 100%;
            background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1)) left repeat;
            bottom: 0;
            @apply block sticky;
        }
    }
    .fade-enter-active {
        transition: opacity 0.3s ease-out;
    }

    .fade-leave-active {
        transition: opacity 0.3s ease-in;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
