/* tslint:disable */
/* eslint-disable */
/**
 * Normal Commerce API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddOrUpdateLineItemsRequest } from '../models';
// @ts-ignore
import { BasketViewModelClientResponse } from '../models';
// @ts-ignore
import { CheckoutStep } from '../models';
// @ts-ignore
import { ClientResponse } from '../models';
// @ts-ignore
import { CompleteCheckoutRequest } from '../models';
// @ts-ignore
import { CreateGiftCardBasketRequest } from '../models';
// @ts-ignore
import { PrepareCompleteCheckoutRequest } from '../models';
// @ts-ignore
import { ReservationStatusViewModelClientResponse } from '../models';
// @ts-ignore
import { ReserveBasketRequest } from '../models';
// @ts-ignore
import { ReserveBasketViewModelClientResponse } from '../models';
// @ts-ignore
import { SetAddressRequest } from '../models';
/**
 * Checkout - axios parameter creator
 * @export
 */
export const CheckoutAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddOrUpdateLineItemsRequest} [addOrUpdateLineItemsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutAddOrUpdateLineItemsPost: async (addOrUpdateLineItemsRequest?: AddOrUpdateLineItemsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/AddOrUpdateLineItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrUpdateLineItemsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompleteCheckoutRequest} [completeCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutCompleteCheckoutPost: async (completeCheckoutRequest?: CompleteCheckoutRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/CompleteCheckout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeCheckoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateGiftCardBasketRequest} [createGiftCardBasketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutCreateGiftCardBasketPost: async (createGiftCardBasketRequest?: CreateGiftCardBasketRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/CreateGiftCardBasket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGiftCardBasketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {CheckoutStep} [xCheckoutStep] 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutGetBasketGet: async (basketId?: string, xCheckoutStep?: CheckoutStep, culture?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/GetBasket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (basketId !== undefined) {
                localVarQueryParameter['basketId'] = basketId;
            }

            if (culture !== undefined) {
                localVarQueryParameter['culture'] = culture;
            }

            if (xCheckoutStep !== undefined && xCheckoutStep !== null) {
                localVarHeaderParameter['x-CheckoutStep'] = String(JSON.stringify(xCheckoutStep));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutGetCompletedBasketGet: async (basketId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/GetCompletedBasket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (basketId !== undefined) {
                localVarQueryParameter['basketId'] = basketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutGetReservationStatusGet: async (basketId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/GetReservationStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (basketId !== undefined) {
                localVarQueryParameter['basketId'] = basketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [methodName] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutLogDataPost: async (methodName?: string, body?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/LogData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (methodName !== undefined) {
                localVarQueryParameter['methodName'] = methodName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutPingNavGet: async (culture?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/PingNav`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (culture !== undefined) {
                localVarQueryParameter['culture'] = culture;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PrepareCompleteCheckoutRequest} [prepareCompleteCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutPrepareCompleteCheckoutPost: async (prepareCompleteCheckoutRequest?: PrepareCompleteCheckoutRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/PrepareCompleteCheckout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prepareCompleteCheckoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReserveBasketRequest} [reserveBasketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutReserveBasketPost: async (reserveBasketRequest?: ReserveBasketRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/ReserveBasket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reserveBasketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetAddressRequest} [setAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutUpdateAddressPost: async (setAddressRequest?: SetAddressRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Checkout/UpdateAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Checkout - functional programming interface
 * @export
 */
export const CheckoutFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckoutAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddOrUpdateLineItemsRequest} [addOrUpdateLineItemsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutAddOrUpdateLineItemsPost(addOrUpdateLineItemsRequest?: AddOrUpdateLineItemsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutAddOrUpdateLineItemsPost(addOrUpdateLineItemsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompleteCheckoutRequest} [completeCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutCompleteCheckoutPost(completeCheckoutRequest?: CompleteCheckoutRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutCompleteCheckoutPost(completeCheckoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateGiftCardBasketRequest} [createGiftCardBasketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutCreateGiftCardBasketPost(createGiftCardBasketRequest?: CreateGiftCardBasketRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutCreateGiftCardBasketPost(createGiftCardBasketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {CheckoutStep} [xCheckoutStep] 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutGetBasketGet(basketId?: string, xCheckoutStep?: CheckoutStep, culture?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutGetBasketGet(basketId, xCheckoutStep, culture, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutGetCompletedBasketGet(basketId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutGetCompletedBasketGet(basketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutGetReservationStatusGet(basketId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationStatusViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutGetReservationStatusGet(basketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [methodName] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutLogDataPost(methodName?: string, body?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutLogDataPost(methodName, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutPingNavGet(culture?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutPingNavGet(culture, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PrepareCompleteCheckoutRequest} [prepareCompleteCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutPrepareCompleteCheckoutPost(prepareCompleteCheckoutRequest?: PrepareCompleteCheckoutRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutPrepareCompleteCheckoutPost(prepareCompleteCheckoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReserveBasketRequest} [reserveBasketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutReserveBasketPost(reserveBasketRequest?: ReserveBasketRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReserveBasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutReserveBasketPost(reserveBasketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetAddressRequest} [setAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckoutUpdateAddressPost(setAddressRequest?: SetAddressRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckoutUpdateAddressPost(setAddressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Checkout - factory interface
 * @export
 */
export const CheckoutFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckoutFp(configuration)
    return {
        /**
         * 
         * @param {AddOrUpdateLineItemsRequest} [addOrUpdateLineItemsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutAddOrUpdateLineItemsPost(addOrUpdateLineItemsRequest?: AddOrUpdateLineItemsRequest, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiCheckoutAddOrUpdateLineItemsPost(addOrUpdateLineItemsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompleteCheckoutRequest} [completeCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutCompleteCheckoutPost(completeCheckoutRequest?: CompleteCheckoutRequest, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiCheckoutCompleteCheckoutPost(completeCheckoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateGiftCardBasketRequest} [createGiftCardBasketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutCreateGiftCardBasketPost(createGiftCardBasketRequest?: CreateGiftCardBasketRequest, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiCheckoutCreateGiftCardBasketPost(createGiftCardBasketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {CheckoutStep} [xCheckoutStep] 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutGetBasketGet(basketId?: string, xCheckoutStep?: CheckoutStep, culture?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiCheckoutGetBasketGet(basketId, xCheckoutStep, culture, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutGetCompletedBasketGet(basketId?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiCheckoutGetCompletedBasketGet(basketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutGetReservationStatusGet(basketId?: string, options?: any): AxiosPromise<ReservationStatusViewModelClientResponse> {
            return localVarFp.apiCheckoutGetReservationStatusGet(basketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [methodName] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutLogDataPost(methodName?: string, body?: any, options?: any): AxiosPromise<void> {
            return localVarFp.apiCheckoutLogDataPost(methodName, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutPingNavGet(culture?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCheckoutPingNavGet(culture, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PrepareCompleteCheckoutRequest} [prepareCompleteCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutPrepareCompleteCheckoutPost(prepareCompleteCheckoutRequest?: PrepareCompleteCheckoutRequest, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiCheckoutPrepareCompleteCheckoutPost(prepareCompleteCheckoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReserveBasketRequest} [reserveBasketRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutReserveBasketPost(reserveBasketRequest?: ReserveBasketRequest, options?: any): AxiosPromise<ReserveBasketViewModelClientResponse> {
            return localVarFp.apiCheckoutReserveBasketPost(reserveBasketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetAddressRequest} [setAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckoutUpdateAddressPost(setAddressRequest?: SetAddressRequest, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiCheckoutUpdateAddressPost(setAddressRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Checkout - interface
 * @export
 * @interface Checkout
 */
export interface CheckoutInterface {
    /**
     * 
     * @param {AddOrUpdateLineItemsRequest} [addOrUpdateLineItemsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutAddOrUpdateLineItemsPost(addOrUpdateLineItemsRequest?: AddOrUpdateLineItemsRequest, options?: any): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @param {CompleteCheckoutRequest} [completeCheckoutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutCompleteCheckoutPost(completeCheckoutRequest?: CompleteCheckoutRequest, options?: any): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @param {CreateGiftCardBasketRequest} [createGiftCardBasketRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutCreateGiftCardBasketPost(createGiftCardBasketRequest?: CreateGiftCardBasketRequest, options?: any): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @param {string} [basketId] 
     * @param {CheckoutStep} [xCheckoutStep] 
     * @param {string} [culture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutGetBasketGet(basketId?: string, xCheckoutStep?: CheckoutStep, culture?: string, options?: any): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @param {string} [basketId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutGetCompletedBasketGet(basketId?: string, options?: any): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @param {string} [basketId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutGetReservationStatusGet(basketId?: string, options?: any): AxiosPromise<ReservationStatusViewModelClientResponse>;

    /**
     * 
     * @param {string} [methodName] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutLogDataPost(methodName?: string, body?: any, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} [culture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutPingNavGet(culture?: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {PrepareCompleteCheckoutRequest} [prepareCompleteCheckoutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutPrepareCompleteCheckoutPost(prepareCompleteCheckoutRequest?: PrepareCompleteCheckoutRequest, options?: any): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @param {ReserveBasketRequest} [reserveBasketRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutReserveBasketPost(reserveBasketRequest?: ReserveBasketRequest, options?: any): AxiosPromise<ReserveBasketViewModelClientResponse>;

    /**
     * 
     * @param {SetAddressRequest} [setAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutInterface
     */
    apiCheckoutUpdateAddressPost(setAddressRequest?: SetAddressRequest, options?: any): AxiosPromise<BasketViewModelClientResponse>;

}

/**
 * Checkout - object-oriented interface
 * @export
 * @class Checkout
 * @extends {BaseAPI}
 */
export class Checkout extends BaseAPI implements CheckoutInterface {
    /**
     * 
     * @param {AddOrUpdateLineItemsRequest} [addOrUpdateLineItemsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutAddOrUpdateLineItemsPost(addOrUpdateLineItemsRequest?: AddOrUpdateLineItemsRequest, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutAddOrUpdateLineItemsPost(addOrUpdateLineItemsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompleteCheckoutRequest} [completeCheckoutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutCompleteCheckoutPost(completeCheckoutRequest?: CompleteCheckoutRequest, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutCompleteCheckoutPost(completeCheckoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateGiftCardBasketRequest} [createGiftCardBasketRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutCreateGiftCardBasketPost(createGiftCardBasketRequest?: CreateGiftCardBasketRequest, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutCreateGiftCardBasketPost(createGiftCardBasketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [basketId] 
     * @param {CheckoutStep} [xCheckoutStep] 
     * @param {string} [culture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutGetBasketGet(basketId?: string, xCheckoutStep?: CheckoutStep, culture?: string, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutGetBasketGet(basketId, xCheckoutStep, culture, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [basketId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutGetCompletedBasketGet(basketId?: string, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutGetCompletedBasketGet(basketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [basketId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutGetReservationStatusGet(basketId?: string, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutGetReservationStatusGet(basketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [methodName] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutLogDataPost(methodName?: string, body?: any, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutLogDataPost(methodName, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [culture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutPingNavGet(culture?: string, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutPingNavGet(culture, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrepareCompleteCheckoutRequest} [prepareCompleteCheckoutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutPrepareCompleteCheckoutPost(prepareCompleteCheckoutRequest?: PrepareCompleteCheckoutRequest, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutPrepareCompleteCheckoutPost(prepareCompleteCheckoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReserveBasketRequest} [reserveBasketRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutReserveBasketPost(reserveBasketRequest?: ReserveBasketRequest, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutReserveBasketPost(reserveBasketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetAddressRequest} [setAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Checkout
     */
    public apiCheckoutUpdateAddressPost(setAddressRequest?: SetAddressRequest, options?: any) {
        return CheckoutFp(this.configuration).apiCheckoutUpdateAddressPost(setAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
