<template>
    <div>
        <div class="flex flex-col gap-15 mb-7">
            <div class=" flex gap-15 items-center">
                <CallToAction variant="SecondaryButton"
                              class="min-w-200"
                              @click.prevent="onClick">
                    {{ $translate("Sponsorformular.ChooseFileButtonText") }}
                </CallToAction>
                <div class="flex flex-wrap">
                    <div v-for="file in files"
                         :key="file.name"
                         class="flex px-10 w-180 font-fauli text-left hover:scale-105 cursor-pointer"
                         @click="e => removeFile(file)">
                        <div class="flex flex-col items-start"
                             :class="{ 'text-red-100': file.size > 20000000 }">
                            {{ file.name }}
                            <span class="text-[10px] opacity-50"> ({{ formatFileSize(file.size) }})</span>
                        </div>
                        <div class="relative -right-2 bottom-2 text-pink-100">
                            X
                        </div>
                    </div>
                </div>
                <span v-if="files.length === 0"
                      class="font-fauli text-center opacity-50">{{
                          $translate("Sponsorformular.NoFiles") }}</span>
            </div>
        </div>
        <span v-if="filesHasBeenOverwritten"
              class="font-fauli">
            {{ $translate("Forms.FilesHasBeenOverwritten") }}
            <br>
        </span>
        <span v-if="totalMb > 20"
              class="font-fauli text-red-100">
            {{ $translate("Sponsorformular.ToBigOfAFilePackage") }}
        </span>
        <span v-if="fileExtensionError"
              class="font-fauli text-center text-red-100 opacity-50">{{
                  $translate("Sponsorformular.NotSupportedFile") }}: {{ fileExtensionError }}</span>
        <label :id="name"
               :for="name"></label>
        <Field :name="name">
            <input ref="inputRef"
                   :name="name"
                   type="file"
                   :accept="allowedFilesExtensions.join(', ')"
                   :multiple="multiple"
                   onclick="this.value=null"
                   class="hidden"
                   @change="onFileUpload">
        </Field>
    </div>
</template>

<script lang="ts" setup>
import { Field, useField } from 'vee-validate';
import { computed, ref } from 'vue';
import contentApi from '../content/api/contentApi';
import { formsPayloadLogging } from '@/project/basket/basket.service';
import CallToAction from '../navigation/call-to-action/CallToAction.vue';
import bus from '@/core/bus';
import { UploadFileError } from '@/project/forms/forms-services/forms.service';

type Props = {
    multiple: boolean;
    name: string;
}

const props = defineProps<Props>();

const inputRef = ref<HTMLInputElement | null>(null);
const files = ref<File[]>([]);
const allowedFilesExtensions = ref<string[]>(['.jpg']);
const fileExtensionError = ref<string | null>(null);
const filesHasBeenOverwritten = ref<boolean | null>(false);

contentApi.settings.then((settings) => {
    allowedFilesExtensions.value = settings.acceptedFileExtensions;
});

const { value } = useField(props.name);


const onClick = () => {
    if (!inputRef.value) {
        return;
    }

    inputRef.value?.click();
};

const totalMb = computed(() => {
    const size = files.value.reduce((acc, current) => acc + current.size, 0) / 1000 / 1000;
    if (size > 20) {
        bus.emit(UploadFileError, true);
    } else {
        bus.emit(UploadFileError, false);
    }
    return size;
});

const formatFileSize = (size: number) => {
    const mb = size / 1000 / 1000;
    const rounded = Math.round(mb);
    return rounded > 0 ? `${rounded} MB` : '< 1 MB';
};

const onFileUpload = async(e: Event) => {
    const target = e.target as HTMLInputElement;
    let text = '';

    fileExtensionError.value = null; // clear when uploading new files

    if (target && target.files) {
        for (let index = 0; index < target.files.length; index++) {
            const file = target.files[index];
            text += 'File: ' + file.name + ' size: ' + file.size + ' ';
            const lastDotIndex = file.name.lastIndexOf('.');
            const fileExtension = file.name.substring(lastDotIndex).toLocaleLowerCase();


            if (fileTypeIsAllowed(fileExtension)) {
                files.value.push(file);
                bus.emit(UploadFileError, false);
            } else {
                bus.emit(UploadFileError, true);
                fileExtensionError.value = fileExtension;
            }
        }
    }

    await formsPayloadLogging('On file upload (InputFile.vue)', text);

    bus.emit('TempFileCounterInput', files.value.length);

    value.value = files.value;
};

const removeFile = async(file: File) => {
    files.value = files.value.filter((f) => f.name !== file.name);

    value.value = files.value;

    if (files.value.length <= 0) {
        filesHasBeenOverwritten.value = false;
    }

    let loggingText = 'Files: ';
    files.value.forEach(file => {
        loggingText += 'filename: ' + file.name + ', file size: ' + file.size + ' ';
    });

    await formsPayloadLogging('Removed file (InputFile.vue)', loggingText);
    bus.emit('TempFileCounterInput', files.value.length);
};

const fileTypeIsAllowed = (extension: string) => {
    return allowedFilesExtensions.value.includes(extension);
};

</script>
