<template>
    <div class="p-20 text-center">
        <div class="mt-50 mb-40 font-fauli">
            <p class="mt-0 text-32">
                {{ $translate('Product.TooEarly.NotStartedYet') }}
            </p>
            <p class="mt-0 text-29">
                {{ $translate('Product.TooEarly.NotStartedYetText') }}
            </p>
        </div>
        <div class="mb-40 md:mb-0">
            <div v-if="startsIn"
                 class="flex justify-center font-fauli">
                <Countdown key="countdown"
                           :starts-in="startsIn"
                           :checkout-mode="false"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { trackError } from '../../tracking/tracking.service';
import Countdown from '@/project/countdown/Countdown.vue';

export default defineComponent({
    name: 'ProductNotStarted',
    components: {
        Countdown,
    },
    props: {
        startsIn: {
            type: Object as PropType<number | null>,
            required: true,
        },
    },
    setup() {
        trackError('Produktet er ikke tilgængeligt endnu');
    },
});
</script>

<style scoped>
.time {
    margin-top: 6px;
    margin-left: 8px;
    letter-spacing: 18px;
    @apply text-20 absolute mb-5;
}
.timer-icon {
    stroke: #000000;
}
.time-text {
    @apply block mt-5
}
</style>
