import { ref, watch } from 'vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { Breakpoint } from '../config/breakpoints.config';

const { activeBreakpoint } = useBreakpoints();
const isMobileView = ref(false);

export { useResponsive };

function useResponsive() {
    watch(activeBreakpoint, (abp) => {
        isMobileView.value = abp === Breakpoint.XS || abp === Breakpoint.SM;
    }, { immediate: true });

    return {
        isMobileView,
    };
}
