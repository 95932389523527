<template>
    <article class="flex flex-col px-10 mx-auto mb-30 max-w-800">
        <div v-if="stores">
            <Map :stores="stores"
                 :today="$translate('Stores.Today')"
                 :map-link="$translate('Stores.MapLink')"
                 :initial-coordinates="initialCoordinates"/>

            <div class="pt-30 pb-20 text-center">
                <h2 class="mb-2">
                    {{ $translate('Stores.Title') }}
                </h2>
                <p class="text-14">
                    {{ $translate('Stores.Subtitle') }}
                </p>
            </div>

            <InputText v-model="term"
                       name="search"
                       :show-as-required="false"
                       :label="$translate('Accessibility.Stores.SearchPlaceholder.Label')"
                       :placeholder="$translate('Stores.SearchPlaceholder')"
                       class="my-20 mx-auto w-1/2"/>
        </div>

        <Store v-for="(store, storeIndex) in stores"
               :key="storeIndex"
               :store="store"/>
        <div v-if="stores?.length"
             class="divider">
        </div>
    </article>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from 'vue';
import { getStores } from '@/project/stores/stores.service';
import { StoreViewObject, StoresBlock, OpeningHourDayViewObject, ContentElementWithSettings } from '@/api/content/models';
import dictionary from '@/core/dictionary/dictionary';
import Store from '@/project/stores/Store.vue';
import Map from '@/project/stores/Map.vue';

export type IStore = StoreViewObject & {
    days: string[],
    today: string,
    hasCtaOverride : boolean,
    callToActionUrl : string,
    callToActionText : string,
};

export default defineComponent({
    name: 'StoresBlock',
    components: { Map, Store },
    props: {
        model: {
            type: Object as PropType<StoresBlock>,
            required: true,
        },
    },
    setup(props)
    {
        const term = ref<string>();
        const allStores = ref<StoreViewObject[]>();
        const initialCoordinates = { lat: Number(props.model.initialMapCoordinatesLatitude), lng: Number(props.model.initialMapCoordinatesLongitude), zoom: Number(props.model.initialMapZoomLevel) };   
        getStores().then(res => {
            allStores.value = res;
        });

        const mappedStores = computed(() => {
            // format opening hours using labels
            return allStores.value?.map(store => {

                let hasCtaOverride = hasStoreCtaOverride(store.id); 
                let storeCtaProps = getStoreCtaProps(store.id, hasCtaOverride);
                return {
                    ...store,
                    today: `${dictionary.get('Stores.Today')}: ${formatOpeningHours(store.openingHoursToday)}`,
                    days: store.openingHours?.map(day => `${ dictionary.get('General.Weekdays.' + day.dayOfWeek) }: ${formatOpeningHours(day)}`),
                    hasCtaOverride : hasCtaOverride,
                    callToActionUrl : storeCtaProps.callToActionUrl,
                    callToActionText : storeCtaProps.callToActionText,
                } as IStore;
            });
        });

        const stores = computed(() => {
            if (term.value !== '' && term.value) {
                return mappedStores.value?.filter(x => {
                    const lowerTerm = term.value!.toLowerCase();
                    const differentSpelling = searchAllSpellings(lowerTerm);

                    return x.name?.toLowerCase().includes(lowerTerm) || x.name?.toLowerCase().includes(differentSpelling) ||
                            x.city?.toLowerCase().includes(lowerTerm) || x.city?.toLowerCase().includes(differentSpelling) ||
                            x.postalCode?.toLowerCase().includes(lowerTerm) ||
                            x.address?.toLowerCase().includes(lowerTerm) || x.address?.toLowerCase().includes(differentSpelling);
                });
            }
            return mappedStores.value;
        });

        function hasStoreCtaOverride(storeId) {
            let ctaOverride = getCtaOverrides(storeId);
            return ctaOverride !== undefined; 
        }

        function getStoreCtaProps(storeId, hasCtaOverride) {
            const ctaOverride = getCtaOverrides(storeId);
            return {
                callToActionUrl : hasCtaOverride ? (ctaOverride as any).ctaUrl?.url : props.model.ctaUrl?.url,
                callToActionText : hasCtaOverride ? (ctaOverride as any).ctaText : dictionary.get('Stores.FindJob'),
            };
        }

        function getCtaOverrides(storeId) {
            return props.model.ctaOverride?.find(x => (x.content as any) && (x.content as any).storeId === storeId)?.content;
        }

        function searchAllSpellings(term: string) {
            const replacements: Record<string, string> = {
                'å': 'aa',
                'aa': 'å',
            };

            return term.replace(/å|aa/g, match => replacements[match]);
        }

        function formatOpeningHours(day: OpeningHourDayViewObject | undefined) {
            if (!day)
                return '';

            if (day.closed)
                return dictionary.get('Stores.Closed');

            if (day.opens == null || day.closes == null)
                return dictionary.get('Stores.Closed');

            return `${day.opens} - ${day.closes}`;
        }

        return {
            stores,
            term,
            initialCoordinates,
        };
    },
});
</script>
<style scoped>
.divider{
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAwIiBoZWlnaHQ9IjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMy4yOTFjMTguOTg4LjM3IDY0LjE2IDEgOTIuOTQyLjU1NiAzNS45NzctLjU1NiA3NS40NTMtMS4yOTcgODkuNDQ0LS45MjYgMTMuOTkxLjM3IDg1Ljk0Ni43NCAxMjAuOTI0LjM3IDM0Ljk3OS0uMzcgODguNjktMi4yMTYgMTU4LjgyMi0xLjExMSA2OS41OTcgMS4wOTcgMTc3LjMwNiAxLjA1NCAyMjEuNDQxLS41NTZDNzI3LjcwOC4wMTQgNzk4LjcyOCAyLjAwNyA4MDAgMi4xOCIgc3Ryb2tlPSIjMDAwIi8+PC9zdmc+) top no-repeat;
    width: 100%;
    height: 20px;
    border: none;
}
</style>
