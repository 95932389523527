<template>
    <div class="divider">
    </div>    
    <Disclosure v-slot="{open}">
        <DisclosureButton class="flex items-center pb-10 text-16">
            <div class=" text-left">
                <div class="font-fauli font-bold">
                    {{ store.name }} {{ store.namePostFix }}
                </div>
                <p>
                    <span>{{ store.address }}</span>
                    <span v-if="store.address2">, {{ store.address2 }} </span>
                    <span v-if="store.postalCode || store.city">, {{ store.postalCode }} {{ store.city }}</span>
                </p>
                <p class="mt-0 md:hidden">
                    {{ store.today }}
                </p>
            </div>
            <div class="flex items-center ml-auto">
                <p class="hidden mr-20 min-w-[14.5rem] text-right md:flex">
                    {{ store.today }}
                </p>
                <CIcon name="arrow-up"
                       class="w-22 h-auto transition-transform duration-300"
                       :class="{ 'rotate-0' : open, 'rotate-180': !open}"
                       :fill="false"/>
            </div>
        </DisclosureButton>
        <DisclosurePanel v-show="open">
            <div class="divider">
            </div>
            <div class="flex mt-10 text-16">
                <div>
                    <div class="mb-10 font-fauli font-bold">
                        {{ $translate('Stores.OpeningHours') }}
                    </div>
                    <div>
                        <div v-for="(day, dayIndex) in store.days"
                             :key="dayIndex"
                             class="mb-10">
                            <div>{{ day }}</div>
                        </div>
                    </div>
                </div>
                <div class="ml-auto">
                    <div v-if="store.telephone?.length && store.email?.length"
                         class="mb-10 font-fauli font-bold">
                        {{ $translate('Stores.ContactHeading') }}
                    </div>
                    <div v-if="store.telephone?.length"
                         class="mb-10">
                        {{ $translate('Stores.Phone') }}:  <a class="underline"
                                                              :href="`tel:${store.telephone}`">{{ store.telephone }}</a>
                    </div>
                    <div v-if="store.email?.length"
                         class="mb-20">
                        {{ $translate('Stores.Email') }}: <a class="underline"
                                                             :href="`mailto:${store.email}`">{{ store.email }}</a>
                    </div>
                    <div class="mb-10">
                        <CallToAction v-if="store.latitude != null && store.longitude != null"
                                      :href="store.googleMapsUrl != null
                                          ? store.googleMapsUrl
                                          : `https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}`"
                                      size="small"
                                      target="_blank">
                            {{ $translate('Stores.MapLink') }}
                        </CallToAction>
                    </div>
                    <div>
                        <CallToAction v-if="showCallToAction"
                                      :class="store.hasCtaOverride ? 'cta_override' : ''"
                                      :href="store.callToActionUrl"
                                      size="small"
                                      target="_blank">
                            {{ store.callToActionText }}
                        </CallToAction>
                    </div>
                </div>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IStore } from '@/project/content/StoresBlock.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
export default defineComponent({
    name: 'Store',
    components: { Disclosure, DisclosureButton, DisclosurePanel },
    props: {
        store: {
            type: Object as PropType<IStore>,
            required: true,
        },
    },
    setup(props) {
        const showCallToAction = props.store.callToActionUrl && props.store.callToActionText; 

        return { 
            showCallToAction,
        };
    },
});
</script>
<style scoped>
.divider{
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAwIiBoZWlnaHQ9IjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMy4yOTFjMTguOTg4LjM3IDY0LjE2IDEgOTIuOTQyLjU1NiAzNS45NzctLjU1NiA3NS40NTMtMS4yOTcgODkuNDQ0LS45MjYgMTMuOTkxLjM3IDg1Ljk0Ni43NCAxMjAuOTI0LjM3IDM0Ljk3OS0uMzcgODguNjktMi4yMTYgMTU4LjgyMi0xLjExMSA2OS41OTcgMS4wOTcgMTc3LjMwNiAxLjA1NCAyMjEuNDQxLS41NTZDNzI3LjcwOC4wMTQgNzk4LjcyOCAyLjAwNyA4MDAgMi4xOCIgc3Ryb2tlPSIjMDAwIi8+PC9zdmc+) top no-repeat;
    width: 100%;
    height: 20px;
    border: none;
}

.cta_override {
    width: 185px;
}
</style>
