<template>
    <component :is="dialog"
               v-if="dialog"
               v-bind="props"
               :key="key"/>
</template>

<script lang="ts">
import { Component, defineComponent, ref, shallowRef } from 'vue';
import bus from '@/core/bus';
import { OpenDialogKey, OpenDialogData } from '@/project/dialog/dialog';

export default defineComponent({
    name: 'DialogHandler',
    setup() {
        const dialog = shallowRef<Component | undefined>(undefined);
        const props = ref <Record<string, any> | undefined>(undefined);
        const key = ref(getKey());

        bus.on(OpenDialogKey, (openDialogData: OpenDialogData) => {
            dialog.value = openDialogData.dialog;
            props.value = openDialogData.props;
            key.value = getKey();
        });

        function getKey(): number {
            return Math.random();
        }

        return {
            dialog,
            props,
            key,
        };
    },
});
</script>
