// This script is needed to add translations for the Aaland (normal.ax) cookie declaration
// Browsers and cookiebot doesn't recognize sv-ax as a culture. So this hardcoded script is necessary.
// if something seems wrong, ask mly or fmh
const translations = {
    consentAppliesToDomains: 'Ditt samtycke gäller för följande domäner',
    yourCurrentState: 'Din nuvarande tillstånd',
    yourConsentId: 'Ditt samtyckes-ID',
    consentDate: 'Samtyckesdatum',
    changeConsentButton: 'Ändra ditt medgivande',
    withdrawConsentButton: 'Ta tillbaka samtycke',
    declarationLastUpdatedOn: 'Cookie-deklaration uppdaterades senast',
    declarationLastUpdatedBy: 'av',
};

const declarationCheckTimeout = 500; // 0.5s

function translateElement(element, toReplace, toReplaceWith) {
    const text = element.childNodes[0].textContent;
    const replacedText = text.replace(toReplace, toReplaceWith);

    element.childNodes[0].textContent = replacedText;
}

function translateCookieDeclaration() {
    var consentAppliesTo = document.querySelector('.CookieDeclarationIntro').nextElementSibling;
    translateElement(consentAppliesTo, 'Your consent applies to the following domains', translations.consentAppliesToDomains);

    var statusAllowAll = document.getElementById('CookieDeclarationUserStatusLabelOn');
    translateElement(statusAllowAll, 'Your current state', translations.yourCurrentState);

    var statusAllowSelection = document.getElementById('CookieDeclarationUserStatusLabelMulti');
    translateElement(statusAllowSelection, 'Your current state', translations.yourCurrentState);

    var statusDecline = document.getElementById('CookieDeclarationUserStatusLabelOff');
    translateElement(statusDecline, 'Your current state', translations.yourCurrentState);

    var statusDoNotSell = document.getElementById('CookieDeclarationUserStatusLabelOffDoNotSell');
    translateElement(statusDoNotSell, 'Your current state', translations.yourCurrentState);

    var consentId = document.querySelector('#CookieDeclarationConsentIdAndDate >span');
    translateElement(consentId, 'Your consent ID', translations.yourConsentId);

    var consentDate = document.querySelector('#CookieDeclarationConsentIdAndDate :nth-child(2)');
    translateElement(consentDate, 'Consent date', translations.consentDate);

    var changeConsent = document.getElementById('CookieDeclarationChangeConsentChange');
    translateElement(changeConsent, 'Change your consent', translations.changeConsentButton);

    var withdrawConsent = document.getElementById('CookieDeclarationChangeConsentWithdraw');
    translateElement(withdrawConsent, 'Withdraw your consent', translations.withdrawConsentButton);

    var cookieDeclarationLastUpdatedOn = document.querySelector('.CookieDeclarationLastUpdated');
    translateElement(cookieDeclarationLastUpdatedOn, 'Cookie declaration last updated on', translations.declarationLastUpdatedOn);

    var cookieDeclarationLastUpdatedBy = document.querySelector('.CookieDeclarationLastUpdated');
    translateElement(cookieDeclarationLastUpdatedBy, 'by', translations.declarationLastUpdatedBy);
}

function waitForCookieDeclaration() {
    const translationContainer = document.querySelector('.CookieDeclaration');

    if (translationContainer) {
        translateCookieDeclaration();
    } else {
        setTimeout(waitForCookieDeclaration, declarationCheckTimeout);
    }
}

waitForCookieDeclaration();

export default {
    translations: translations,
    translateElement: translateElement,
    translateCookieDeclaration: translateCookieDeclaration,
    waitForCookieDeclaration: waitForCookieDeclaration,
};
