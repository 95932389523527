<template>
    <div class="mb-10">
        <InputText :id="displayName"
                   :name="name" 
                   :placeholder="displayName" 
                   :label="displayName"
                   :show-as-required="model.required"
                   :validation-rules="rules"/>
    </div>
</template>

<script lang="ts" setup>
import { TextBoxBlock } from '@/api/content/models';
import { computed, defineProps } from 'vue';
import InputText from '../forms/InputText.vue';
type Props = {
    model: TextBoxBlock
}

const props = defineProps<Props>();

const displayName = computed(() => props.model.textboxHeader?.trim() ?? '');

const name = computed(() => handleDots(props.model.textboxHeader?.trim()) ?? '');

function handleDots(input) {
    return input.replace(/\./g, '[dot]');
}

const rules = computed(() => props.model.required ? 'formsRequired' : undefined);

</script>
