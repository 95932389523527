/* tslint:disable */
/* eslint-disable */
/**
 * Normal Stock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompleteReservationRequest } from '../models';
// @ts-ignore
import { CreateReservationRequest } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ReservationStatus } from '../models';
// @ts-ignore
import { StockResponse } from '../models';
// @ts-ignore
import { StockStatusViewObjectArrayClientResponse } from '../models';
/**
 * Stock - axios parameter creator
 * @export
 */
export const StockAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [basketId] 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockCheckBasketHasActiveReservationsGet: async (basketId?: string, culture?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stock/CheckBasketHasActiveReservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (basketId !== undefined) {
                localVarQueryParameter['basketId'] = basketId;
            }

            if (culture !== undefined) {
                localVarQueryParameter['culture'] = culture;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompleteReservationRequest} [completeReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockCompleteReservationPost: async (completeReservationRequest?: CompleteReservationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stock/CompleteReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateReservationRequest} [createReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockCreateReservationPost: async (createReservationRequest?: CreateReservationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stock/CreateReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockGetReservationStatusGet: async (basketId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stock/GetReservationStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (basketId !== undefined) {
                localVarQueryParameter['basketId'] = basketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [skus] 
         * @param {string} [excludeReservationsForBasketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockGetStockInfoGet: async (skus?: Array<string>, excludeReservationsForBasketId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stock/GetStockInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skus) {
                localVarQueryParameter['skus'] = skus;
            }

            if (excludeReservationsForBasketId !== undefined) {
                localVarQueryParameter['excludeReservationsForBasketId'] = excludeReservationsForBasketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [skus] 
         * @param {string} [basketId] 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockGetStockStatusGet: async (skus?: Array<string>, basketId?: string, culture?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stock/GetStockStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skus) {
                localVarQueryParameter['skus'] = skus;
            }

            if (basketId !== undefined) {
                localVarQueryParameter['basketId'] = basketId;
            }

            if (culture !== undefined) {
                localVarQueryParameter['culture'] = culture;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Stock - functional programming interface
 * @export
 */
export const StockFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [basketId] 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockCheckBasketHasActiveReservationsGet(basketId?: string, culture?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockCheckBasketHasActiveReservationsGet(basketId, culture, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompleteReservationRequest} [completeReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockCompleteReservationPost(completeReservationRequest?: CompleteReservationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockCompleteReservationPost(completeReservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateReservationRequest} [createReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockCreateReservationPost(createReservationRequest?: CreateReservationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockCreateReservationPost(createReservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockGetReservationStatusGet(basketId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReservationStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockGetReservationStatusGet(basketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [skus] 
         * @param {string} [excludeReservationsForBasketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockGetStockInfoGet(skus?: Array<string>, excludeReservationsForBasketId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockGetStockInfoGet(skus, excludeReservationsForBasketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [skus] 
         * @param {string} [basketId] 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStockGetStockStatusGet(skus?: Array<string>, basketId?: string, culture?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockStatusViewObjectArrayClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStockGetStockStatusGet(skus, basketId, culture, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Stock - factory interface
 * @export
 */
export const StockFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockFp(configuration)
    return {
        /**
         * 
         * @param {string} [basketId] 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockCheckBasketHasActiveReservationsGet(basketId?: string, culture?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiStockCheckBasketHasActiveReservationsGet(basketId, culture, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompleteReservationRequest} [completeReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockCompleteReservationPost(completeReservationRequest?: CompleteReservationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiStockCompleteReservationPost(completeReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateReservationRequest} [createReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockCreateReservationPost(createReservationRequest?: CreateReservationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiStockCreateReservationPost(createReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [basketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockGetReservationStatusGet(basketId?: string, options?: any): AxiosPromise<ReservationStatus> {
            return localVarFp.apiStockGetReservationStatusGet(basketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [skus] 
         * @param {string} [excludeReservationsForBasketId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockGetStockInfoGet(skus?: Array<string>, excludeReservationsForBasketId?: string, options?: any): AxiosPromise<Array<StockResponse>> {
            return localVarFp.apiStockGetStockInfoGet(skus, excludeReservationsForBasketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [skus] 
         * @param {string} [basketId] 
         * @param {string} [culture] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStockGetStockStatusGet(skus?: Array<string>, basketId?: string, culture?: string, options?: any): AxiosPromise<StockStatusViewObjectArrayClientResponse> {
            return localVarFp.apiStockGetStockStatusGet(skus, basketId, culture, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Stock - interface
 * @export
 * @interface Stock
 */
export interface StockInterface {
    /**
     * 
     * @param {string} [basketId] 
     * @param {string} [culture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockInterface
     */
    apiStockCheckBasketHasActiveReservationsGet(basketId?: string, culture?: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {CompleteReservationRequest} [completeReservationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockInterface
     */
    apiStockCompleteReservationPost(completeReservationRequest?: CompleteReservationRequest, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {CreateReservationRequest} [createReservationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockInterface
     */
    apiStockCreateReservationPost(createReservationRequest?: CreateReservationRequest, options?: any): AxiosPromise<void>;

    /**
     * 
     * @param {string} [basketId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockInterface
     */
    apiStockGetReservationStatusGet(basketId?: string, options?: any): AxiosPromise<ReservationStatus>;

    /**
     * 
     * @param {Array<string>} [skus] 
     * @param {string} [excludeReservationsForBasketId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockInterface
     */
    apiStockGetStockInfoGet(skus?: Array<string>, excludeReservationsForBasketId?: string, options?: any): AxiosPromise<Array<StockResponse>>;

    /**
     * 
     * @param {Array<string>} [skus] 
     * @param {string} [basketId] 
     * @param {string} [culture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockInterface
     */
    apiStockGetStockStatusGet(skus?: Array<string>, basketId?: string, culture?: string, options?: any): AxiosPromise<StockStatusViewObjectArrayClientResponse>;

}

/**
 * Stock - object-oriented interface
 * @export
 * @class Stock
 * @extends {BaseAPI}
 */
export class Stock extends BaseAPI implements StockInterface {
    /**
     * 
     * @param {string} [basketId] 
     * @param {string} [culture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Stock
     */
    public apiStockCheckBasketHasActiveReservationsGet(basketId?: string, culture?: string, options?: any) {
        return StockFp(this.configuration).apiStockCheckBasketHasActiveReservationsGet(basketId, culture, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompleteReservationRequest} [completeReservationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Stock
     */
    public apiStockCompleteReservationPost(completeReservationRequest?: CompleteReservationRequest, options?: any) {
        return StockFp(this.configuration).apiStockCompleteReservationPost(completeReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateReservationRequest} [createReservationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Stock
     */
    public apiStockCreateReservationPost(createReservationRequest?: CreateReservationRequest, options?: any) {
        return StockFp(this.configuration).apiStockCreateReservationPost(createReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [basketId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Stock
     */
    public apiStockGetReservationStatusGet(basketId?: string, options?: any) {
        return StockFp(this.configuration).apiStockGetReservationStatusGet(basketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [skus] 
     * @param {string} [excludeReservationsForBasketId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Stock
     */
    public apiStockGetStockInfoGet(skus?: Array<string>, excludeReservationsForBasketId?: string, options?: any) {
        return StockFp(this.configuration).apiStockGetStockInfoGet(skus, excludeReservationsForBasketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [skus] 
     * @param {string} [basketId] 
     * @param {string} [culture] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Stock
     */
    public apiStockGetStockStatusGet(skus?: Array<string>, basketId?: string, culture?: string, options?: any) {
        return StockFp(this.configuration).apiStockGetStockStatusGet(skus, basketId, culture, options).then((request) => request(this.axios, this.basePath));
    }
}
