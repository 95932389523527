<template>
    <div v-if="showAvailableQuantity && reservationError"
         class="relative z-2 px-20 pt-[5%] pb-20 mt-10 text-red-100">
        {{ $translate('Product.AvailableQuantityText', reservationError.availableQuantity.toString()) }}
        <CIcon name="error-speechbubble"
               class="absolute inset-0 -z-1 -mt-5 w-full h-full"
               original
               preserveAspectRatio="none"/>
    </div>
    <div v-else-if="showNoAvailableQuantity"
         class="relative z-2 px-20 pt-10 pb-20 mt-10 text-red-100">
        {{ $translate('Product.NoAvailableQuantity') }}
        <CIcon name="error-speechbubble"
               class="absolute inset-0 -z-1 -mt-5 w-full h-full"
               original
               preserveAspectRatio="none"/>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { clientBasket, reservationErrors } from './basket.service';

export default defineComponent({
    name: 'ReservationError',
    props: {
        sku: {
            required: true,
            type: String,
        },
    },
    setup(props) {
        const quantityInBasket = computed(() => {
            const lineItemInBasket = clientBasket.value.lineItems.find(l => l.sku === props.sku);
            return lineItemInBasket?.quantity || 0;
        });
        const reservationError = computed(() => {
            return reservationErrors.value.find(e => e.sku === props.sku) || null;
        });
        const showAvailableQuantity = computed(() => {
            return reservationError.value && reservationError.value.availableQuantity > 0 && quantityInBasket.value > reservationError.value.availableQuantity;
        });
        const showNoAvailableQuantity = computed(() => {
            return reservationError.value && reservationError.value.availableQuantity <= 0 && quantityInBasket.value > reservationError.value.availableQuantity;
        });

        return {
            reservationError,
            showAvailableQuantity,
            showNoAvailableQuantity,
        };
    },

});
</script>
