<template>
    <article class="py-60 px-20 md:grid md:grid-cols-12 md:gap-x-20 md:px-0">
        <div class="md:col-span-8 md:col-start-3">
            <component :is="'script'"
                       v-if="model.scriptUrl"
                       id="CookieDeclaration"
                       :src="model.scriptUrl"
                       type="text/javascript"
                       :data-culture="culture"/>
        </div>
    </article>
</template>

<script lang="ts">
import { CookieBlock } from '@/api/content/models';
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { getCultureSettings } from '@/project/content/api/contentApi';
import aalandCookieTranslationsScript from '@/project/scripts/AalandCookieTranslations.js';


export default defineComponent({
    name: 'CookieBlock',
    props: {
        model: {
            required: true,
            type: Object as PropType<CookieBlock>,
        },
    },
    setup() {
        const culture = ref('');
        getCultureSettings().then((settings)=> {
            culture.value = settings.cultureNameForCookieBot.split('-')[0];
        });

        // This is done to circumvent that cookiebot doesn't support Aaland as language
        if(culture.value.toLowerCase() === 'tt') {
            onMounted(() => {
                aalandCookieTranslationsScript.waitForCookieDeclaration();
            });
        }

        return {
            culture,
        };
    },
});
</script>

<style>
 #CookieDeclarationChangeConsentChange, #CookieDeclarationChangeConsentWithdraw {
     text-decoration: underline !important;
     font-weight: bold !important;
     @apply text-green-100 !important;
}
</style>
