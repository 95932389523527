<template>
    <article class="py-60 px-20 md:grid md:grid-cols-12 md:gap-x-20 md:px-0">
        <div class="md:col-span-8 md:col-start-3">
            <component :is="'script'"
                       v-if="model.scriptUrl"
                       :src="model.scriptUrl"/>
        </div>
    </article>
</template>

<script lang="ts">
import { ScriptBlock } from '@/api/content/models';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'ScriptBlock',
    props: {
        model: {
            required: true,
            type: Object as PropType<ScriptBlock>,
        },
    },
});
</script>
