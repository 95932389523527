import { ref } from 'vue';

const backgroundColor = ref<string | undefined>();
let theFallbackColor = 'transparent';

export default function useTheme(fallbackColor?: string) {
    if (fallbackColor) {
        theFallbackColor = fallbackColor;
    }
    function setBackgroundColor(hexColor?: string | null) {
        backgroundColor.value = hexColor && hexColor !== 'none' ? ensurePrefix(hexColor) : theFallbackColor;
    }
    
    function ensurePrefix(hexColor: string): string {
        return hexColor.startsWith('#') ? hexColor : '#' + hexColor;
    } 
    
    return {
        setThemeBackgroundColor : setBackgroundColor,
        themeBackgroundColor : backgroundColor,    
    };
}

