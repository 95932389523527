import { CheckoutFactory, LineItemViewObject, ProductType, StockStatus } from '@/api/checkout';
import { GiftCardDetailsModel } from '@/api/content';
import { checkoutHost } from '@/core/infrastructure/environment';
import { ref } from 'vue';
import { BasketSummaryType } from './basket.service';

const giftcardProductInformation = ref<GiftCardDetailsModel | null>(null);

const checkoutService = CheckoutFactory({
    basePath: checkoutHost,
    isJsonMime: () => true,
});

export const pingnav = checkoutService.apiCheckoutPingNavGet;

const setGiftcardInformation = (giftcard: GiftCardDetailsModel) => {
    giftcardProductInformation.value = giftcard;
};

const getGiftcardInformation = () => {
    return giftcardProductInformation.value;
}; 

const clearGiftcardInformation = () => {
    giftcardProductInformation.value = null;
};

const getGiftcardTotalCost = () => giftcardProductInformation.value?.price;

export {
    setGiftcardInformation,
    getGiftcardInformation,
    clearGiftcardInformation,
    getGiftcardTotalCost,
};

