let formatter: Intl.NumberFormat | undefined;

export function formatAmount(culture: string, amount: number, currency?: string): string |void {
    if (!culture) return; 
    if (!formatter) {
        // Premise: Always same currency for same market (and reload of FE between market-change)
        formatter = new Intl.NumberFormat(culture, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });
    }

    return formatter.format(amount) + (currency ? ` ${currency.toUpperCase()}` : '');
}
