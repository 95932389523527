<template>
    <article class="relative">
        <div class="flex items-center w-full md:mr-20 lg:mr-40">
            <div class="img-wrap">
                <a href=""
                   :title="lineItem.displayName"
                   @click.prevent="goToProduct()">
                    <ImageWrap v-if="lineItem.image"
                               format="webp"
                               :image="lineItem.image"
                               :width="150"
                               :name="lineItem.displayName"
                               :use-theme="false"
                               :class="{'opacity-50': disableLineItem}"
                               styles="mx-auto"/>
                </a>
            </div>
            <div class="w-full">
                <a href=""
                   :title="lineItem.displayName"
                   @click.prevent="goToProduct()">
                    <p class="pt-10 mb-5 max-w-[16rem] font-fauli text-14 hover:text-pink-100 md:max-w-none"
                       :class="{'opacity-50': disableLineItem}">
                        {{ lineItem.displayName }}
                    </p>
                </a>
                <div class="flex items-center">
                    <div>
                        <AddToBasket :key="lineItem.sku"
                                     :model="lineItem"
                                     :mini-basket-mode="true"
                                     :disable-line-item="disableLineItem"/>
                    </div>
                    <div v-if="lineItem && lineItem.totalPrice"
                         class="mt-10 ml-auto font-numbers text-20 md:mt-0 md:text-24">
                        <Price :amount="lineItem.totalPrice" 
                               :show-formatted-amount="true"/>
                    </div>
                    <div v-else
                         class="mt-10 ml-auto font-numbers text-20 md:mt-0 md:text-24 text-blur text-blur--active">
                        {{ pricePlaceHolder }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="disableLineItem"
             class="inline-block relative -mt-10 ml-30">
            <div class="inline-block p-20 font-fauli text-14 text-red-100">
                <span v-if="soldOut"
                      class="relative z-1">{{ $translate('Product.AllSoldOut') }}</span>
                <span v-else-if="allReserved"
                      class="relative z-1">{{ $translate('Product.AllReserved') }}</span>
                <span v-else-if="notAvailable"
                      class="relative z-1">{{ $translate('Product.Unavailable') }}</span>
                <CIcon name="error-speechbubble"
                       class="absolute inset-0 -mt-5 w-full h-full"
                       original
                       preserveAspectRatio="none"/>
            </div>
        </div>
        <ReservationError v-else
                          :sku="lineItem.sku"
                          class="-mt-10 ml-30"/>
    </article>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from 'vue';
import { clientBasket } from '@/project/basket/basket.service';
import { StockStatus, LineItemViewObject } from '@/api/checkout';
import { useRoute } from 'vue-router';
import AddToBasket from '@/project/basket/add-to-basket/AddToBasket.vue';
import ReservationError from '@/project/basket/ReservationError.vue';
import CIcon from '@/core/layout/icons/CIcon.vue';
import router from '@/router';
import { closeDialog } from '../dialog/dialog';
import ImageWrap from '@/project/shared/ImageWrap.vue';
import Price from '@/project/basket/Price.vue';

export default defineComponent({
    name: 'LineItem',
    components: {
        AddToBasket,
        ReservationError,
        CIcon,
        ImageWrap,
        Price,
    },
    props: {
        lineItem: {
            type: Object as PropType<LineItemViewObject>,
            required: true,
        },
    },
    setup(props) {
        const route = useRoute();
        const productUrl = `/p-${props.lineItem.sku}`;
        const pricePlaceHolder = ref<string>('--,--');

        function goToProduct() {
            closeDialog();
            router.push(productUrl);
        }

        const allReserved = computed(() => {
            return props.lineItem.stockStatus === StockStatus.AllReserved;
        });

        const soldOut = computed(() => {
            return props.lineItem.stockStatus === StockStatus.SoldOut;
        });

        const notAvailable = computed(() => {
            return props.lineItem.stockStatus === StockStatus.Unavailable;
        });

        const disableLineItem = computed(() => {
            return allReserved.value || soldOut.value || notAvailable.value;
        });

        return {
            clientBasket,
            notAvailable,
            allReserved,
            disableLineItem,
            soldOut,
            route,
            goToProduct,
            pricePlaceHolder,
        };
    },
});
</script>
<style scoped>
    .img-wrap {
        flex: 0 0 5.5rem;
        @apply max-w-75 text-center mr-20
    }

    @screen md {
        .img-wrap {
            flex: 0 0 7.5rem;
        }
    }
</style>
