<template>
    <svg width="421px"
         height="169px"
         viewBox="0 0 421 169"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path id="path-1"
                  d="M440,5877.21647 C440,5880.65036 459.488835,5892 483.582344,5892 C507.675853,5892 519.413402,5888.92699 522.343269,5886.80888 C525.273137,5884.69077 525.273137,5880.06756 538.499431,5882.39142 C551.725726,5884.71529 655.219838,5890.1235 694.377327,5890.1235 C733.534815,5890.1235 801.639074,5891.80711 815.764683,5887.40636 C829.890293,5883.00561 846.624545,5871.96202 848.722326,5844.30687 C850.820107,5816.65171 840.471884,5814.89495 836.476386,5811.39905 C832.480888,5807.90316 827.735911,5805.63244 824.811384,5802.26413 C821.886856,5798.89581 818.691305,5795.99692 816.975646,5787.13731 C815.259987,5778.2777 810.970964,5759.85553 808.041561,5755.23451 C805.112158,5750.61348 801.904129,5744.39679 788.548388,5742.56439 C775.192647,5740.73198 746.919805,5735 710.228974,5735 C673.538144,5735 628.943063,5736.89738 615.936889,5738.62972 C602.930715,5740.36205 567.320566,5742.59044 554.374694,5744.1846 C541.428823,5745.77875 526.300587,5750.80088 522.343269,5762.63289 C518.385952,5774.4649 515.797371,5782.07297 520.932871,5795.29086 C526.06837,5808.50874 516.129767,5814.5576 509.203802,5819.82878 C502.277837,5825.09996 499.255835,5834.19382 498.860356,5839.27268 C498.464876,5844.35154 497.115402,5859.00606 497.115402,5863.00364 C497.115402,5867.00121 492.277278,5871.5714 481.139599,5873.16689 C470.001921,5874.76238 462.728493,5875.3284 457.68238,5875.3284 C452.636266,5875.3284 440,5873.78258 440,5877.21647 Z"/>
        </defs>
        <g stroke="none"
           stroke-width="1"
           fill="none"
           fill-rule="evenodd">
            <g transform="translate(-434.000000, -5729.000000)">
                <use stroke="#FFFFFF"
                     stroke-width="12"
                     fill="#FFFFFF"
                     fill-rule="evenodd"
                     xlink:href="#path-1"/>
                <use stroke="#000000"
                     stroke-width="8"
                     xlink:href="#path-1"/>
            </g>
        </g>
    </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NotFoundSpeechBubble',
});
</script>
