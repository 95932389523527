<template>
    <Form class="flex flex-col gap-15 px-80 pt-35 pb-80 xs:px-10 xs:mx-2 sm:mx-5 md:mx-110 lg:mx-2/12" 
          @submit="e => onSubmit(e)">
        <label class="mx-10 mt-45 mb-35 h1"
               v-html="props.model.headline"></label>

        <ContentBlockList class="flex flex-col gap-15"
                          :blocks="props.model.blocks"/>

        <div v-if="useRecaptcha">
            <Recaptcha ref="recaptcha"
                       v-model="recaptchaKey"
                       class="mt-25 md:mr-auto"
                       @verify="SetRecaptchaVerification()"/>
            <label v-if="recaptchaMissing"
                   class="mt-5 font-fauli text-10 text-red-100">{{ $translate('Forms.ReCaptcha.Validation') }}</label>
            <label v-if="recaptchaHasError"
                   class="mt-5 font-fauli text-10 text-red-100">{{ $translate('Forms.ReCaptchaErrors') }}</label>
        </div>
        <CallToAction :variant="submitColorButton"
                      class="mt-25 md:mr-auto"
                      :disabled=" loading || success || hasError"
                      type="submit">
            {{ loading ? $translate('Sponsorformular.SubmitButtonLoading') : $translate('Sponsorformular.SubmitButton') }}
        </CallToAction>
    </Form>
</template>

<script lang="ts" setup>
import { FormsViewModel } from '@/api/content/models/forms-view-model';
import ContentBlockList from '@/core/content/ContentBlockList.vue';
import { HttpServerError } from '@/core/http/HttpServerError';
import blockUtil from '@/project/content/util/blockUtil';
import { formService, UploadFileError } from '@/project/forms/forms-services/forms.service';
import router from '@/router';
import { StatusCodes } from 'http-status-codes';
import { Form, useForm } from 'vee-validate';
import { computed, Ref, ref } from 'vue';
import CallToAction from '../navigation/call-to-action/CallToAction.vue';
import bus from '@/core/bus';
import { formsPayloadLogging } from '../basket/basket.service';
import content from '@/project/content/api/contentApi';
import Recaptcha, { RecaptchaComponentInstance } from '../shared/recaptcha/Recaptcha.vue';

type Props = {
    model: FormsViewModel;
}

const props = defineProps<Props>();
const success = ref<boolean>(false);
const loading = ref<boolean>(false);
const errorMessage = ref<string|null>(null);
const hasError = ref(false);
const recaptchaMissing = ref(false);
const recaptchaHasError = ref(false);
const inputFileCount = ref<number|null>(0);
const submitColorButton = computed(() => blockUtil.buttonVariant(props.model.buttonColor));
const recaptcha: Ref<RecaptchaComponentInstance | null> = ref(null);
const useRecaptcha = props.model.useRecaptcha; 
const recaptchaKey: Ref<string> = ref('');

bus.on(UploadFileError, (value)=>{
    hasError.value = value;
});

bus.on('TempFileCounterInput', (value)=>{
    inputFileCount.value = value;
});

bus.on('error', (value)=>{
    recaptchaVerified.value = false;
    recaptchaHasError.value = true;  
});

bus.on('expired', (value)=>{
    recaptchaVerified.value = false; 
    recaptchaMissing.value = true;  
});

const { validate } = useForm();

let recaptchaVerified  = ref(false); 

function SetRecaptchaVerification() {
    if(recaptchaKey.value){
        recaptchaVerified .value = true;
        recaptchaMissing.value = false;  
        recaptchaHasError.value = false;
    }  
}

const onSubmit = async(eventData) => {
    if(useRecaptcha === false) {
        addFilesAndSubmit(eventData);
    } else if (useRecaptcha && recaptchaVerified.value === true) {
        addFilesAndSubmit(eventData);
    } else {
        recaptchaMissing.value = true; 
    }
};

const addFilesAndSubmit = async(eventData) => {
    if(eventData['files']!== undefined && eventData['files'].length !== inputFileCount.value){
        let confirmText = 'An error occurred. We believe you have uploaded ' + inputFileCount.value + ' but only ' + eventData['files'].length + ' will be shipped. Do you want to send anyway?';
        if(confirm(confirmText) === true){
            submit(eventData);
        }
    } else {
        submit(eventData);
    }
};

const verifyRecaptcha = () => {
    recaptcha.value && recaptcha.value.reset();
};

const submit = async(eventData) => {
    await formsPayloadLogging('OnSubmit (FormsBlock.vue)', eventData);
    const res = await validate();

    if(!res.valid) {
        return;
    }

    try {
        loading.value = true;
        const authSettings = (await content.settings).formsAuthentication.basicAuthentication;
        const res = await formService.sendFormular(eventData, props.model, authSettings, recaptchaKey.value);

        if(res.status === StatusCodes.OK) {
            success.value = true;
            router.push(props.model.redirect);
            verifyRecaptcha();
        }
    } catch (error) {
        if(error instanceof HttpServerError) {
            errorMessage.value = error.message;
        }
    } finally {
        loading.value = false;
    }
};

</script>
