<template>
    <li v-for="(usp, index) in usps"
        :key="index"
        class="flex justify-center items-center py-15 px-10 mx-auto font-fauli md:p-0 md:mr-15 md:last:mr-0 md:ml-0 lg:mr-30 xl:mr-50 md:justify-unset">
        <img v-if="usp"
             :src="usp.url"
             class="pr-10 md:pr-5 md:max-w-55 lg:pr-15 lg:max-w-75"
             :alt="usp.text"             
             aria-hidden="true">
        <p class="md:text-14 lg:text-16">
            {{ usp.text }}
        </p>
    </li>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import content from '@/project/content/api/contentApi';
import { Usp } from '@/api/content/models';

export default defineComponent({
    name: 'Usps',
    setup() {
        const usps = ref<Usp[]>([]);

        content.settings.then(siteSettings => {
            if (siteSettings.usps) {
                usps.value = siteSettings.usps.map(usp => usp as Usp);
            }
        });

        return {
            usps,
        };
    },
});
</script>
