<template>
    <InputText id="email" 
               class="mb-10 w-full" 
               name="email" 
               type="email" 
               :required="model.required" 
               :label="$translate('Checkout.Labels.Email')" 
               :placeholder="model.emailHeader"
               :show-as-required="model.required"
               :validation-rules="rules"/>
</template>

<script lang="ts" setup>
import { EmailBlock } from '@/api/content/models/email-block';
import { computed, defineProps } from 'vue';
import InputText from '../forms/InputText.vue';

type Props = {
    model: EmailBlock;
}

const props = defineProps<Props>();

const rules = computed(() => props.model.required ? 'formsEmail|formsRequired' : 'email');

</script>
