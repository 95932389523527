import { computed, ref, watch } from 'vue';
import { StockStatus } from '@/api/stock';

export { useStockStatus };

function useStockStatus(stockResult) {

    const stockStatus = ref<StockStatus>(StockStatus.Available);
    const stockReady = ref<boolean>(false);
    watch(stockResult, (result) => {
        if (result.dataReady && !result.error) {
            if (!result[0]) {
                return;
            }
            stockReady.value = true;
            stockStatus.value = result[0].status ?? StockStatus.Unavailable;
        }
    }, {
        immediate: true,
    });

    const shouldShowStockStatus = computed(() => {
        return stockStatus.value === StockStatus.AllReserved ||
        stockStatus.value === StockStatus.SoldOut ||
         stockStatus.value === StockStatus.FewAvailable;
    });
    
    const allReserved = computed(() => {
        return stockStatus.value === StockStatus.AllReserved;
    });
    
    const soldOut = computed(() =>  {
        return stockStatus.value === StockStatus.SoldOut;
    });
    
    const fewAvailable = computed(() =>  {
        return stockStatus.value === StockStatus.FewAvailable;
    });

    const dataReady = computed(() =>  {
        return stockReady.value;
    });

    return {
        stockStatus,
        shouldShowStockStatus,
        allReserved,
        soldOut,
        fewAvailable,
        stockResult,
        dataReady,
    };
}
