<template>
    <InfoBox v-bind="splashColors"
             class="flex items-center max-w-[14.3rem] font-fauli text-12"
             :class="{'max-w-[14.8rem]': splashPdp}"
             :splash-pdp="splashPdp">
        <span class="whitespace-pre-line">{{ splashText }}</span>
    </InfoBox>
</template>

<script lang="ts" setup>
import { CatalogProductTileViewObject, GiftCardDetailsModel, ProductDetailsModel } from '@/api/content/models';
import blockUtil from '@/project/content/util/blockUtil';
import InfoBox from '@/project/shared/InfoBox.vue';
import { computed } from 'vue';

type ProductSplashType = ProductDetailsModel | CatalogProductTileViewObject | GiftCardDetailsModel;

type Props = {
    product: ProductSplashType;
    splashPdp?: boolean;
}

const props = defineProps<Props>();

const splashColors = computed(() => splashColorsBasedOnProductType(props.product));
const splashText = computed(() => splashTextBasedOnProductType(props.product));

/**
 * TODO: Refactor, scales terribly.... use some sort on pattern to determine colors and text
 * @param product 
 */
const splashColorsBasedOnProductType = (product: ProductSplashType)=> {
    /**
     * Giftcard
     */
    if ('splash' in product && product.splash !== null) {
        return formatBackgroundColorAndColor(product.splash.splashTextColor, product.splash.splashColor);
    }

    /**
     * Product
     */
    if ('splashText' in product && product.splashText !== null) {
        return formatBackgroundColorAndColor(product.splashTextColor, product.splashColor);
    }

    return null; // no splash information
};

const splashTextBasedOnProductType = (product: ProductSplashType)=> {
    /**
     * Giftcard
     */
    if ('splash' in product && product.splash !== null) {
        return product.splash.splashText;
    }

    /**
     * Product
     */
    if ('splashText' in product && product.splashText !== null) {
        return product.splashText;
    }

    return null; // no splash information
};

const formatBackgroundColorAndColor = (color?: string | null, bgColor?: string | null) => {
    return {
        backgroundColor: blockUtil.formatHexColor(bgColor) ?? 'black',
        color: blockUtil.formatHexColor(color) ?? 'white',
    };
};
</script>
