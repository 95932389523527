import { blockResolver, pageResolver } from '@/core/content/componentResolver';
import HomePage from '@/project/pages/HomePage.vue';
import ContentPage from '@/project/pages/ContentPage.vue';
import ProductDetailsPage from '@/project/pages/product-details/ProductDetailsPage.vue';
import GiftCardDetailsPage from '@/project/pages/gift-card-details/GiftCardDetailsPage.vue';
import FallbackPage from '@/core/content/FallbackPage.vue';
import TextBlock from '@/project/content/TextBlock.vue';
import ImageBlock from '@/project/content/ImageBlock.vue';
import FiftyFiftyBlock from '@/project/content/FiftyFiftyBlock.vue';
import HeroBlock from '@/project/content/HeroBlock.vue';
import ProductsBlock from '@/project/content/ProductsBlock.vue';
import ScriptBlock from '@/project/content/ScriptBlock.vue';
import CookieBlock from '@/project/content/CookieBlock.vue';
import HTMLBlock from '@/project/content/HTMLBlock.vue';
import StoresBlock from '@/project/content/StoresBlock.vue';
import FallbackBlock from '@/core/content/FallbackBlock.vue';
import _404Page from '@/project/pages/_404Page.vue';
import FormsBlock from '@/project/content/FormsBlock.vue';
import ButtonColorBlock from '@/project/content/ButtonColorBlock.vue';
import TextBoxBlock from '@/project/content/TextboxBlock.vue';
import TextAreaBlock from '@/project/content/TextAreaBlock.vue';
import LinkBlock from '@/project/content/LinkBlock.vue';
import CheckboxBlock from '@/project/content/CheckboxBlock.vue';
import FileSelectorBlock from '@/project/content/FileSelectorBlock.vue';
import EmailBlock from '@/project/content/EmailBlock.vue';
import FormsTextBlock from '@/project/content/FormsTextBlock.vue';

export default function config(): void {
    registerPages();
    registerBlocks();
}

function registerPages(): void {
    pageResolver.register('homePage', HomePage);
    pageResolver.register('contentPage', ContentPage);
    pageResolver.register('productDetailsPage', ProductDetailsPage);
    pageResolver.register('giftCardDetailsPage', GiftCardDetailsPage);
    pageResolver.register('notFoundPage', _404Page);
    pageResolver.registerFallback(FallbackPage);
}

function registerBlocks(): void {
    blockResolver.register('heroBlock', HeroBlock);
    blockResolver.register('textBlock', TextBlock);
    blockResolver.register('fiftyFiftyBlock', FiftyFiftyBlock);
    blockResolver.register('imageBlock', ImageBlock);
    blockResolver.register('scriptBlock', ScriptBlock);
    blockResolver.register('cookieBlock', CookieBlock);
    blockResolver.register('htmlBlock', HTMLBlock);
    blockResolver.register('storesBlock', StoresBlock);
    blockResolver.register('productsBlock', ProductsBlock);
    blockResolver.register('ButtonColorBlock', ButtonColorBlock);
    blockResolver.register('TextBoxBlock', TextBoxBlock);
    blockResolver.register('TextAreaBlock', TextAreaBlock);
    blockResolver.register('LinkBlock', LinkBlock);
    blockResolver.register('CheckboxBlock', CheckboxBlock);
    blockResolver.register('FileSelectorBlock', FileSelectorBlock);
    blockResolver.register('EmailBlock', EmailBlock);
    blockResolver.register('formsTextBlock', FormsTextBlock);
    blockResolver.register('formsBlock', FormsBlock);
    blockResolver.registerFallback(FallbackBlock);
}
