<template>
    <article class="flex flex-col h-full text-center">
        <ProductTileImage :index="index"
                          :content-block-index="contentBlockIndex"
                          :stock-results="stockResults"
                          :product="product"/>

        <div class="mb-6 font-fauli text-16">
            <RouterLink :to="productUrl"
                        class="hover:text-pink-100"
                        @click="trackOnClick()">
                {{ product.displayName }}
            </RouterLink>
        </div>
        <div class="mt-auto -ml-2 font-numbers text-52 leading-none">
            <Price :amount="product.unitPrice"
                   show-formatted-amount/>
        </div>
        <div class="-mt-3 mb-7 h-17 text-13">
            <span v-if="product.pricePerUnit">{{ product.pricePerUnit }}</span>
        </div>
        <div v-if="showFreightText && product.productType === ProductType.Product"
             class="mb-20 font-fauli text-12">
            <span v-html="$translate('Product.FreightText')"></span>
        </div>
        <ProductTileCTA :index="index"
                        :content-block-index="contentBlockIndex"
                        :product="product"
                        :stock-results="stockResults"
                        :all-reserved="allReserved"/>
    </article>
</template>


<script lang="ts">
import { CatalogProductTileViewObject, ProductType } from '@/api/content/models';
import useTheme from '@/core/theme/useTheme';
import { getCultureSettings } from '@/project/content/api/contentApi';
import { setPDPBackgroundColor } from '@/project/pages/product-details/product-details-page-color';
import { trackGoToPdp } from '@/project/tracking/tracking.service';
import { find } from 'lodash';
import { computed, defineComponent, PropType, ref } from 'vue';
import Price from '../basket/Price.vue';
import { useStockStatus } from '../stock/use-stock-status.composable';
import ProductTileCTA from './ProductTileCTA.vue';
import ProductTileImage from './ProductTileImage.vue';
import { productService } from './product.service';


export default defineComponent({
    name: 'ProductTile',
    components: {
        Price,
        ProductTileImage,
        ProductTileCTA,
    },
    props: {
        product: {
            type: Object as PropType<CatalogProductTileViewObject>,
            required: true,
        },
        stockResults: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        contentBlockIndex: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const productUrl = productService.getProductRedirectUrlByProduct(props.product);
        const showFreightText = ref(false);
        const stockResult = computed(()=> {
            return {
                dataReady: props.stockResults.dataReady,
                0: find(props.stockResults, i => i.sku === props.product.sku),
            };
        });

        const { shouldShowStockStatus, allReserved, fewAvailable } = useStockStatus(stockResult);

        const { themeBackgroundColor } = useTheme();
        setPDPBackgroundColor(themeBackgroundColor.value || '');

        function trackOnClick() {
            trackGoToPdp(props.product, props.index, props.contentBlockIndex, stockResult);
        }

        const stockStatus = computed(()=> {
            return {
                shouldShowStockStatus: shouldShowStockStatus.value,
                allReserved: allReserved.value,
                fewAvailable: fewAvailable.value,
            };
        });

        getCultureSettings().then(settings => {
            showFreightText.value = settings.showFreightText;
        });
        


        return {
            productUrl,
            themeBackgroundColor,
            allReserved,
            shouldShowStockStatus,
            fewAvailable,
            stockStatus,
            trackOnClick,
            showFreightText,
            ProductType,
        };
    },
});
</script>
