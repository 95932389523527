/* tslint:disable */
/* eslint-disable */
/**
 * Normal.Web.Cms.Website
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { StoreViewObject } from '../models';
/**
 * StoresApi - axios parameter creator
 * @export
 */
export const StoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} culture 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGet: async (culture: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'culture' is not null or undefined
            assertParamExists('storesGet', 'culture', culture)
            const localVarPath = `/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (culture !== undefined) {
                localVarQueryParameter['culture'] = culture;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoresApi - functional programming interface
 * @export
 */
export const StoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} culture 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGet(culture: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StoreViewObject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGet(culture, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoresApi - factory interface
 * @export
 */
export const StoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoresApiFp(configuration)
    return {
        /**
         * 
         * @param {string} culture 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGet(culture: string, options?: any): AxiosPromise<Array<StoreViewObject>> {
            return localVarFp.storesGet(culture, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoresApi - interface
 * @export
 * @interface StoresApi
 */
export interface StoresApiInterface {
    /**
     * 
     * @param {string} culture 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesGet(culture: string, options?: any): AxiosPromise<Array<StoreViewObject>>;

}

/**
 * StoresApi - object-oriented interface
 * @export
 * @class StoresApi
 * @extends {BaseAPI}
 */
export class StoresApi extends BaseAPI implements StoresApiInterface {
    /**
     * 
     * @param {string} culture 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGet(culture: string, options?: any) {
        return StoresApiFp(this.configuration).storesGet(culture, options).then((request) => request(this.axios, this.basePath));
    }
}
