<template>
    <article class="fallback">
        <h2><b>'{{ alias }}'</b> page alias not registered! (call registerPage('{{ alias }}'', ...))</h2>
        <pre>{{ jsonContent }}</pre>
    </article>
</template>

<script lang="ts">
import { PageData } from '@/api/content/models';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'FallbackPage',
    props: {
        model: {
            type: Object as PropType<PageData>,
            required: true,
        },
        alias: {
            required: true,
            type: String,
        },
    },
    setup(props) {
        return {
            jsonContent: JSON.stringify(props.model, null, 2),
        };
    },
});
</script>

<style scoped>
.fallback {
    border: 1px dashed black;
    background-color: rgba(0, 131, 255, 0.6);
    padding: 10px;
    overflow: hidden;

    & pre {
        font-size: 16px;
    }
}
</style>
