<template>
    <ul class="flex flex-wrap justify-center list-reset">
        <li v-for="(socialMediaIcon, index) in socialMediaIcons"
            :key="index">
            <a :href="socialMediaIcon.link?.url"
               :target="socialMediaIcon.link?.target"
               class="mx-12">
                <img :key="index"
                     :src="socialMediaIcon.iconUrl"
                     :alt="socialMediaIcon.altText ?? ''"
                     :title="socialMediaIcon.altText ?? ''"
                     class="mb-10 h-35 md:h-40"
                     loading="lazy">
            </a>
        </li>
    </ul>
</template>

<script setup lang="ts">
import { SocialMediaIconItem } from '@/api/content';
import contentApi from '@/project/content/api/contentApi';
import { onBeforeMount, ref } from 'vue';
const socialMediaIcons = ref<SocialMediaIconItem[]>([]);

onBeforeMount(async() => {
    const settings = await contentApi.settings;
    socialMediaIcons.value = settings.socialMediaIcons?.map(p => p as SocialMediaIconItem);
});
</script>
