<template>
    <DrawerDialog position="left"
                  :use-slide-animation="true">
        <div class="mb-20 w-[9.7rem] text-center md:hidden center">
            <LogoTagLine/>
        </div>
        <div class="menu-items-wrap">
            <MenuItems/>
        </div>
    </DrawerDialog>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue';
import DrawerDialog from '../../dialog/DrawerDialog.vue';
import MenuItems from './MenuItems.vue';
import LogoTagLine from '@/assets/images/LogoTagLine.vue';
import { useRoute } from 'vue-router';
import { closeDialog } from '@/project/dialog/dialog';

export default defineComponent({
    name: 'MobileMenuDialog',
    components: { DrawerDialog, MenuItems, LogoTagLine },
    setup() {
        const route = useRoute();
        const path = computed(() => {
            return route.path;
        });

        watch(path, (route, oldRoute) => {
            if (route !== oldRoute) {
                closeDialog();
            }
        });

        return {
        };
    },
});
</script>

<style scoped>
.menu-items-wrap {
    @apply overflow-y-auto h-full w-full pt-10;

    &:after {
        content: '';
        height: 6rem;
        width: 100%;
        background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1)) left repeat;
        bottom: .2rem;
        @apply block sticky z-1;
    }
}
.logo-tagline{
    font-size: 1rem;
}
</style>
