<template>
    <transition appear
                enter-active-class="animate__animated animate__fadeIn u-anim-dur-500"
                leave-active-class="animate__animated animate__fadeOut u-anim-dur-500">
        <article v-if="data.messages.length"
                 class="fixed top-0 z-error-toaster w-full animate__animated animate__fadeIn">
            <div v-for="message in data.messages"
                 :key="message.errorId"
                 class="p-30 font-fauli text-20 text-white"
                 :class="getBgColor(message.messageType)">
                <div class="message">
                    <p class="m-auto"
                       v-html="getMessage(message.message)"></p>
                    <template v-if="message.errorId">
                        [{{ message.errorId }}]
                    </template>
                    <button class="pl-30">
                        <CIcon name="close-content"
                               width="50px"
                               height="50px"
                               original
                               class="w-50 h-auto text-white transition-transform hover:rotate-180"
                               @click="clearMessage(message)"/>
                    </button>
                </div>
            </div>
        </article>
    </transition>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { ClientMessage, ClientMessageType, ClientMessageWithError, GeneralErrorEventKey } from '@/core/messages/types';
import bus from '@/core/bus';

// const AUTO_KILL_TIME = 10000; // ms.

export default defineComponent({
    name: 'ErrorToaster',
    setup() {
        const data: { messages: ClientMessage[] } = reactive({ messages: [] });

        const getBgColor = (messageType: ClientMessageType): string => {
            switch (messageType) {
            case ClientMessageType.Info:
                return 'bg-pink-100/80';
            case ClientMessageType.Warning:
                return 'bg-yellow-100/80';
            case ClientMessageType.Error:
            default:
                return 'bg-red-100/80';
            }
        };

        const getMessage = (msgOrLabel: string): string => {
            return msgOrLabel;
        };

        const addMessages = (clientMessages: ClientMessageWithError): void => {
            clientMessages.messages.forEach(msg => {
                if (msg.clearMessages) {
                    clearAllMessages();
                }
                msg = reactive(msg);
                msg.errorId = clientMessages.errorId;
                // setTimeout(() => clearMessage(msg), AUTO_KILL_TIME);
            });
            data.messages = data.messages.concat(clientMessages.messages);
        };

        const clearMessage = (msg: ClientMessage): void => {
            data.messages = data.messages.filter(m => m !== msg);
        };

        const clearAllMessages = (): void => {
            data.messages = [];
        };

        bus.on(GeneralErrorEventKey, addMessages);

        return {
            data,
            getBgColor,
            getMessage,
            addMessages,
            clearMessage,
        };
    },
});
</script>

<style scoped>
    .message {
        word-break: break-word;
        line-height: 2.6rem;
        @apply w-full max-w-page-max mx-auto flex items-center justify-between text-center px-20 max-h-200 overflow-auto;
    }
</style>
