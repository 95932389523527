import { FormsViewModel } from '@/api/content';
import { formsPayloadLogging } from '@/project/basket/basket.service';
import axios from 'axios';
import { formApiHost } from '@/core/infrastructure/environment';
import { getCultureSettings } from '@/project/content/api/contentApi';
import { BasicAuthenticationValues } from '@/api/content/models/basic-authentication-values';
export const UploadFileError = 'UploadFileError';

const culturePromise = getCultureSettings().then(settings => settings.culture);

class FormsService {
    public async sendFormular(data: any, formsPageModel: FormsViewModel, authSettings: BasicAuthenticationValues, recaptchaHeader: string) {

        const formData = new FormData();
        let loggingText = ''; 

        for(const key in data) {
            if(key === 'files' && data[key] !== undefined) {
                data[key].forEach(file => {
                    formData.append(key, file);    
                });
            } else {
                formData.append(key, data[key]);
            }

            if(key === 'files' && data[key] !== undefined){
                loggingText += key + ': ';
                data[key].forEach(file => {
                    loggingText += '{Filename: ' + file.name + ', file size: ' + file.size + '} '; 
                });
                
            }else{
                loggingText += key + ': ' + data[key] + ' '; 
            }            
        }

        this.getSettingsMap(formsPageModel).forEach((key) => {
            formData.append(key, formsPageModel[key]);
        });

        await formsPayloadLogging('SendFormular (forms.service.ts)', loggingText);
        const formApiEndoint = `${formApiHost}/api/FormsBuilder/SendFormEmail`;
        const culture = await culturePromise;
        const encodedBasicHeader = btoa(`${authSettings.userName}:${authSettings.password}`);

        // Using Axios directly instead of swagger generated service due to complexity of openapi-generator-cli generating unwanted types.
        // We don't gain much by trying to make it work as we just want to pass the form data to the service.
        return await axios.post(formApiEndoint, formData, {
            params: {
                culture: culture,
                formType: formsPageModel.formsBlockName ? formsPageModel.formsBlockName : 'unknown',
            },
            headers: {
                Authorization: `Basic ${encodedBasicHeader}`,
                'X-Recapcha': recaptchaHeader,
            },
        });
        
    }

    private  getSettingsMap = (model: FormsViewModel) => {
        const settings = {
            emailReceivers: model.emailReceivers, 
            emailSubject:  model.emailSubject,
            headline:  model.headline,
        };
    
        const standardForm = Object.keys(settings);
        return standardForm;
    };
}

const formService = new FormsService();

export { formService };
