import { BasketViewModel } from '@/api/checkout';
import { localStorageService, sessionStorageService } from '@/core/storage/storage.service';
import { basketKeys } from '@/project/basket/basket.service';
import router, { PageNames } from '@/router';
import { RedirectError } from '../HttpRedirectError';

const checkoutRoutes = [
    PageNames.GiftCardCheckoutReceipt,
    PageNames.CheckoutReceipt,
];

export const basketStateHandler = (response) => {
    const basket: BasketViewModel = response.data?.model?.id ? response.data?.model : response.data?.basket?.model ?? null;

    const isNotInReceiptRoute = !checkoutRoutes.includes(router.currentRoute.value.name as PageNames);

    if (basket && basket.isCompleted && isNotInReceiptRoute) {
        localStorageService.removeItem(basketKeys.Product);
        sessionStorageService.removeItem(basketKeys.GiftCard);

        throw new RedirectError();
    }

    if (router.currentRoute.value.name !== PageNames.GiftCardCheckoutInformation &&
        router.currentRoute.value.name !== PageNames.GiftCardCheckoutPayment &&
        router.currentRoute.value.name !== PageNames.GiftCardCheckoutReceipt) {
        sessionStorageService.removeItem(basketKeys.GiftCard);
    }

    return response;
};