import { App } from 'vue';
import './config';
import { setupAxiosInterceptor } from './config/axios.config';
import contentConfig from './content';
import formsConfig from './forms';
import navigationConfig from './navigation';
import GTMConfig from './tracking/tracking.config';
import dynamicRouteConfig from './dynamic-routes';

export default async function config(app: App<Element>): Promise<void> {
    formsConfig(app);
    contentConfig();
    navigationConfig(app);
    GTMConfig();
    setupAxiosInterceptor();
    await dynamicRouteConfig(); // Must be before getBasket.
}
