import { createRouter, createWebHistory, RouteMeta, RouteRecordRaw } from 'vue-router';
import CmsPageProxy, { pageCache } from '@/core/content/CmsPageProxy.vue';
import bus from '@/core/bus';
import { NEW_PAGE_SET } from '@/core/content/constants';
import { nextTick, ref } from 'vue';
import { CheckoutStep } from '../api/checkout/models/checkout-step';
import { trackPage } from '@/project/tracking/tracking.service';
import { getCultureSettings } from '@/project/content/api/contentApi';
import { basketKeys } from '@/project/basket/basket.service';
import { getBasket } from '@/project/basket/basket.service';
import { sessionStorageService } from '@/core/storage/storage.service';

const culturePromise = getCultureSettings().then(settings => settings.commerceEnabled);

export enum PageNames {
    ProductDetail = 'ProductDetail',
    GiftCardDetail = 'GiftCardDetails',
    CheckoutInformation = 'CheckoutInformation',
    CheckoutPayment = 'CheckoutPayment',
    CheckoutDelivery = 'CheckoutDelivery',
    CheckoutReceipt = 'CheckoutReceipt',
    GiftCardCheckoutInformation = 'GiftcardInformation',
    GiftCardCheckoutPayment = 'GiftCardPayment', 
    GiftCardCheckoutReceipt = 'GiftCardReceipt',
}

export interface RouterMetaData extends RouteMeta {
    checkoutStep?: CheckoutStep,
    isServerPage?: boolean
}

export function getCurrentRouteMetaData() : RouterMetaData | undefined {
    return router.currentRoute.value?.meta as RouterMetaData | undefined;
}

export function getPageData() {
    return {
        meta: pageCache.get(router.currentRoute.value.path)?.metadata || router.currentRoute.value?.meta,
        alias: pageCache.get(router.currentRoute.value.path)?.alias || router.currentRoute.value?.name,
    };
}

// Setup dynamically
const routes: Array<RouteRecordRaw> = [];
export const rootPath = '/';

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        scrollToAnchor(to, savedPosition);
        if (!to.meta.isServerPage) {
            trackPage();
            return {
                top: savedPosition?.top ?? 0,
            };
        }
        return new Promise((resolve) => {
            bus.once(NEW_PAGE_SET, () => {
                // Wait for new page data to be set and wait a bit for rendering
                nextTick(() => {
                    resolve({
                        top: savedPosition?.top ?? 0,
                    });
                });
                if (to.meta.isServerPage) trackPage();
            });
        });
    },
});

// Pages and content are async, so try to look up anchor some time before scrolling
const timesToTry = 20;
let timesTried = 0;
function scrollToAnchor(to, savedPosition) {
    if (to.hash && !savedPosition) {
        const anchor = document.querySelector(to.hash);
        if (anchor) {
            const headerOffset = 65;
            const elementPosition = anchor.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
        else {
            setTimeout(()=> {
                timesTried += 1;
                if (timesTried === timesToTry) return;
                scrollToAnchor(to, savedPosition);
            }, 300);            
        }
    }
}

let routerInitialized = false;
router.beforeEach((to, from)=> {
    if (routerInitialized && to.path === from.path) {
        scrollToAnchor(to, null);
        return false; 
    }
});

const lastPathBeforeCheckout = ref(rootPath);
router.afterEach((to, from) => {
    if (!(to.meta as RouterMetaData).checkoutStep) {
        // Remember last step outside checkout to be able to go back from checkout
        lastPathBeforeCheckout.value = to.fullPath;
    }
    if ((from.meta as RouterMetaData).CheckoutStep && !(to.meta as RouterMetaData).CheckoutStep) {
        sessionStorageService.removeItem(basketKeys.GiftCard);
        setTimeout(()=> {
            getBasket();
        });
    }
});

export function getLastPathBeforeCheckout(): string {
    return lastPathBeforeCheckout.value;
}

export async function setupDynamicRoutes({ checkoutInformationUrl, checkoutDeliveryUrl, checkoutPaymentUrl, checkoutReceiptUrl,
    giftCardCheckoutInformationUrl, giftCardCheckoutPaymentUrl, giftCardCheckoutReceiptUrl }: {
    checkoutInformationUrl: string,
    checkoutDeliveryUrl: string,
    checkoutPaymentUrl: string,
    checkoutReceiptUrl: string,
    giftCardCheckoutInformationUrl: string,
    giftCardCheckoutPaymentUrl: string,
    giftCardCheckoutReceiptUrl: string,
}): Promise<void> {
    const commerceEnabled = await culturePromise;
    if(commerceEnabled) {
        router.addRoute({
            name: PageNames.CheckoutReceipt,
            path: checkoutReceiptUrl,
            meta: { checkoutStep: CheckoutStep.Receipt },
            component: () => import('@/project/pages/checkout/CheckoutReceipt.vue'),
        });

        router.addRoute({
            name: PageNames.GiftCardCheckoutReceipt,
            path: giftCardCheckoutReceiptUrl,
            meta: { checkoutStep: CheckoutStep.Receipt },
            component: () => import('@/project/pages/checkout/giftcard/GiftCardCheckoutReceipt.vue'),
        });

        router.addRoute({
            path: `/${getBasePath(checkoutInformationUrl)}`,
            component: () => import('@/project/pages/checkout/CheckoutView.vue'),
            children: [
                {
                    name: PageNames.CheckoutInformation,
                    path: `${getLeafPath(checkoutInformationUrl)}`,
                    meta: { checkoutStep: CheckoutStep.Information },
                    component: () => import('@/project/pages/checkout/CheckoutInformation.vue'),
                },
                {
                    name: PageNames.CheckoutDelivery,
                    path: `${getLeafPath(checkoutDeliveryUrl)}`,
                    meta: { checkoutStep: CheckoutStep.Delivery },
                    component: () => import('@/project/pages/checkout/CheckoutDelivery.vue'),
                },
                {
                    name: PageNames.CheckoutPayment,
                    path: `${getLeafPath(checkoutPaymentUrl)}`,
                    meta: { checkoutStep: CheckoutStep.Payment },
                    component: () => import('@/project/pages/checkout/CheckoutPayment.vue'),
                },
            ],
        });

        router.addRoute({
            path: `/${getBasePath(giftCardCheckoutInformationUrl)}`,
            component: () => import('@/project/pages/checkout/giftcard/GiftCardCheckoutView.vue'),
            children: [
                {
                    name: PageNames.GiftCardCheckoutInformation,
                    path: `${getLeafPath(giftCardCheckoutInformationUrl)}`,
                    meta: {CheckoutStep: CheckoutStep.Information},
                    component: () => import('@/project/pages/checkout/giftcard/GiftCardCheckoutInformation.vue'),
                },
                {
                    name: PageNames.GiftCardCheckoutPayment,
                    path: `${getLeafPath(giftCardCheckoutPaymentUrl)}`,
                    meta: {CheckoutStep: CheckoutStep.Payment},
                    component: () => import('@/project/pages/checkout/giftcard/GiftCardCheckoutPayment.vue'),
                },
            ],
        });

        router.addRoute({
            name: PageNames.ProductDetail,
            path: '/product/:product',
            component: () => import('@/project/pages/product-details/ProductDetailsPage.vue'),
        });

        router.addRoute({
            name: PageNames.GiftCardDetail,
            path: '/gift-card-pdp/',
            component: () => import('@/project/pages/gift-card-details/GiftCardDetailsPage.vue'),
        });
    }
    // Fallback for CMS
    router.addRoute({
        path: '/:pathMatch(.*)*',
        component: CmsPageProxy,
        meta: {
            isServerPage: true,
        },
        beforeEnter: ()=> {
            routerInitialized = true;
        },
    });

    function getBasePath(path: string): string {
        const elems = path.split('/');
        if (elems.length < 2) throw new Error('Path needs a base and subpath');
        return elems[1];
    }

    function getLeafPath(path: string): string {
        const elems = path.split('/');
        if (elems.length < 3) throw new Error('Path needs a base and subpath');
        return elems[2];
    }
}

export default router;
