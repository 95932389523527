import log from '../infrastructure/logging';
import { App } from 'vue';

class Dictionary {
    private dictionaryMap;

    public get(key: string, ...args: string[]) {
        const translated = this.dictionaryMap.get(key);
        if (translated === undefined) {
            log.error(`### Key '${key}' not found in dictionary ###`);
            return `##${key}`;
        }
        return this.format(translated, ...args);
    }

    public exists(key: string): boolean {
        return !!this.dictionaryMap.get(key);
    }

    private format(input: string, ...args: string[]): string {
        return args.reduce((result, arg, ix) => {
            return result.split(`{${ix}}`).join(arg);
        }, input);
    }

    init(labels: Record<string, string>) {
        this.dictionaryMap = new Map<string, string>(Object.entries(labels));
    }
}

const dictionary = new Dictionary();

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $translate: (key: string, ...params: string[]) => string;
    }
}

export function config(app: App): void {
    app.config.globalProperties.$translate = dictionary.get.bind(dictionary);
}

export default dictionary;
