declare global {
    interface Window {
        appData: {
            cmsHost: string,
            checkoutHost: string,
            paymentApiHost: string,
            stockApiHost: string,
            formApiHost: string,
            applicationInsightKey: string,
            dictionaryKey: string,
            siteSettings: any,
            firstPage: any
            isPrerenderRequest: boolean
        }
    }
}

export function isDevMode(): boolean {
    return !isProdMode();
}

export function isProdMode(): boolean {
    return import.meta.env.PROD;
}

export const cmsHost = window.appData.cmsHost;
export const checkoutHost = window.appData.checkoutHost;
export const paymentApiHost = window.appData.paymentApiHost;
export const stockApiHost = window.appData.stockApiHost;
export const formApiHost = window.appData.formApiHost;
export const applicationInsightKey = window.appData.applicationInsightKey;
export const dictionaryKey = window.appData.dictionaryKey;
export const siteSettings = window.appData.siteSettings || undefined;
export const firstPage = window.appData.firstPage || undefined;
export const isPrerenderRequest = window.appData.isPrerenderRequest || false;
