<template>
    <div>
        <div ref="containerRef"
             class="relative pb-6 bg-transparent bg"
             :class="[{'bg-error': errorMessage}, bgHeight ]"> 
            <textarea :id="id"
                      class="px-15 pb-3 w-[99%] min-h-200 max-h-600 font-fauli text-12 bg-transparent resize-y"
                      :class="[!value?.length ? 'pt-0 mt-15' : 'pt-15 mt-20']" 
                      :name="name" 
                      :value="value"
                      :placeholder="placeholder"
                      cols="30"
                      rows="10"
                      @change="handleChange"
                      @input="handleChange"/>

            <div v-if="required"
                 class="absolute top-9 right-1">
                <CIcon name="required-mark"
                       class="my-auto mr-12 w-10 h-10 text-pink-100"/>
            </div>
            <div class="absolute top-7 left-0 ml-16 max-w-260 truncate xs:top-4 sm:max-w-330 md:max-w-485"
                 :class="{'md:max-w-full': !!value}">
                <label :for="id"
                       class="max-w-150 font-fauli text-10 font-light opacity-0"
                       :class="{'opacity-100 truncate': !!value}">{{ label }}</label>
            </div>
        </div>
        <InputErrorMessage v-if="errorMessage"
                           class="mb-18 font-fauli"
                           :name="name"/> 
    </div>
</template>
<script lang="ts" setup>
import CIcon from '@/core/layout/icons/CIcon.vue';
import { useField } from 'vee-validate';
import { onMounted, ref, watch } from 'vue';
import InputErrorMessage from './InputErrorMessage.vue';

type Props = {
    id?: string;
    name: string;
    label: string;
    required: boolean;
    placeholder: string;
}

const heightToImageHeight: {[key: string]: string} = {
    1: 'bg-textarea-scale-1',
    2: 'bg-textarea-scale-2',
    3: 'bg-textarea-scale-3',
    4: 'bg-textarea-scale-4',
    5: 'bg-textarea-scale-5',
    6: 'bg-textarea-scale-6',
    7: 'bg-textarea-scale-7',
    8: 'bg-textarea-scale-7',
    9: 'bg-textarea-scale-7',
    10: 'bg-textarea-scale-7',
};


const props = defineProps<Props>();

const containerRef = ref<HTMLDivElement>();
const bgHeight = ref<string>(heightToImageHeight[1]);

onMounted(() => {
    // TODO: Refactor
    if(containerRef.value) {
        const obs = new ResizeObserver(() => {
            let index = '1';
            const ch = containerRef.value?.clientHeight;

            if(!ch) {
                return;
            }

            if(ch < 1000) {
                index = ch.toString()[0];
            } else {
                index = ch.toString().slice(0,2);
            }

            if(index) {
                bgHeight.value = heightToImageHeight[Number(index) > 10 ? '10' : index];
            }
        });

        obs.observe(containerRef.value);
    }
});


const {
    value,
    errorMessage,
    validate,
    handleChange,
    
} = useField<string>(props.name, {required: !!props.required} , {
    validateOnValueUpdate: true,
    label: props.label,
});

watch(value, async(newValue) => {
    if(newValue === value.value) {
        return;
    }
    value.value = newValue;
    await validate();
});

</script>

<style scoped>
    .bg {
        background-image: url("@/assets/images/textarea.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
    .bg-error {
        background-image: url("@/assets/images/input1-error.svg");
    }

    textarea::-webkit-scrollbar {
        width: 1em;
    }

    textarea::-webkit-scrollbar-thumb {
        @apply bg-pink-100 rounded-2xl;
    }

    textarea::-webkit-resizer {
        background-image: url("@/assets/images/textarea-resizer.svg");
        background-size: cover;
    }

</style>
