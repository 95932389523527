<template>
    <span v-if="showFormattedAmount"
          class="font-numbers"
          v-html="amountValue"></span>
    <template v-else>
        {{ amountValue }}
    </template>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { AmountViewObject } from '@/api/checkout';
import { formatAmount } from '@/project/intl/amountFormatter';
import { getCultureSettings } from '@/project/content/api/contentApi';

export default defineComponent({
    name: 'Price',
    props: {
        amount: {
            required: true,
            type: Object as PropType<AmountViewObject | null | undefined>,
        },
        currency: {
            required: false,
            type: String,
            default: '',
        },
        showFormattedAmount: {
            type: Boolean,
        },
    },
    setup(props) {
        const culture = ref('');
        let pricePostfix = ref(''); 
        getCultureSettings().then((settings)=> {
            culture.value = settings.culture;
            pricePostfix.value = settings.pricePostfix.length > 0 ? settings.pricePostfix : '$'; 
        });
        const amountValue = computed<string | number | undefined | void>(() => {
            let amountToShow = props.showFormattedAmount ? props.amount?.formatted : props.amount?.value ? formatAmount(culture.value, props.amount.value, props.currency) : '';
            
            if(amountToShow && props.showFormattedAmount === true){
                amountToShow = amountToShow + pricePostfix.value; 
            }    

            return amountToShow;
        });
        return {
            amountValue,
        };
    },

});
</script>
