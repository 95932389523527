import { isPrerenderRequest } from '@/core/infrastructure/environment';
export type DataLayerEntry = { [key: string]: any };

declare global {
    interface Window {
        dataLayer: Array<DataLayerEntry>,
    }
}

export default function GTMConfig() {
    if (isPrerenderRequest) return;
    window.dataLayer = [];
    window.dataLayer.push({
        originalLocation: document.location.protocol + '//' +
                          document.location.hostname +
                          document.location.pathname +
                          document.location.search,
    });
    runGTMScript();
}

function runGTMScript() {
    const script = document.createElement('script');
    script.text =
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KHQ4SMZ');
`;
    document.head.appendChild(script);
}
