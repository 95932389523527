<template>
    <button class="block relative md:ml-10"
            @click="openOverlay()">
        <CIcon class="block w-32 h-auto"
               name="icon-menu"
               fill/>
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import openDialog from '@/project/dialog/dialog';
import MobileMenuDialog from '@/project/navigation/main-menu/MobileMenuDialog.vue';

export default defineComponent({
    name: 'MobileMenu',
    components: {
    },
    setup() {
        function openOverlay() {
            openDialog(MobileMenuDialog, { position: 'left' });
        }

        return {
            openOverlay,
        };
    },
});
</script>

<style scoped>
</style>
