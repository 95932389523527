<template>
    <div class="p-20 text-center wrapper">
        <div class="font-fauli text-[9.6rem] tracking-[-1.7rem] leading-none text-green-100">
            {{ $translate('Errors.NotFound.NotFoundHeader') }}
        </div>
        <div class="mb-40 font-fauli text-[3rem] md:text-28">
            <p class="mb-15">
                {{ $translate('Errors.NotFound.NotFoundText') }}
            </p>
        </div>
        <div class="bottom-0 left-0 mb-40 -ml-20 md:absolute md:mb-0 md:ml-0">
            <div class="flex relative">
                <div v-if="culture">
                    <CIcon :name="getNotFoundDesktopGraphics(culture)"
                           class="hidden w-200 h-auto md:block md:-mb-3 md:w-180 xl:w-full"
                           original/>

                    <CIcon :name="getNotFoundMobileGraphics(culture)"
                           class="w-200 h-auto md:hidden"
                           original/>
                </div>
                <div class="relative mt-60 mb-auto -ml-60 md:mt-30 md:-ml-30 xl:mt-60">
                    <NotFoundSpeechBubble class="w-200 md:w-[22.4rem] xl:w-auto"/>
                    <p v-if="$translate('Errors.NotFound.NotFoundSpeechBubble').length > 27" 
                       class="absolute inset-0 px-20 mx-auto mt-70 ml-25 max-w-300 font-fauli text-14 text-center sm:mt-80 sm:text-14 md:mt-[20] md:text-16 xl:pr-0 xl:pl-15 xl:mt-25 xl:ml-[7.5rem] xl:text-[3.3rem]"
                       v-html="$translate('Errors.NotFound.NotFoundSpeechBubble')">
                    </p>
                    <p v-else 
                       class="absolute inset-0 px-20 mx-auto mt-60 ml-15 max-w-300 font-fauli text-14 text-center sm:mt-80 sm:text-14 md:mt-[20] md:text-16 xl:mt-35 xl:ml-[7.5rem] xl:text-[3.3rem]"
                       v-html="$translate('Errors.NotFound.NotFoundSpeechBubble')">
                    </p>
                </div>
            </div>
        </div>
        <div class="inset-x-0 bottom-0 mt-50 mb-30 font-fauli text-[2.6rem] md:absolute md:left-auto md:mt-0 md:text-[3.5rem] xl:text-[4.5rem]">
            {{ $translate('Errors.NotFound.PageNotFoundText') }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { trackError } from '../tracking/tracking.service';
import NotFoundSpeechBubble from '@/assets/images/NotFoundSpeechBubble.vue';
import CIcon from '@/core/layout/icons/CIcon.vue';
import { getCultureSettings } from '@/project/content/api/contentApi';

export default defineComponent({
    name: 'NotFound',
    components: {
        NotFoundSpeechBubble,
        CIcon,
    },
    setup() {
        trackError('404');
        const culture = ref('');

        getCultureSettings().then(settings => {
            culture.value = settings.culture;
        });

        function getNotFoundDesktopGraphics(culture) {
            return `notfound-desktop_${culture}`;
        }

        function getNotFoundMobileGraphics(culture) {
            return `notfound-mobile_${culture}`;
        }

        return {
            getNotFoundDesktopGraphics,
            getNotFoundMobileGraphics,
            culture,
        };
    },
});
</script>

<style scoped>
.wrapper {
    max-width: 115rem;
    min-height: 5.6rem;
    margin: 0 auto;
    @apply h-full relative;
}

@screen md {
    .wrapper {
        min-height: 41rem;
        margin: 0 4rem
    }
}

@screen lg {
    .wrapper {
        max-width: 115rem;
    }
}

@screen xl {
    .wrapper {
        min-height: 65rem;
        @apply mx-auto;
    }
}
</style>
