<template>
    <article>
        <ContentBlockList v-if="blocksAbove"
                          :blocks="blocksAbove"/>

        <div v-if="model.product && model.product.availabilityState == ProductAvailabilityState.Default"
             class="px-20 pt-60 pb-50 w-full md:grid md:grid-cols-12 md:gap-x-20 lg:px-0">
            <div class="w-full md:flex md:col-span-12 md:col-start-1 lg:col-span-10 lg:col-start-2 xl:col-span-9 xl:col-start-3">
                <div class="my-auto md:mx-auto lg:mr-auto">
                    <article class="md:grid md:grid-cols-2 md:gap-40 lg:gap-50 xl:gap-60">
                        <div class="image-wrap">
                            <ImageWrap v-if="model.product.packshots[0]"
                                       format="webp"
                                       :image="model.product.packshots[0]"
                                       :width="800"
                                       :name="model.product.displayName"
                                       styles="mx-auto max-w-full max-h-500"/>
                        </div>
                        <div class="mx-auto w-full max-w-400 md:my-auto lg:max-w-none">
                            <h1 class="mb-5 text-18 leading-24 md:text-24 md:leading-32">
                                {{ model.product.displayName }}
                            </h1>
                            <div class="mb-20 md:text-16 product-rich-text test"
                                 v-html="model.product.description"></div>
                            <div v-if="model.product.certifications"
                                 class="mb-10">
                                <img v-for="(certification, index) in model.product.certifications"
                                     :key="`certification${index}`"
                                     :src="certification.url"
                                     width="32"
                                     class="mr-10 w-32">
                            </div>
                            <div v-if="model.product.brand || model.product.productName"
                                 class="mb-3 leading-none">
                                <div class="font-bold">
                                    {{ model.product.brand }}
                                </div>
                                <div class="font-bold">
                                    {{ model.product.productName }}
                                </div>
                            </div>
                            <div class="flex">
                                <div class="mt-2 mr-20 mb-15 -ml-2">
                                    <Price :amount="model.product.unitPrice"
                                           show-formatted-amount
                                           class="font-numbers text-52 leading-none"/>
                                    <p class="-mt-5 font-10">
                                        <span v-if="model.product.pricePerUnit">{{ model.product.pricePerUnit }}</span>
                                    </p>
                                </div>
                                <StockStatusInfo v-if="shouldShowStockStatus"
                                                 :sku="model.product.sku"
                                                 :stock-status="stockStatus"/>
                                <ProductSplash v-else-if="model.product.splashText"
                                               :product="model.product"
                                               splash-pdp/>
                            </div>
                            <div v-if="showFreightText"
                                 class="mb-20 font-fauli text-12">
                                <span v-html="$translate('Product.FreightText')"></span>
                            </div>
                            <div class="flex relative z-1 flex-wrap items-center mb-10">
                                <AddToBasket v-if="!allReserved && !soldOut"
                                             :key="model.product.sku"
                                             class="mr-20 ml-0"
                                             :model="model.product"/>
                                <ReservationError :sku="model.product.sku"
                                                  class="mt-0"/>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
        <ProductNotStarted v-else-if="model.product.availabilityState == ProductAvailabilityState.NotAvailableYet"
                           :starts-in="model.product.startsInMs"/>
        <ProductExpired v-else/>
        <ContentBlockList v-if="blocksBelow"
                          :blocks="blocksBelow"/>
    </article>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, PropType, watch, ref } from 'vue';
import AddToBasket from '@/project/basket/add-to-basket/AddToBasket.vue';
import ReservationError from '@/project/basket/ReservationError.vue';
import { ProductDetailsPage, ProductAvailabilityState, ProductEnrichment } from '@/api/content/models';
import ProductNotStarted from './ProductNotStarted.vue';
import ProductExpired from './ProductExpired.vue';
import ContentBlockList from '@/core/content/ContentBlockList.vue';
import Price from '../../basket/Price.vue';
import useTheme from '@/core/theme/useTheme';
import { getPDPBackgroundColor } from './product-details-page-color';
import { useStockStatus } from '@/project/stock/use-stock-status.composable';
import StockStatusInfo from '@/project/stock/StockStatusInfo.vue';
import ProductSplash from '@/project/product/ProductSplash.vue';
import ImageWrap from '@/project/shared/ImageWrap.vue';
import { useStock } from '@/project/stock/stock.service';
import { trackProductDetailsPage } from '@/project/tracking/tracking.service';
import { useHead } from '@vueuse/head';
import { getCultureSettings } from '@/project/content/api/contentApi';
import router from '@/router';


export default defineComponent({
    name: 'ProductDetails',
    components: {
        AddToBasket,
        ReservationError,
        ProductNotStarted,
        ProductExpired,
        ContentBlockList,
        Price,
        StockStatusInfo,
        ProductSplash,
        ImageWrap,
    },
    props: {
        model: {
            type: Object as PropType<ProductDetailsPage>,
            required: true,
        },
    },
    setup(props) {
        let redirectValue = props.model.enrichment?.content as (ProductEnrichment | null);
        const redirectUrl = redirectValue?.redirectURL?.url; 

        if(redirectUrl !== null && redirectUrl !== undefined){
            router.push(redirectUrl);   
        }

        const { stockResult } = useStock([props.model!.product!.sku]);
        const { shouldShowStockStatus, soldOut, allReserved, fewAvailable, dataReady } = useStockStatus(stockResult);
        const showFreightText = ref(false);

        const blocksAbove = props.model.product.availabilityState === ProductAvailabilityState.Default ? (props.model.enrichment?.content as (ProductEnrichment | null))?.blocksAbove : undefined;
        const blocksBelow = props.model.product.availabilityState === ProductAvailabilityState.Default ? (props.model.enrichment?.content as (ProductEnrichment | null))?.blocksBelow : undefined;

        const { setThemeBackgroundColor, themeBackgroundColor } = useTheme();
        setThemeBackgroundColor(getPDPBackgroundColor());

        const themeBackgroundColorFormatted = computed(()=> {
            return themeBackgroundColor.value?.replace('#', '');
        });

        const stockStatus = computed(()=> {
            return {
                shouldShowStockStatus: shouldShowStockStatus.value,
                allReserved: allReserved.value,
                fewAvailable: fewAvailable.value,
                soldOut: soldOut.value,
            };
        });

        watch(dataReady, () => {
            if (dataReady.value) {
                nextTick(()=> {
                    trackProductDetailsPage(props.model.product, stockResult);
                });
            }
        });
    
        const headObj = {
            title: props.model.product.displayName || '',
        };

        useHead(headObj);

        getCultureSettings().then(settings => {
            showFreightText.value = settings.showFreightText;
        });

        return {
            fewAvailable,
            shouldShowStockStatus,
            allReserved,
            soldOut,
            ProductAvailabilityState,
            blocksAbove,
            blocksBelow,
            stockStatus,
            themeBackgroundColorFormatted,
            showFreightText,
        };
    },
});

</script>
<style scoped>
    .image-wrap {
        @apply mx-auto mt-5 mb-30 text-center max-w-400;
    }

    @screen md {
        .image-wrap {
            @apply max-w-none w-full mb-0;
        }
    }

</style>

<style>
    .product-rich-text a {
        @apply underline;
    }

    .product-rich-text p {
        @apply my-0;
    }

    strong {
        font-weight: bold;
    }
</style>
