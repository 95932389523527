import { ref, watch } from 'vue';
import { PageNames } from '@/router';
import { useRoute } from 'vue-router';

const isCheckoutMode = ref(false);

export { useCheckoutNavigationStates };

function useCheckoutNavigationStates() {
    const route = useRoute();
    watch(route, route => {
        isCheckoutMode.value = route.name === PageNames.CheckoutInformation ||
        route.name === PageNames.CheckoutDelivery ||
        route.name === PageNames.CheckoutPayment ||
        route.name === PageNames.CheckoutReceipt ||
        route.name === PageNames.GiftCardCheckoutInformation ||
        route.name === PageNames.GiftCardCheckoutPayment ||
        route.name === PageNames.GiftCardCheckoutReceipt;
    }, { immediate: true });

    return {
        isCheckoutMode,
    };
}
