<template>
    <div>
        <div class="absolute inset-x-0 h-427">
            <div ref="mapContainer" 
                 class="absolute w-full h-full">
            </div>
        </div>
        <div class="h-427 pointer-events-none">
        </div>
    </div>
</template>

<script setup lang="ts">
import { Map, NavigationControl, Popup } from 'maplibre-gl';
import { shallowRef, onMounted, onUnmounted, markRaw, PropType, watch } from 'vue';
import { IStore } from '@/project/content/StoresBlock.vue';
import 'maplibre-gl/dist/maplibre-gl.css';
import mapPinImg from '@/assets/images/normal_pin.png';
import mapClusterImg from '@/assets/images/map_cluster.png';

const props = defineProps({
    stores: {
        type: Object as PropType<IStore[]>,
        required: true,
    },
    mapLink: {
        type: String,
        required: true,
    },
    today: {
        type: String,
        required: true,
    },

    initialCoordinates: {
        type: Object as PropType<{ lat: number; lng: number; zoom: number; }>,

        default: () => ({
            lng: 10.135413499185534, 
            lat: 56.15901305514162, 
            zoom: 5.5,
        }),
    },
});

const mapContainer = shallowRef<any>(null);
const map = shallowRef<any>(null);
const apiKey = 'eyJkcGZ4IjogIm5vcm1hbCIsICJyZWYiOiAiMjAyMjA4IiwgInBhciI6ICIiLCAicHJpdnMiOiAicjFaMHIwRjBrNkJ0V3FRY09VeWtCL3k2U1VwSnYyUWJneVhldHE0SFk0WE8vM1lyVzArSzk2NEhZNFgxV094clJBTndXUT09In0.1FH9KMGw4cHGAQqD5GBisv0sMTaBOvuIJik1qIep/bOPidQ58nAjyCpCuBunmZy94FNKFKGikcCPatOkk6aYpg';

const customIcons = [
    {
        iconKey: 'normal_pin_icon', 
        path: mapPinImg,
    },
    {
        iconKey: 'normal_cluster_icon', 
        path: mapClusterImg,
    },
];

watch(() => props.stores, () => {
    const newFeatures = buildFeatures(props.stores);
    map.value.getSource('storeLocationsSource').setData({
        type: 'FeatureCollection',
        features: newFeatures,
    } as any);

    fitBounds(newFeatures);
});

const buildFeatures = (stores: IStore[]) => {
    return stores.map((store) => {
        return {
            type: 'Feature',
            properties: {
                id: `${store.name} ${store.id}`,
                description: popupDescription(store),
            },
            geometry: {
                type: 'Point',
                coordinates: [store.longitude, store.latitude],
            },
        };
    });
};

const popupDescription = (store: IStore): string => {
    const googleMapsUrl = store.googleMapsUrl != null   
        ? store.googleMapsUrl
        : `https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}`;

    return `<h4 style="line-height: 1.5">${store.name} ${ store.namePostFix}</h4>
                    <p style="line-height: 1.2">${store.address}<br />
                        ${store.postalCode} ${store.city}<br />
                        <br />
                        <a class="underline" style="outline: none" href="tel:${store.telephone}">${store.telephone}</a>
                        <br />
                        <a class="underline" href="mailto:${store.email}">${store.email}</a><br />
                        <br />
                        <span>
                            ${store.today}
                        </span>
                    </p>
                    <a target="_blank"
                       style="text-decoration:
                       underline; color: rgb(235, 0, 131); line-height: 1.2;"
                       href=${googleMapsUrl}>
                        ${props.mapLink}
                    </a>
                    `;
};

const fitBounds = (mapFeatures: any) => {
    const boundsArr = mapFeatures.map(feature => feature.geometry.coordinates);
    const bbox = [
        [Math.min(...boundsArr.map(b => b[0] ?? 1000)), Math.min(...boundsArr.map(b => b[1] ?? 1000))],
        [Math.max(...boundsArr.map(b => b[0] ?? -1)), Math.max(...boundsArr.map(b => b[1] ?? -1))]];

    if (bbox.some(coord => coord.some(co => co > -90 && co < 90 ))) {
        map.value.fitBounds(bbox, {
            padding: {top: 50, bottom:50, left: 50, right: 50},
        });
    }
};

onMounted(() => {
    map.value = markRaw(new Map({
        container: mapContainer.value,
        style: `https://normal.tiles.viamap.net/v1/style.json?token=${apiKey}`,
        center: [props.initialCoordinates.lng, props.initialCoordinates.lat],
        zoom: props.initialCoordinates.zoom,
        minZoom: 1,
        maxZoom: 18,
    }));     

    map.value.addControl(new NavigationControl({showZoom: true, showCompass: false}));

    map.value.on('load', () => {
        loadCustomIcons();

        map.value.addSource('storeLocationsSource', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: buildFeatures(props.stores),
            },
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });

        addMapLayers();         
        
        addMapActions();
    });
});

function loadCustomIcons() {
    customIcons.forEach(icon => {
        try {
            map.value.loadImage(
                icon.path,
                function(error, image) {
                    if (error) {
                        throw error;
                    }
                    map.value.addImage(icon.iconKey, image);
                },
            );
        } catch (err) {
            console.log('Got error loading image', err);
        }
    });
}

function addMapLayers(){
    map.value.addLayer({
        id: 'storeLocations',
        type: 'symbol',
        source: 'storeLocationsSource',
        filter: ['has', 'point_count'],
        layout: {
            'icon-image': ['coalesce', ['get', 'icon'], 'normal_cluster_icon' ],
            'icon-allow-overlap': true,
        },
        paint: {
            'text-color': 'white',
        },
    });

    map.value.addLayer({
        id: 'storeLocations-count',
        type: 'symbol',
        source: 'storeLocationsSource',
        filter: ['has', 'point_count'],
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Noto Sans Bold'],
            'text-size': 25,
        },
        paint: {
            'text-color': 'white',
        },
    });

    map.value.addLayer({
        id: 'storeLocation', 
        type: 'symbol', 
        source: 'storeLocationsSource', 
        filter: ['!', ['has', 'point_count']], 
        layout: {
            'icon-image': ['coalesce', ['get', 'icon'], 'normal_pin_icon' ],
            'icon-anchor': 'bottom',
            'icon-offset': [0, 5],
            'icon-size': 0.5,
            'icon-allow-overlap': true,
        }, 
        paint: {
            'text-color': 'white',
        },    
    });
}

function addMapActions(){
    map.value.on('click', 'storeLocation', function(store) {
        const coordinates = store.features[0].geometry.coordinates.slice();
        const description = store.features[0].properties.description;

        // Ensure that if the map is zoomed out such that multiple copies of the feature are visible, the popup appears over the copy being pointed to.
        while (Math.abs(store.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += store.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new Popup({ offset: 22 })
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map.value); 
    });

    map.value.on('click', (e) => {
        const features = map.value.queryRenderedFeatures(e.point, {
            layers: ['storeLocations'],
        });
        const clusterId = features[0].properties.cluster_id;
        map.value.getSource('storeLocationsSource').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
                if (err) return;

                const easingDuration = 350;
                map.value.easeTo({
                    duration: easingDuration,
                    center: features[0].geometry.coordinates,
                    zoom: zoom ?? 4,
                });
            },
        );
    });

    map.value.on('mouseenter', 'storeLocations', () => {
        map.value.getCanvas().style.cursor = 'pointer';
    });

    map.value.on('mouseleave', 'storeLocations', () => {
        map.value.getCanvas().style.cursor = '';
    });

    map.value.on('mouseenter', 'storeLocation', () => {
        map.value.getCanvas().style.cursor = 'pointer';
    });
    
    map.value.on('mouseleave', 'storeLocation', () => {
        map.value.getCanvas().style.cursor = '';
    });
}

onUnmounted(() => {
    map.value?.remove();
});

</script>
