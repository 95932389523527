<template>
    <component :is="blockComponent"
               :model="model.content"
               :alias="alias"
               v-bind="$attrs"/>
</template>

<script lang="ts" setup>
import { ContentElement } from '@/api/content/models';
import { blockResolver } from '@/core/content/componentResolver';
import { computed } from 'vue';

type Props = {
    model: ContentElement;
}

const props = defineProps<Props>();

const alias = props.model.alias;

const blockComponent = computed(() => blockResolver.resolve(alias));

</script>
