<template>
    <article class="px-20 md:px-0"
             :class="{'py-60 md:grid md:grid-cols-12 md:gap-x-20' : !dialogMode}"
             :style="[colorStyle]">
        <div :class="gridColumnClasses">
            <div v-if="model.textRTE"
                 v-rte-links
                 class="font-europa text-16 font-light rich-text"
                 v-html="model.textRTE"></div>
            <div :class="alignmentClasses">
                <CallToAction v-if="model.ctaButtonLink"
                              :variant="buttonVariant"
                              v-bind="linkProps"
                              class="mt-20">
                    {{ model.ctaButtonText }}
                </CallToAction>
            </div>
        </div>
    </article>
</template>

<script lang="ts">
import { TextBlock, ContentWidth, ContentAlignment } from '@/api/content/models';
import { defineComponent, inject, PropType } from 'vue';
import CallToAction from '@/project/navigation/call-to-action/CallToAction.vue';
import blockUtil from './util/blockUtil';
import { DialogMode } from '../dialog/constants';

export default defineComponent({
    name: 'TextBlock',
    components: {
        CallToAction,
    },
    props: {
        model: {
            required: true,
            type: Object as PropType<TextBlock>,
        },
    },
    setup(props) {
        const dialogMode = inject(DialogMode, false);
        const gridColumnClasses = props.model.width === ContentWidth.Narrow ? 'md:col-start-4 md:col-span-6' : 'md:col-start-3 md:col-span-8';
        const alignmentClasses = props.model.ctaAlignment === ContentAlignment.Left
            ? 'text-left'
            : props.model.ctaAlignment === ContentAlignment.Right
                ? 'text-right'
                : 'text-center';

        function getColorStyle() {
            const backgroundColor = blockUtil.formatHexColor(props.model.backgroundColor);
            const colorStyle: Record<string, string> = {
                color: blockUtil.formatHexColor(props.model.textColor) || 'black',
            };
            if (backgroundColor) {
                colorStyle.backgroundColor = backgroundColor;
            }
            return colorStyle;
        }

        return {
            colorStyle: getColorStyle(),
            gridColumnClasses,
            alignmentClasses,
            buttonVariant: blockUtil.buttonVariant(props.model.ctaButtonColor),
            linkProps: blockUtil.linkProps(props.model.ctaButtonLink),
            dialogMode,
        };
    },
});
</script>
