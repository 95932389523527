<template>
    <article :class="[$attrs.class]">
        <ContentBlock v-for="(block, ix) in blocksFiltered"
                      :key="ix"
                      :model="block"
                      :is-first-on-page="ix === 0"
                      :block-index="ix"/>
    </article>
</template>

<script lang="ts">
import { ContentElement } from '@/api/content/models';
import { defineComponent, PropType } from 'vue';
import ContentBlock from './ContentBlock.vue';

export default defineComponent({
    name: 'ContentBlockList',
    components: { ContentBlock },
    props: {
        blocks: {
            type: Array as PropType<ContentElement[] | null>,
            default: () => [],
        },
    },
    setup(props) {
        const blocksFiltered: ContentElement[] = [];
        if (props.blocks) {
            props.blocks.forEach(block => {
                const content = block.content;
                const fromAsString = content['from'];
                const toAsString = content['to'];

                const from = new Date(content['from']).getTime();
                const to = new Date(content['to']).getTime();
                const now = new Date().getTime();

                // NoValue can be two different values based on the parent block it comes from. It is either: new Date(0) or '0001-01-01T00:00:00'
                const fromHasNoValue = from === new Date(0).getTime() ||  fromAsString === undefined || fromAsString === null || fromAsString === '0001-01-01T00:00:00';
                const toHasNoValue = to === new Date(0).getTime() || toAsString === undefined ||  toAsString === null ||  toAsString === '0001-01-01T00:00:00';
                if (toHasNoValue && fromHasNoValue) {
                    blocksFiltered.push(block);
                } else if (to > now && fromHasNoValue) {
                    blocksFiltered.push(block);
                } else if (from < now && toHasNoValue) {
                    blocksFiltered.push(block);
                } else if (from < now && to > now) {
                    blocksFiltered.push(block);
                }
            });
        }
        return{
            blocksFiltered,
        };
    },
});
</script>
