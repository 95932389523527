<template>
    <div>
        <svg width="185px"
             height="62px"
             viewBox="0 0 185 62"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             preserveAspectRatio="none">
            <title>mailbg</title>
            <g stroke="none"
               stroke-width="1"
               fill="none"
               fill-rule="evenodd">
                <g id="mailbg"
                   transform="translate(-456.000000, -2989.000000)"
                   fill="#FFFFFF"
                   stroke="#000000"
                   stroke-width="1.6">
                    <path vector-effect="non-scaling-stroke"
                          d="M467.569355,2991.98302 C463.714604,2993.49249 460.636158,2998.51059 458.745286,3002.86231 C456.854414,3007.2115 458.119862,3019.54473 457.175643,3023.16948 C456.22899,3026.79927 459.395045,3037.7972 459.869588,3039.36977 C460.341698,3040.94235 461.76046,3045.65755 468.851838,3047.46992 C475.940783,3049.2823 487.760559,3049.2823 495.324047,3049.2823 C502.887535,3049.2823 532.861629,3048.56038 540.425117,3047.83341 C547.986172,3047.10896 585.881486,3048.92134 590.609883,3048.56038 C595.333413,3048.19689 619.788204,3049.2823 624.514168,3049.2823 C629.242565,3049.2823 631.965712,3049.03997 634.645056,3045.53639 C637.319533,3042.02775 640.47342,3038.76397 639.843129,3029.69705 C639.212838,3020.63518 640.745978,3013.98392 639.485396,3008.78408 C638.222382,3003.58928 639.169034,2996.33474 635.384857,2993.07095 C631.605546,2989.80716 623.883877,2989.9258 619.943952,2990.04444 C616.00646,2990.1656 609.056228,2990.65782 598.170938,2990.53161 C592.289036,2990.46093 566.09912,2989.8526 550.821263,2990.16812 C539.945707,2990.39278 520.039229,2990.89257 516.595749,2990.89257 C502.415426,2990.89257 474.974662,2989.08272 467.569355,2991.98302"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MailBg',
});
</script>
