import { getPageUrls } from '@/project/content/api/contentApi';
import { setupDynamicRoutes } from '@/router';

export default async function config(): Promise<void> {
    return getPageUrls()
        .then(({
            checkoutInformationUrl,
            checkoutDeliveryUrl,
            checkoutPaymentUrl,
            checkoutReceiptUrl,
            giftCardCheckoutInformationUrl,
            giftCardCheckoutPaymentUrl,
            giftCardCheckoutReceiptUrl,
        }) => {
            setupDynamicRoutes({
                checkoutInformationUrl,
                checkoutDeliveryUrl,
                checkoutPaymentUrl,
                checkoutReceiptUrl,
                giftCardCheckoutInformationUrl,
                giftCardCheckoutPaymentUrl,
                giftCardCheckoutReceiptUrl,
            });
        });
}
