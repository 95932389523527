<template>
    <article class="flex relative flex-col w-full h-full"
             role="img"
             :aria-label="altText">
        <div class="absolute inset-0 promoted-tile-styles"
             :style="backgroundImageStyle">
        </div>
        <CallToAction v-if="model.promotedTile?.buttonText"
                      :variant="buttonVariant"
                      v-bind="linkProps"
                      size="small"
                      class="promoted-cta-style">
            <span class="inline-block">
                {{ model.promotedTile?.buttonText }}
            </span>
        </CallToAction>
    </article>
</template>


<script lang="ts">
import { Link, LinkType, ProductTileViewObject } from '@/api/content/models';
import { computed, defineComponent, PropType } from 'vue';
import blockUtil from '../content/util/blockUtil';

export default defineComponent({
    name: 'PromotedProductTile',
    components: {

    },
    props: {
        model: {
            type: Object as PropType<ProductTileViewObject>,
            required: true,
        },
    },
    setup(props) {
        function imageUrl(): string {
            const image = props.model.promotedTile?.image;
            return image ? `${image.url}?width=750&rmode=pad&format=jpeg&quality=100&bgcolor=F4F1DD` : '';
        }

        const backgroundImageStyle = computed(() => ({
            'background-image': `url(${imageUrl()})`,
        }));
        
        const productUrl = `/p-${props.model.product.sku}`;

        const link: Link = {
            name: props.model.product?.displayName ?? '',
            linkType: LinkType.Content,
            target: '',
            url: productUrl,
        };

        return {
            backgroundImageStyle,
            buttonVariant: blockUtil.buttonVariant(props.model.promotedTile?.buttonColor),
            linkProps: blockUtil.linkProps(link),
            altText: blockUtil.altText(props.model.promotedTile?.image),
        };
    },
});
</script>
<style scoped>
.promoted-cta-style {
    padding-left: 15px !important;
    padding-right: 15px !important;
    @apply mx-auto block mt-auto mb-0 w-fit h-[4rem!important];
}

.promoted-tile-styles {
    height: calc(100% - 6.6rem);
    margin-left: -1rem;
    margin-right: -1rem;
    @apply flex flex-col text-center bg-cover bg-center;
}

@screen sm {
    .promoted-tile-styles {
        height: calc(100% + 2rem);
        @apply flex flex-col text-center bg-cover mx-0;
    }
}

@screen lg {
    .promoted-cta-style {
        padding-left: 50px !important;
        padding-right: 50px !important;
        @apply mx-auto mt-auto mb-0 w-fit h-[4.4rem];
    }
}
</style>
