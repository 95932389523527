<template>
    <picture>
        <source :srcset="productImageUrl(format)"
                type="image/webp">
        <img v-on-error
             :src="productImageUrl()"
             :alt="name"
             :class="styles"
             loading="lazy">
    </picture>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { ProductMedia } from '@/api/checkout';
import useTheme from '@/core/theme/useTheme';

export default defineComponent({
    name: 'ImageWrap',
    props: {
        format: {
            type: String,
            default: 'jpeg',
            validator: (value: string) => { return ['jpeg', 'png', 'webp'].indexOf(value) !== -1; },
        },
        image: {
            type: Object as PropType<ProductMedia>,
            required: true,
        },
        width: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        styles: {
            type: String,
            default: '',
        },
        useTheme: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const { themeBackgroundColor } = useTheme();
        const themeBackgroundColorFormatted = computed(()=> {
            return themeBackgroundColor.value?.replace('#', '');
        });

        const quality = props.format === 'webp' ? 87 : 100;

        function productImageUrl(format = 'jpeg') {
            const backgroundColor = props.useTheme ? themeBackgroundColorFormatted.value : 'fff';
            if (!props.image) return '';
            const imageUrlEncoded = encodeURI(props.image.url);
            return `${imageUrlEncoded}&width=${props.width}&rmode=pad&format=${format}&quality=${quality}&bgcolor=${backgroundColor}`;
        }

        return {
            productImageUrl,
        };
    },
});
</script>
