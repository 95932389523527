export const StatusQueryParam = 'status';
export const PaymentIdQueryParam = 'paymentId';
export const PaymentMethodStorageKey = 'paymentMethod';

export enum ReceiptStatusCodes {
    Success= 'success',
    Pending = 'pending',
    Failed = 'failed'
}

export enum ErrorStatusCodes {
    Refused = 'Refused',
    Error = 'Error'
}
