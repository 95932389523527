import { Media, Link, ButtonColor } from '@/api/content/models';

export type BlockContentWrap<T> = {
    content: T
}

function formatHexColor(color: string | null | undefined) {
    if (!color) return undefined;
    return `#${color}`;
}

function linkProps(link: Link | undefined | null): {
    href: string,
    target: string
} | undefined {
    if (!link) return undefined;
    return {
        href: link.url ?? '',
        target: link.target ? link.target : '_self',
    };
}

const variants = new Map<ButtonColor, string>([
    [ButtonColor.Color1, 'SecondaryButton'],
    [ButtonColor.Color2, 'PrimaryButton'],
    [ButtonColor.Color3, 'TertiaryButton'],
]);

function buttonVariant(color: ButtonColor | undefined | null): string {
    if (!color) return '';
    return variants.get(color) ?? '';
}

function altText(media: Media | null | undefined): string | undefined {
    if (!media) return undefined;
    return (media.additionalProperties as any).altText as string;
}

export default {
    formatHexColor,
    linkProps,
    buttonVariant,
    altText,
};
