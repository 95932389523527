<template>
    <Component :is="component"/>
</template>

<script lang="ts">
import bus from '@/core/bus';
import { defineComponent, shallowRef, computed } from 'vue';
import { SHOW_ERROR_PAGE } from '@/project/content/errorPage';

export default defineComponent({
    name: 'PageRenderer',
    props: {
        page: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const errorPage = shallowRef();

        bus.on(SHOW_ERROR_PAGE, _errorPage => {
            errorPage.value = _errorPage;
        });

        const component = computed(() => {
            return errorPage.value ? errorPage.value : props.page;
        });

        return {
            component,
        };
    },
});
</script>
