<template>
    <Field v-slot="{ field }"
           v-model="internalValue"
           :name="name"
           type="checkbox"
           :value="!!value">
        <label :for="fieldId"
               class="cursor-pointer">
            <div class="inline-flex relative items-start">
                <div class="relative">
                    <input :id="fieldId"
                           v-bind="{ ...$attrs, ...field }"
                           type="checkbox"
                           :name="name"
                           :value="!!value">

                    <svg :class="{'text-red-100': hasError}"
                         width="29px"
                         height="31px"
                         viewBox="0 0 29 31"
                         version="1.1"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink"
                         class="absolute inset-0">
                        <g id="Styleguide"
                           stroke="none"
                           stroke-width="1"
                           fill="none"
                           fill-rule="evenodd">
                            <g id="Checkbox"
                               transform="translate(-736.000000, -1667.000000)"
                               fill="#FFFFFF"
                               stroke="currentColor">
                                <path d="M752.07984,1667.50075 C757.845632,1667.51621 763.343306,1667.62858 763.836766,1668.25094 C764.204226,1668.71439 764.006134,1671.8193 763.886213,1675.67432 C763.816653,1677.91038 763.738993,1680.40872 763.72856,1682.86397 C763.718091,1685.32795 763.798704,1687.74657 763.868136,1689.82706 C763.969609,1692.86764 764.119358,1695.18427 763.807647,1695.67041 C763.2699,1696.50909 756.567038,1696.74666 750.144575,1696.76345 L750.144575,1696.76345 L749.304172,1696.76345 L748.46551,1696.75905 C745.062034,1696.73217 741.801945,1696.59602 739.514554,1696.34944 C738.506557,1696.24077 737.692407,1696.11229 737.148169,1695.9597 C736.771915,1695.19275 736.799627,1693.96811 736.802428,1692.3096 L736.802428,1692.3096 L736.80243,1691.49299 C736.799452,1689.61211 736.776361,1687.31124 736.728456,1684.91392 C736.716177,1684.29946 736.703097,1683.67768 736.689646,1683.05327 L736.689646,1683.05327 L736.656898,1681.5533 L736.640412,1680.80449 L736.624072,1680.0589 C736.562,1677.21261 736.50677,1674.47791 736.500578,1672.32766 L736.500578,1672.32766 L736.500745,1671.51718 C736.507393,1669.70483 736.495104,1668.43627 736.641267,1668.22255 C736.93823,1667.78834 741.63643,1667.59508 746.962806,1667.52851 L747.660804,1667.52054 C748.128096,1667.51569 748.598899,1667.51176 749.07073,1667.50867 L749.778938,1667.50467 C750.251177,1667.50242 750.723205,1667.50097 751.19254,1667.50025 Z"/>
                            </g>
                        </g>
                    </svg>
                    <!-- checkmark -->
                    <svg width="23px"
                         height="17px"
                         viewBox="0 0 23 17"
                         version="1.1"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink"
                         class="checkmark">
                        <g id="Styleguide"
                           stroke="none"
                           stroke-width="1"
                           fill="none"
                           fill-rule="evenodd">
                            <g id="Checkmark"
                               transform="translate(-853.000000, -1674.000000)"
                               fill="#E6007E"
                               fill-rule="nonzero">
                                <path d="M859.297268,1691 C859.613115,1691 859.873224,1690.88852 860.077596,1690.66557 L860.133333,1690.60984 C862.29768,1687.57224 864.559848,1684.86896 866.919837,1682.5 C869.279826,1680.13104 871.903997,1677.9294 874.79235,1675.89508 C875.015301,1675.69071 875.126776,1675.4306 875.126776,1675.11475 C875.126776,1674.79891 875.019945,1674.53415 874.806284,1674.32049 C874.592623,1674.10683 874.327869,1674 874.012022,1674 C873.696175,1674 873.436066,1674.11148 873.231694,1674.33443 C870.084239,1676.6241 867.426154,1678.8555 865.257437,1681.02863 C863.088721,1683.20177 861.185604,1685.54086 859.548087,1688.0459 L855.84153,1681.55246 C855.674317,1681.29235 855.442077,1681.12049 855.144809,1681.03689 C854.847541,1680.95328 854.564208,1680.98579 854.294809,1681.13443 C854.02541,1681.28306 853.853552,1681.50601 853.779235,1681.80328 C853.704918,1682.10055 853.742077,1682.38852 853.89071,1682.66721 L858.321858,1690.41475 C858.50765,1690.74918 858.786339,1690.93497 859.157923,1690.97213 C859.195082,1690.99071 859.24153,1691 859.297268,1691 Z"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="mt-10 ml-10 checkbox-text">
                    <span v-if="label"
                          class="block mb-5 font-fauli">{{ label }}</span>
                    <span v-else-if="htmlLabel"
                          class="block mb-5 font-fauli"
                          v-html="htmlLabel"></span>
                    <span v-if="labelHelpText"
                          class="mr-4 text-12 font-light"
                          v-html="labelHelpText"></span>
                    <span v-if="smartLabelHelpText"
                          class="mr-4 text-12 font-light">
                        <SmartLabel :label="smartLabelHelpText"
                                    link-class="lowercase underline hover:no-underline"/>
                    </span>
                    
                    <InputErrorMessage class="mt-5 font-fauli text-10"
                                       :name="name"/>
                </div>
            </div>

        </label>
    </Field>
</template>
<script lang="ts">
import { CustomInputFieldInternalValue } from '@/core/forms/vee-validate.types';
import { Field, useField, useFieldError } from 'vee-validate';
import { computed, defineComponent, ref, Ref, toRef, watch } from 'vue';
import SmartLabel from '../shared/SmartLabel.vue';

export default defineComponent({
    name: 'InputCheckbox',
    components: { Field, SmartLabel },
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        name: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Boolean, Number],
            default: true,
        },
        // eslint-disable-next-line vue/require-default-prop
        modelValue: {
            type: null,
        },
        label: {
            type: String,
            default: '',
        },
        htmlLabel: {
            type: String,
            default: '',
        },
        labelHelpText: {
            type: String,
            default: '',
        },
        smartLabelHelpText: {
            type: String,
            default: '',
        },
        showErrors: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        'update:modelValue': (evt) => !!evt,
    },
    setup(props, { emit }) {
        const internalValue: Ref<CustomInputFieldInternalValue> = ref(false);
        const fieldId = computed(() => {
            return props.id || props.name + Math.random();
        });

        const isModelBound = computed(() => {
            return 'modelValue' in props;
        });

        const { handleChange, meta, checked } = useField(props.name, props.required ? 'is-true' : undefined, {
            // Will make the checkbox set its value to true/false if it was checked or not
            type: 'checkbox',
            initialValue:false,
            uncheckedValue: false,
            checkedValue: true,
        });

        if (isModelBound.value) {
            const modelValue = toRef(props, 'modelValue');
            watch(modelValue, newModelValue => {
                if (newModelValue !== internalValue.value) {
                    internalValue.value = newModelValue;
                    if (meta.touched) {
                        handleChange(!!newModelValue);
                    }
                }
            }, { immediate: true });
            watch(internalValue, newInternalValue => {
                emit('update:modelValue', !!newInternalValue);
            });
        }
        const hasError = useFieldError(props.name);

        return {
            fieldId,
            hasError,
            internalValue,
            checked,
        };
    },
});
</script>

<style scoped>
input[type="checkbox"] {
    display: inline-block;
    vertical-align: middle;
    width: 2.9rem;
    height: 3.1rem
}
.checkmark {
  @apply absolute inset-0 m-auto hidden;
}

input[type="checkbox"]:checked ~ .checkmark {
  @apply block;
}

.checkbox-text {
    line-height: 1.6rem;
}
</style>
