<template>
    <ul class="list-reset">
        <li>
            <RouterLinkConditional v-for="(item, index) in footerLinks"
                                   :key="index"
                                   class="footer-link"
                                   :href="item.url"
                                   :target="item.target">
                {{ item.name }}
            </RouterLinkConditional>
        </li>
    </ul>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import contentApi from '@/project/content/api/contentApi';
import { Link } from '@/api/content/models';

export default defineComponent({
    name: 'FooterLinks',
    setup() {
        const footerLinks = ref<Link[]>([]);
        contentApi.settings.then(settings => {
            if (settings.footerLinks) {
                footerLinks.value = settings.footerLinks;
            }
        });

        return {
            footerLinks,
        };
    },
});
</script>

<style scoped>
.footer-link {
    @apply mb-20 text-center block text-16 leading-normal md:mb-0 md:py-23 md:mr-30 last:mr-0 lg:mr-50 md:inline-block;
}
</style>
