<template>
    <article>
        <ContentBlockList :blocks="model.blocks"/>
    </article>
</template>

<script lang="ts">
import { ContentPage } from '@/api/content/models';
import { defineComponent, PropType } from 'vue';
import ContentBlockList from '@/core/content/ContentBlockList.vue';
import useTheme from '@/core/theme/useTheme';

export default defineComponent({
    name: 'ContentPage',
    components: { ContentBlockList },
    props: {
        model: {
            type: Object as PropType<ContentPage>,
            required: true,
        },
        dialogMode: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        if (!props.dialogMode) {
            const { setThemeBackgroundColor } = useTheme();
            setThemeBackgroundColor(props.model.pageBackgroundColor);
        }
    },

});
</script>
