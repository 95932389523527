import { CheckoutStep } from '@/api/checkout/models/checkout-step';
import { getCurrentRouteMetaData } from '@/router';
import { AxiosRequestConfig } from 'axios';

const CheckoutStepHeaderParamKey = 'X-CheckoutStep';

export const requestHandler = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const checkoutStep: CheckoutStep | undefined = getCurrentRouteMetaData()?.checkoutStep;

    if (checkoutStep) {
        config.headers ||= {};
        config.headers[CheckoutStepHeaderParamKey] = checkoutStep;
    }
    return config;
};
