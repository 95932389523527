<template>
    <div class="wrap">
        <span class="inline-block relative z-1"
              :class="[{'pl-40 pr-15': splashPdp}, {'px-20': !splashPdp}]"
              :style="{ color }">
            <slot/>
        </span>
        <svg v-if="splashPdp"
             width="143px"
             height="46px"
             viewBox="0 0 143 46"
             preserveAspectRatio="none"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             class="absolute inset-0 w-full h-full"
             :style="{ color: backgroundColor }">
            <g id="info-box-bg"
               stroke="none"
               stroke-width="1"
               fill="none"
               fill-rule="evenodd">
                <g id="info-box-bg-2"
                   transform="translate(-659.000000, -436.000000)"
                   fill="currentColor">
                    <path id="splash-pdp-magenta"
                          d="M663.305131,455.804708 C671.547533,452.85083 675.564082,453.369363 679.128758,453.369363 C682.693434,453.369363 689.186918,454.968971 691.372855,453.369363 C693.413808,451.875851 690.628463,439.723727 693.413808,438.608444 C706.14078,433.512429 739.521495,437.59555 743.742899,436.80763 C748.888173,435.847271 766.801614,436.80763 770.122079,436.80763 C773.442545,436.80763 794.056853,435.129431 797.733429,437.506545 C801.410005,439.883659 801.410005,447.398959 800.84752,451.429764 C800.285035,455.46057 799.649195,460.273389 799.649195,467.42924 C799.649195,474.585091 799.285025,474.481027 796.899219,475.967025 C794.513413,477.453022 794.513413,478.360482 788.943737,479.886855 C783.374061,481.413229 772.788567,481.047499 768.769735,481.333466 C762.96084,481.746808 751.164425,481.333466 748.339055,481.333466 C743.55898,481.333466 730.447229,479.886855 727.411814,479.886855 C724.3764,479.886855 708.704086,479.886855 704.526218,479.886855 C700.348349,479.886855 695.054115,480.291987 693.413808,478.360482 C691.773501,476.428978 690.432808,470.805239 690.432808,467.710889 C690.432808,464.616539 682.110017,461.346525 680.385607,460.310038 C678.661198,459.273551 674.579378,458.819772 672.111619,458.485516 C669.64386,458.15126 665.880001,457.632727 663.305131,458.485516 C660.730261,459.338305 655.062729,458.758587 663.305131,455.804708 Z"
                          transform="translate(730.059678, 458.758587) scale(1, -1) translate(-730.059678, -458.758587) "/>
                </g>
            </g>
        </svg>
        <svg v-else
             width="186px"
             height="100%"
             viewBox="0 0 186 58"
             preserveAspectRatio="none"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             class="absolute inset-0 w-full h-full"
             :style="{ color: backgroundColor }">
            <g stroke="none"
               stroke-width="1"
               fill="none"
               fill-rule="evenodd">
                <g id="info-box-bg"
                   transform="translate(-735.000000, -850.000000)"
                   fill="currentColor">
                    <path d="M745.742623,851.940127 C741.82468,853.416947 738.695767,858.326508 736.773897,862.584094 C734.852027,866.839212 736.13822,878.905667 735.178522,882.45201 C734.216351,886.003291 737.434308,896.763329 737.91663,898.301889 C738.396479,899.840448 739.8385,904.453657 747.046131,906.226829 C754.251288,908 766.26483,908 773.95231,908 C781.63979,908 812.105262,907.293695 819.792742,906.582451 C827.477748,905.873676 865.994297,907.646847 870.800209,907.293695 C875.601174,906.938073 900.456863,908 905.260302,908 C910.066213,908 912.834003,907.762919 915.55727,904.335117 C918.275591,900.902376 921.481181,897.709185 920.840557,888.83839 C920.199934,879.972533 921.758207,873.465143 920.47696,868.377771 C919.19324,863.295339 920.155412,856.197714 916.309199,853.004524 C912.467932,849.811334 904.619678,849.927405 900.615164,850.043476 C896.613124,850.162017 889.548953,850.643588 878.485215,850.520109 C872.506889,850.45096 845.88763,849.855787 830.359316,850.164486 C819.305472,850.384281 799.072659,850.873261 795.572728,850.873261 C781.159941,850.873261 753.269329,849.102559 745.742623,851.940127"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'InfoBox',
    props: {
        backgroundColor: {
            type: String,
            default: 'black',
        },
        color: {
            type: String,
            default: 'white',
        },
        splashPdp: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<style scoped>
    .wrap {
        background-size: 100% 100%;
        @apply relative h-full flex items-center min-h-[3.4rem] text-white text-center p-2 box-border font-fauli text-10 -mr-10 md:p-5 md:text-12 md:min-h-49;
    }
    .white-bg {
        margin: 0;
        top: -1.5px;
        bottom: -1.5px;
        height: 106%;
        width: 101.7%;
        margin-left: -1.5px;
        margin-right: -1.5px;
    }
</style>
