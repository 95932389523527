<script lang="ts">
import bus from '@/core/bus';
import recaptcha from './recaptcha-wrapper';
import content from '@/project/content/api/contentApi';
import { getCultureSettings } from '@/project/content/api/contentApi';
import {
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    Ref,
    h, nextTick,
} from 'vue';

export interface RecaptchaComponentInstance extends HTMLElement {
    reset: () => void,
    execute: () => void,
}

export default defineComponent({
    name: 'Recaptcha',
    props: {
        loadRecaptchaScript: {
            type: Boolean,
            default: true,
        },
        recaptchaScriptId: {
            type: String,
            default: '__RECAPTCHA_SCRIPT',
        },
        recaptchaHost: {
            type: String,
            default: 'www.google.com',
        },
        culture: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue', 'verify', 'expired', 'error', 'render'],
    expose: ['reset', 'execute'],
    setup(props, { emit, slots }) {
        const root: Ref<HTMLElement | null> = ref(null);
        const widgetId: Ref = ref(null);
        const siteKey: Ref<string> = ref('');

        const emitVerify = (key: any) => {
            emit('update:modelValue', key);
            nextTick(() => emit('verify', key));
        };
        const emitExpired = () => {
            bus.emit('expired'); 
        };
        const emitError = () => {
            bus.emit('error');
        };
        const reset = () => {
            recaptcha.reset(widgetId.value);
        };
        const execute = () => {
            recaptcha.execute(widgetId.value);
        };

        onBeforeMount(async() => {
            if (props.loadRecaptchaScript) {
                if (!document.getElementById(props.recaptchaScriptId)) {
                    
                    const recaptchaCulture = (await getCultureSettings()).culture.substring(0,2);

                    // Note: vueRecaptchaApiLoaded load callback name is per the latest documentation
                    const script = document.createElement('script');
                    script.id = props.recaptchaScriptId;
                    script.src = `https://${props.recaptchaHost}/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=${recaptchaCulture}`;
                    script.async = true;
                    script.defer = true;

                    document.head.appendChild(script);
                }
            }
        });
        onMounted(async() => {
            await getReCaptchaKey();
            recaptcha.checkRecaptchaLoad();
            const opts = {
                ...props,
                sitekey: siteKey.value,
                callback: emitVerify,
                'expired-callback': emitExpired,
                'error-callback': emitError,
            };
            if(root.value !== null){
                const container = slots.default ? root.value.children[0] : root.value;
                recaptcha.render(container, opts, (id: any) => {
                    widgetId.value = id;
                    emit('render', id);
                });
            }
        });
        return function renderRecaptcha(this: RecaptchaComponentInstance) {
            if (!('setErrors' in this)) {
                this.reset = reset;
                this.execute = execute;
            }
            return h('div', { ref: root }, slots.default ? slots.default() : slots.default);
        };

        async function getReCaptchaKey(){
            content.settings.then(siteSettings => {
                if (siteSettings.recaptchaApiKey) {
                    siteKey.value = siteSettings.recaptchaApiKey;
                }
            });
        }
    },
});
</script>
