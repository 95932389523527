<template>
    <Popper v-bind="$attrs"
            :show="showPopper"
            :hover="hover">
        <slot/>
        <template #content="props">
            <slot name="content"
                  v-bind="props"/>
        </template>
    </Popper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Popper from 'vue3-popper';

export default defineComponent({
    name: 'Popover',
    components: {
        Popper,
    },
    props: {        
        showPopper: {
            default: false,
            type: Boolean,
        },
        hover: {
            default: false,
            type: Boolean,
        },
    },
});
</script>