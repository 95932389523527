import { CatalogProductTileViewObject } from '@/api/content/models/catalog-product-tile-view-object';
import { ProductType } from '@/api/content/models/product-type';


export type ProductTypeUrls = '/p-' | '/g-';

class ProductService {

    public getProductRedirectUrlByProduct(product : CatalogProductTileViewObject) {
        return this.productTypeUrls[product.productType] + product.sku;
    }

    public getProductTypeUrlPrefixFromProductType(type: ProductType) {
        return this.productTypeUrls[type];
    }

    private readonly productTypeUrls: {[key in ProductType]: ProductTypeUrls } = {
        Product: '/p-',
        GiftCard: '/g-',
    };
}

const productService = new ProductService();

export {
    productService,
};

