import { LineItemViewObject } from '@/api/checkout';
import { DataLayerEntry } from './tracking.config';
import { clientBasket } from '../basket/basket.service';
import { PaymentMethodStorageKey } from '@/project/pages/checkout/payment-providers/constants';
import { localStorageService } from '@/core/storage/storage.service';
import { CatalogProductTileViewObject, ProductDetailsModel, ProductTileViewObject } from '@/api/content';
import { StockStatus } from '@/api/stock';
import { getPageData } from '@/router';
import { useStockStatus } from '../stock/use-stock-status.composable';
import { find } from 'lodash-es';
import { ref } from 'vue';

enum GTMEventType {
    ProductDetail = 'productDetail',
    AddToBasket = 'addToBasket',
    RemoveFromBasket = 'removeFromBasket',
    Checkout = 'checkout',
    CheckoutOption = 'checkout_option',
    TransactionComplete = 'transactionComplete',
    ErrorPage = 'errorPage',
    Variables = 'variables',
    ProductClick = 'productClick',
    ProductImpressions = 'productImpressions',
    OverlayPage = 'overlayPage',
}

export interface TrackingObject {
    productTileMode: boolean,
    productIndex: number,
    contentBlockIndex: number,
}

export function trackPage() {
    const trackingObject: DataLayerEntry =
        {
            event: GTMEventType.Variables,
            pageType: getPageData().alias,
        };

    pushToDataLayer(trackingObject);
}

export function trackOverlay(title: string) {
    const trackingObject: DataLayerEntry =
        {
            event: GTMEventType.OverlayPage,
            eventCategory: title,
        };

    pushToDataLayer(trackingObject);
}

export function trackProductImpression(products: ProductTileViewObject[], contentBlockIndex: number, stockResults) {
    const trackingObject: DataLayerEntry =
    {
        event: GTMEventType.ProductImpressions,
        ecommerce: {
            currencyCode: clientBasket.value?.currency?.toUpperCase(),
            impression: {
                products: setProductsData(products, contentBlockIndex, stockResults),
            },
        },
    };

    pushToDataLayer(trackingObject);
}

export function trackGoToPdp(model: CatalogProductTileViewObject, index: number, contentBlockIndex: number, stockResult) {
    const actionField: any = {
        actionField: {
            list: `${getPageData().meta.name} | block no. ${contentBlockIndex}`,
        },
    };

    const trackingObject: DataLayerEntry =
        {
            event: GTMEventType.ProductClick,
            ecommerce: {
                currencyCode: clientBasket.value?.currency?.toUpperCase(),
                click: {
                    ...actionField,
                    products: [{
                        ...setProductData(model, null, index),
                        dimension3: setStockStatus(stockResult),
                    },
                    ],
                },
            },
        };

    pushToDataLayer(trackingObject);
}

export function trackAddToBasket(product, quantity: number, productTileMode: boolean, increasedQuantity: boolean, index?, contentBlockIndex?) {
    const actionField: any = productTileMode ? {
        actionField: {
            list: `${getPageData().meta.name} | block no. ${contentBlockIndex}`,
        },
    } : {};

    const propName = increasedQuantity ? 'add' : 'remove';
    const trackingObject: DataLayerEntry =
        {
            event: increasedQuantity ? GTMEventType.AddToBasket : GTMEventType.RemoveFromBasket,
            ecommerce: {
                currencyCode: clientBasket.value?.currency?.toUpperCase(),
                [propName]: {
                    ...actionField,
                    products: [
                        setProductData(product, quantity, index),
                    ],
                },
            },
        };

    pushToDataLayer(trackingObject);
}

export function trackCheckoutStep(step: number, option?: string) {
    let actionField: any = {
        step: step,
    };

    if (option) {
        actionField = {
            ...actionField,
            option: option,
        };
    }

    pushToDataLayer(trackingObject(GTMEventType.Checkout, actionField));
}

export function trackOption(step: number, option: string) {
    const actionField = {
        step: step,
        option: option,
    };

    pushToDataLayer(trackingObject(GTMEventType.CheckoutOption, actionField));
}

export function trackProductDetailsPage(model, stockResult) {
    const products = setProductData(model, null, null);

    const trackingObject: DataLayerEntry =
        {
            event: GTMEventType.ProductDetail,
            ecommerce: {
                currencyCode: clientBasket.value?.currency?.toUpperCase(),
                detail: {
                    ...products,
                    dimension3: setStockStatus(stockResult),
                },
            },
        };

    pushToDataLayer(trackingObject);
}

export function trackReceiptPage(paymentId: string, total: number, vat: number, deliveryPrice: number) {
    const products = setLineItems();

    const trackingObject: DataLayerEntry = {
        event: GTMEventType.TransactionComplete,
        ecommerce: {
            currencyCode: clientBasket.value?.currency?.toUpperCase(),
            purchase: {
                actionField: {
                    id: paymentId,
                    revenue: total,
                    tax: vat,
                    shipping: deliveryPrice,
                },
                products: products,
            },
        },
    };

    trackPaymentMethod();
    pushToDataLayer(trackingObject);
}

export function trackError(errorType) {
    const trackingObject: DataLayerEntry = {
        event: GTMEventType.ErrorPage,
        eventCategory: errorType,
    };

    pushToDataLayer(trackingObject);
}

export function trackPaymentMethod() {
    const paymentMethodFromStorage = localStorageService.getItem(PaymentMethodStorageKey);
    localStorageService.removeItem(PaymentMethodStorageKey);

    if (paymentMethodFromStorage) {
        trackOption(3, paymentMethodFromStorage);
    }
}

function setProductData(model: CatalogProductTileViewObject | ProductDetailsModel | LineItemViewObject, quantity: number | null = null, index: number | null = null) {
    const productData: any = {
        name: model.displayName,
        id: model.sku,
        price: model.unitPrice.value,
        brand: model.brand,
        dimension4: certificationFileNames(model),
        dimension5: model.splashText,
        position: index != null ? index+1 : '',
        quantity: quantity || (model as LineItemViewObject).quantity || '',
    };

    return productData;
}

function setProductsData(products: ProductTileViewObject[], index, stockResults) {
    const productsData = products.map((item) => {
        const stockResult = ref({
            dataReady: stockResults.dataReady,
            0: find(stockResults, i => i.sku === item.product.sku),
        });
        return {
            ...setProductData(item.product, null, index),
            dimension3: setStockStatus(stockResult.value),
            list: `${getPageData().meta.name} | block no. ${index}`,
            position: products.indexOf(item)+1,
        };
    });

    return productsData;
}

function setLineItems() {
    const lineItems: LineItemViewObject[] = clientBasket.value?.lineItems ?? [];

    const products = lineItems.map((lineItem) => {
        return {
            ...setProductData(lineItem),
            dimension3: lineItem.stockStatus,
        };
    });

    return products;
}

function setStockStatus(stockResult?) {
    const { shouldShowStockStatus, allReserved, fewAvailable, soldOut } = useStockStatus(stockResult);
    if (allReserved.value) {
        return StockStatus.AllReserved;
    }
    else if (soldOut.value) {
        return StockStatus.SoldOut;
    }
    else if (fewAvailable.value) {
        return StockStatus.FewAvailable;
    }
    else if (!shouldShowStockStatus.value) {
        return StockStatus.Available;
    }
}

function certificationFileNames(item) {
    const fileNames = item.certifications.map((cert) => getFileName(cert.url));

    function getFileName(url) {
        const n = url.split('/');
        return n[n.length - 1];
    }
    return fileNames.join('|');
}

function trackingObject(checkoutKey, actionField) {
    const products = setLineItems();
    const trackingObject: DataLayerEntry = {
        event: checkoutKey,
        ecommerce: {
            [checkoutKey]: {
                actionField: actionField,
                products: products,
            },
        },
    };

    return trackingObject;
}

function pushToDataLayer(trackingObject: DataLayerEntry): void {
    // console.log(trackingObject);
    window.dataLayer && window.dataLayer.push(trackingObject);
}
