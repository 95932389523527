<template>
    <div class="aspect-square relative mx-auto mt-14 mb-23 w-[14.5rem] max-w-full h-[14.5rem] md:w-[26rem] md:h-[26rem]">
        <RouterLink :to="productUrl"
                    :title="product.displayName"
                    @click="trackOnClick()">
            <ImageWrap v-if="product.image"
                       format="webp"
                       :image="product.image"
                       :width="300"
                       :name="product.displayName"
                       styles="object-contain mx-auto w-full h-full"/>
        </RouterLink>
        <div class="absolute right-0 bottom-0">
            <StockStatusInfo v-if="showStockStatus"
                             :key="product.sku+'stock-status'"
                             :stock-status="stockStatus"
                             :sku="product.sku"/>
            <ProductSplash v-else-if="product.splashText"
                           :key="product.sku+'splash'"
                           :product="product"
                           :sku="product?.sku"/>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { CatalogProductTileViewObject } from '@/api/content/models/catalog-product-tile-view-object';
import { ProductType } from '@/api/content/models/product-type';
import ImageWrap from '@/project/shared/ImageWrap.vue';
import StockStatusInfo from '@/project/stock/StockStatusInfo.vue';
import { useStockStatus } from '@/project/stock/use-stock-status.composable';
import { trackGoToPdp } from '@/project/tracking/tracking.service';
import { find } from 'lodash';
import { computed } from 'vue';
import { RouterLink } from 'vue-router';
import { productService } from './product.service';
import ProductSplash from './ProductSplash.vue';

type Props = {
    product: CatalogProductTileViewObject;
    stockResults: Record<string, {
        sku: string;
        dataReady: boolean;
    }>;
    index: number;
    contentBlockIndex: number;
}

const props = defineProps<Props>();

const stockResult = computed(()=> {
    return {
        dataReady: props.stockResults.dataReady,
        0: find(props.stockResults, i => i.sku === props.product.sku),
    };
});

const { shouldShowStockStatus, allReserved, fewAvailable } = useStockStatus(stockResult);

const stockStatus = computed(()=> {
    return {
        shouldShowStockStatus: shouldShowStockStatus.value,
        allReserved: allReserved.value,
        fewAvailable:fewAvailable.value,
    };
});

const showStockStatus = computed(() => {
    return props.product.productType === ProductType.Product ? shouldShowStockStatus.value : false;
});

const productUrl = productService.getProductRedirectUrlByProduct(props.product);

function trackOnClick() {
    trackGoToPdp(props.product, props.index, props.contentBlockIndex, stockResult);
}


</script>