// Handrolled - no openApi for content
import { HttpNoServerResponse } from '@/core/http/HttpNoServerResponse';
import { HttpServerError } from '@/core/http/HttpServerError';
import { SiteSettingsViewModel, PageData } from '@/api/content/models';
import { cmsHost, siteSettings, firstPage } from '@/core/infrastructure/environment';
import axios from 'axios';
import applicationVersionInterceptor from '@/core/http/interceptors/applicationVersion.interceptor';

const axiosInstance = axios.create({
    baseURL: cmsHost,
    params: {
        xhr: 1,
    },
});
axiosInstance.interceptors.response.use(applicationVersionInterceptor);

const originalPath = location.pathname;

let hasInitialPageData = !!firstPage;
async function getPage(path: string): Promise<PageData> {
    // Take from appData.firstPage if present (production), first time on path
    const useInitialPageData = hasInitialPageData && path === originalPath;
    if (useInitialPageData) {
        hasInitialPageData = false;
        return Promise.resolve(firstPage);
    }

    try {
        const response = await axiosInstance.get(path);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                throw new HttpServerError(error.response);
            } else {
                throw new HttpNoServerResponse();
            }
        } else {
            throw error;
        }
    }
}

async function getSiteSettings(): Promise<SiteSettingsViewModel> {
    // Take from appData (in production)
    if (siteSettings) return Promise.resolve(siteSettings);

    const settingsPage = await getPage('/settings');
    return settingsPage.content as SiteSettingsViewModel;
}
const theSettings = getSiteSettings();

export async function getPageUrls(): Promise<{
    checkoutInformationUrl: string,
    checkoutDeliveryUrl: string,
    checkoutPaymentUrl: string,
    checkoutReceiptUrl: string,
    termsAndConditionsUrl: string,
    privacyPolicyUrl: string,
    newsletterTermsPage: string,
    giftCardCheckoutInformationUrl: string,
    giftCardCheckoutPaymentUrl: string,
    giftCardCheckoutReceiptUrl: string,
    giftCardTermsAndConditionsUrl: string,
    giftCardPrivacyPolicyUrl: string,
    giftCardNewsletterTermsPage: string,
}> {
    const mySettings = await theSettings;
    return {
        checkoutInformationUrl: mySettings.checkoutInformationPage?.url ?? '',
        checkoutDeliveryUrl: mySettings.checkoutDeliveryPage?.url ?? '',
        checkoutPaymentUrl: mySettings.checkoutPaymentPage?.url ?? '',
        checkoutReceiptUrl: mySettings.checkoutReceiptPage?.url ?? '',
        termsAndConditionsUrl: mySettings.termsAndConditionsPage?.url ?? '',
        privacyPolicyUrl: mySettings.privacyPolicyPage?.url ?? '',
        newsletterTermsPage: mySettings.newsletterTermsPage?.url ?? '',
        giftCardCheckoutInformationUrl: mySettings.giftCardCheckoutInformationPage?.url ?? '',
        giftCardCheckoutPaymentUrl: mySettings.giftCardCheckoutPaymentPage?.url ?? '',
        giftCardCheckoutReceiptUrl: mySettings.giftCardCheckoutReceiptPage?.url ?? '',
        giftCardTermsAndConditionsUrl: mySettings.giftCardTermsAndConditionsPage?.url ?? '',
        giftCardPrivacyPolicyUrl: mySettings.giftCardPrivacyPolicyPage?.url ?? '',
        giftCardNewsletterTermsPage: mySettings.giftCardNewsletterTermsPage?.url ?? '',
    };
}

export async function getCultureSettings(): Promise<{
    cultureNameForCookieBot: string,
    culture: string,
    commerceEnabled: boolean,
    showFreightText: boolean,
    country: string,
    pricePostfix: string
}> {
    const cultureSettings = await theSettings;
    return {
        cultureNameForCookieBot: cultureSettings.cultureNameForCookieBot || '',
        culture: cultureSettings.culture || '',
        commerceEnabled: cultureSettings.commerceEnabled || false,
        showFreightText: cultureSettings.showFreightText || false,
        country: cultureSettings.country || '',
        pricePostfix: cultureSettings.pricePostfix || '',
    };
}

export default {
    getPage,
    settings: theSettings,
};
