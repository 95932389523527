<template>
    <InfoBox :key="sku"
             :v-bind="stockStatus">
        <span v-if="stockStatus.soldOut">{{ $translate('Product.AllSoldOut') }}</span>
        <span v-else-if="stockStatus.allReserved">{{ $translate('Product.AllReserved') }}</span>
        <span v-else-if="stockStatus.fewAvailable"
              v-html="$translate('Product.FewLeft')"></span>
    </InfoBox>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InfoBox from '@/project/shared/InfoBox.vue';

export default defineComponent({
    name: 'StockStatusInfo',
    components: {
        InfoBox,
    },
    props: {
        sku: {
            type: String,
            required: true,
        },
        stockStatus: {
            type: Object,
            required: true,
        },
    },
});
</script>
