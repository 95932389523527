<template>
    <article v-if="image"
             class="flex"
             :style="[backgroundColor]">
        <div class="relative my-auto w-full h-0"
             :style="[aspectRatioStyle]">
            <div class="absolute inset-0">
                <component :is="linkProps ? 'RouterLinkConditional' : 'div'"
                           v-bind="linkProps">
                    <ResponsiveImage :image-url="image.url"
                                     :aspect-ratio="aspectRatio"
                                     :focus="image.focalPoint"
                                     :alt="altText"
                                     :width-on-screen="{xs: 100, sm: 100, md: 50, lg:50, xl:50}"
                                     :bg-color="model.backgroundColor || undefined"
                                     :quality="100"/>
                </component>
            </div>
        </div>
    </article>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';
import blockUtil from './util/blockUtil';
import { ImageBlock } from '@/api/content/models/image-block';

export default defineComponent({
    name: 'ImageBlock',
    components: { ResponsiveImage, RouterLinkConditional },
    props: {
        model: {
            required: true,
            type: Object as PropType<ImageBlock>,
        },
    },
    setup(props) {
        const image = computed(() => {
            return props.model.image;
        });

        const aspectRatio = !image.value || !image.value.width || !image.value.height ? 1 : image.value.width / image.value.height;

        const aspectRatioStyle = {
            paddingTop: `${100 / aspectRatio}%`,
        };

        const backgroundColor =  {
            backgroundColor: blockUtil.formatHexColor(props.model.backgroundColor),
        };

        return {
            image,
            aspectRatio,
            aspectRatioStyle,
            linkProps: blockUtil.linkProps(props.model.link),
            altText: blockUtil.altText(image.value),
            backgroundColor,
        };
    },
});
</script>
