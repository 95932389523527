<template>
    <InputTextarea :placeholder="label" 
                   :label="label" 
                   :name="name" 
                   :required="!!model.required"/>
</template>

<script lang="ts" setup>
import { TextAreaBlock } from '@/api/content/models';
import { computed } from 'vue';
import InputTextarea from '../forms/InputTextarea.vue';

type Props = {
    model: TextAreaBlock
}

const props = defineProps<Props>();

const label = computed(() => props.model.textAreaHeader ?? '');

const name = computed(() => handleDots(props.model.textAreaHeader) ?? ''); 

function handleDots(input) {
    return input.replace(/\./g, '[dot]');
}

</script>