import { DictionaryApiFactory } from './../../api/content/apis/dictionary-api';
import dictionary from '@/core/dictionary/dictionary';
import { cmsHost, dictionaryKey } from '@/core/infrastructure/environment';
import { getCultureSettings } from '@/project/content/api/contentApi';

const culturePromise = getCultureSettings().then(settings => settings.culture);

const dictionaryApi = DictionaryApiFactory({
    basePath: cmsHost,
    isJsonMime: () => true,
});

export default async function loadDictionary(): Promise<void> {
    const culture = await culturePromise;
    return dictionaryApi.dictionaryGet(culture, {
        params: {
            dictionaryKey,
        },
    })
        .then(labelsResponse => {
            dictionary.init(labelsResponse.data.dictionaryValues ?? {});
        });
}
