<template>
    <div class="inline-block items-center mx-auto w-full">
        <AddToBasket v-if="product.productType === ProductType.Product"
                     :key="product.sku"
                     :model="product"
                     :index="index"
                     :content-block-index="contentBlockIndex"
                     class="mx-auto w-full"
                     :disabled="allReserved"
                     product-tile-mode/>
        <CallToAction v-if="product.productType === ProductType.GiftCard"
                      :href="redirectUrl"
                      variant="SecondaryButton"
                      class="mx-auto h-44 text-16 md:px-20 md:text-18 -py-10">
            {{ $translate("GiftCard.GoToPdpButtonText") }}
        </CallToAction>
    </div>
</template>

<script lang="ts" setup>
import { CatalogProductTileViewObject } from '@/api/content/models/catalog-product-tile-view-object';
import { ProductType } from '@/api/content/models/product-type';
import AddToBasket from '@/project/basket/add-to-basket/AddToBasket.vue';
import CallToAction from '@/project/navigation/call-to-action/CallToAction.vue';
import { productService } from './product.service';

type Props = {
    product: CatalogProductTileViewObject;
    stockResults: Record<string, {
        sku: string;
        dataReady: boolean;
    }>;
    index: number;
    contentBlockIndex: number;
    allReserved: boolean;
}

const props = defineProps<Props>();

const redirectUrl = productService.getProductRedirectUrlByProduct(props.product);




</script>