<template>
    <BaseDialog v-bind="dialogBindings">
        <component :is="pageComponent"
                   :alias="page.alias"
                   :model="page.content"
                   :dialog-mode="true"/>
    </BaseDialog>
</template>

<script lang="ts">
import { defineComponent, PropType, provide } from 'vue';
import BaseDialog from './BaseDialog.vue';
import ContentBlock from '@/core/content/ContentBlock.vue';
import { ContentElement } from '@/api/content/models';
import { pageResolver } from '@/core/content/componentResolver';
import { DialogMode } from './constants';

export default defineComponent({
    name: 'ContentPageDialog',
    components: { BaseDialog, ContentBlock },
    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        page: {
            required: true,
            type: Object as PropType<ContentElement>,
        },
    },
    setup(props) {
        provide(DialogMode, true);
        const dialogBindings: Record<string, string> = {};
        if (props.title) {
            dialogBindings.title = props.title;
        }
        if (props.description) {
            dialogBindings.description = props.description;
        }

        const pageComponent = pageResolver.resolve(props.page.alias);

        return {
            dialogBindings,
            pageComponent,
        };
    },
});
</script>

<style scoped>
</style>
