import { ImageNotFound } from './on-error.directive';

function errorHandler(el:HTMLElement, classNamesArray:string[] = []): void {
    el.setAttribute('src', ImageNotFound);
    el.removeAttribute('srcset');

    Array.from(el.parentNode?.children ?? [])
        .filter(c => c.tagName.toLowerCase() === 'source')
        .forEach(c => c.remove());

    classNamesArray.forEach((className: string) => {
        el.classList.add(className);
    });
}

export default errorHandler;
