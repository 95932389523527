<template>
    <article v-if="image">
        <div class="relative h-0"
             :style="[aspectRatioStyle]">
            <div class="absolute inset-0">
                <component :is="linkProps ? 'RouterLinkConditional' : 'div'"
                           v-bind="linkProps">
                    <ResponsiveImage :image-url="image.url"
                                     :aspect-ratio="aspectRatio"
                                     :focus="image.focalPoint"
                                     :alt="altText"
                                     :bg-color="themeBackgroundColorFormatted"
                                     :quality="100"/>
                </component>
            </div>
        </div>
    </article>
</template>

<script lang="ts">
import { HeroBlock } from '@/api/content/models';
import { computed, defineComponent, PropType } from 'vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';
import blockUtil from './util/blockUtil';
import { useResponsive } from '../responsive/responsive.composable';
import useTheme from '@/core/theme/useTheme';

export default defineComponent({
    name: 'HeroBlock',
    components: { ResponsiveImage, RouterLinkConditional },
    props: {
        model: {
            required: true,
            type: Object as PropType<HeroBlock>,
        },
    },
    setup(props) {
        const { isMobileView } = useResponsive();

        const image = computed(() => {
            return isMobileView.value && props.model.imageTall ? props.model.imageTall : props.model.imageWide;
        });

        const { themeBackgroundColor } = useTheme();
        const themeBackgroundColorFormatted = computed(()=> {
            return themeBackgroundColor.value?.replace('#', '');
        });

        const aspectRatio = computed(() => {
            if (!image.value || !image.value.width || !image.value.height) return 1;
            return image.value.width / image.value.height;
        });

        const aspectRatioStyle = computed(() => {
            return {
                paddingTop: `${100 / aspectRatio.value}%`,
            };
        });

        return {
            image,
            themeBackgroundColorFormatted,
            aspectRatio,
            aspectRatioStyle,
            linkProps: blockUtil.linkProps(props.model.link),
            altText: blockUtil.altText(image.value),
        };
    },
});
</script>
