<template>
    <article>
        <ContentBlockList v-if="blocksAbove"
                          :blocks="blocksAbove"/>

        <div v-if="model.giftCard && model.giftCard.avalibilityState == ProductAvailabilityState.Default"
             class="px-20 pt-60 pb-50 w-full md:grid md:grid-cols-12 md:gap-x-20 lg:px-0">
            <div class="w-full md:flex md:col-span-12 md:col-start-1 lg:col-span-10 lg:col-start-2 xl:col-span-9 xl:col-start-3">
                <div class="my-auto md:mx-auto lg:mr-auto">
                    <article class="md:grid md:grid-cols-2 md:gap-40 lg:gap-50 xl:gap-60">
                        <div class="image-wrap">
                            <ImageWrap v-if="model.giftCard.packshots[0]"
                                       format="webp"
                                       :image="model.giftCard.packshots[0]"
                                       :width="800"
                                       :name="model.giftCard.fullname"
                                       styles="mx-auto max-w-full max-h-500"/>
                        </div>
                        <div class="mx-auto w-full max-w-400 md:my-auto lg:max-w-none">
                            <h1 class="mb-5 text-18 leading-24 md:text-24 md:leading-32">
                                {{ model.giftCard.fullname }}
                            </h1>
                            <div class="mb-20 md:text-16 product-rich-text test"
                                 v-html="model.giftCard.description"></div>
                            <div v-if="model.giftCard.certifications"
                                 class="mb-10">
                                <img v-for="(certification, index) in model.giftCard.certifications"
                                     :key="`certification${index}`"
                                     :src="certification.url"
                                     width="32"
                                     class="mr-10 w-32">
                            </div>
                            <div v-if="model.giftCard.brand || model.giftCard.fullname"
                                 class="mb-3 leading-none">
                                <div class="font-bold">
                                    {{ model.giftCard.brand }}
                                </div>
                                <div class="font-bold">
                                    {{ model.giftCard.name }}
                                </div>
                            </div>
                            <div class="flex">
                                <div class="mt-2 mr-20 mb-15 -ml-2">
                                    <Price :amount="model.giftCard.price"
                                           show-formatted-amount
                                           class="font-numbers text-52 leading-none"/>
                                </div>
                                <ProductSplash v-if="model.giftCard.splash?.splashText"
                                               :product="model.giftCard"
                                               splash-pdp/>
                            </div>
                            <div class="flex relative z-1 flex-wrap items-center mb-10">
                                <CallToAction v-if="checkoutUrl"
                                              variant="SecondaryButton"
                                              @click.prevent="onClickGiftCard">
                                    {{ $translate("GiftCard.GoToCheckoutButtonText") }}
                                </CallToAction>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
        <ContentBlockList v-if="blocksBelow"
                          :blocks="blocksBelow"/>
    </article>
</template>

<script lang="ts" setup>
import { GiftCardDetailsPage, ProductAvailabilityState, ProductEnrichment } from '@/api/content/models';
import ContentBlockList from '@/core/content/ContentBlockList.vue';
import useTheme from '@/core/theme/useTheme';
import { createGiftCardBasket } from '@/project/basket/basket.service';
import { pingnav, setGiftcardInformation } from '@/project/basket/giftcard.service';
import settings, { getCultureSettings } from '@/project/content/api/contentApi';
import CallToAction from '@/project/navigation/call-to-action/CallToAction.vue';
import ProductSplash from '@/project/product/ProductSplash.vue';
import ImageWrap from '@/project/shared/ImageWrap.vue';
import router from '@/router';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import Price from '../../basket/Price.vue';
import { getPDPBackgroundColor } from '../product-details/product-details-page-color';

type Props = {
    model: GiftCardDetailsPage;
}

const props = defineProps<Props>();

const showFreightText = ref(false);
const culture = ref('');
const checkoutUrl = ref<string | null>(null);

const blocksAbove = props.model.giftCard.avalibilityState === ProductAvailabilityState.Default ? (props.model.enrichment?.content as (ProductEnrichment | null))?.blocksAbove : undefined;
const blocksBelow = props.model.giftCard.avalibilityState === ProductAvailabilityState.Default ? (props.model.enrichment?.content as (ProductEnrichment | null))?.blocksBelow : undefined;

const { setThemeBackgroundColor } = useTheme();

setThemeBackgroundColor(getPDPBackgroundColor());

const headObj = {
    title: props.model.giftCard.fullname || '',
};

useHead(headObj);

getCultureSettings().then(settings => {
    showFreightText.value = settings.showFreightText;
    culture.value = settings.culture;
});

onMounted(async() => {
    setGiftcardInformation(props.model.giftCard);
});

const onClickGiftCard = async()=> {
    const navResponse = await pingnav(culture.value);
    
    if (checkoutUrl.value && navResponse.status === 200) {
        await createGiftCardBasket(props.model.giftCard);
        router.replace(checkoutUrl.value);   
    }
};

settings.settings.then((settings) => {
    checkoutUrl.value = settings.giftCardCheckoutInformationPage.url + `?${props.model.giftCard.sku}`;
});

</script>
<style scoped>
.image-wrap {
    @apply mx-auto mt-5 mb-30 text-center max-w-400;
}

@screen md {
    .image-wrap {
        @apply max-w-none w-full mb-0;
    }
}
</style>

<style>
.product-rich-text a {
    @apply underline;
}

.product-rich-text p {
    @apply my-0;
}

strong {
    font-weight: bold;
}
</style>
