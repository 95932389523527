<template>
    <div class="wrapper"
         :class="{'-checkout': isCheckoutMode,
                  '-scrolled': scrolledMode }">
        <nav :class="{ 'fixed top-0 left-0 w-screen' : !isCheckoutMode }"
             :style="scrollbarPadding">
            <div class="navbar o-container"
                 :class="isCheckoutMode ? 'bg-transparent' : 'bg-white/[.95]'">
                <div class="mx-auto md:ml-0"
                     :class="logoStylingMobile">
                    <RouterLink to="/">
                        <div class="flex flex-col items-center">
                            <img :src="logoUrlNoTagline"
                                 alt="logo"
                                 class="logo">
                            <span class="mt-5 font-fauli text-[5px] font-semibold xs:hidden md:text-[7.5px] lg:block logo-tagline">{{ $translate('General.Logo.Tagline') }}</span>
                        </div>
                    </RouterLink>
                </div>
                <template v-if="!isCheckoutMode">
                    <div class="order-first mr-auto w-[4.2] h-[4.2] md:hidden">
                        <MobileMenu/>
                    </div>
                    <div class="hidden md:block md:ml-auto">
                        <MenuItems/>
                    </div>
                    <div v-if="commerceEnabled"
                         class="ml-auto md:mr-10 md:ml-40">
                        <MiniBasket/>
                    </div>
                </template>
                <template v-else-if="!isReceiptPage">
                    <ul class="usps">
                        <Usps/>
                    </ul>
                </template>
            </div>
        </nav>
        <div v-if="!isCheckoutMode">
            <div class="h-80 bg-white md:h-[10.3rem]"/>
            <ul class="usps">
                <Usps/>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import logoUrlNoTagline from '@/assets/images/logo-notagline.svg';
import { useCheckoutNavigationStates } from '@/project/navigation/checkout-navigation-states.composable';
import Usps from '@/project/content/Usps.vue';
import MiniBasket from '@/project/basket/MiniBasket.vue';
import MenuItems from '@/project/navigation/main-menu/MenuItems.vue';
import MobileMenu from '@/project/navigation/main-menu/MobileMenu.vue';
import { useScroll } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { PageNames } from '@/router';
import { getCultureSettings } from '@/project/content/api/contentApi';

const commerceEnabledPromise = getCultureSettings().then(settings => settings.commerceEnabled);

export default defineComponent({
    name: 'MainNavigation',
    components: {
        Usps,
        MiniBasket,
        MenuItems,
        MobileMenu,
    },
    setup() {
        const { isCheckoutMode } = useCheckoutNavigationStates();
        const route = useRoute();
        const commerceEnabled = ref(true);
        onMounted(async() => {
            commerceEnabled.value = await commerceEnabledPromise;
        });
        const isReceiptPage = computed(() => route.name === PageNames.CheckoutReceipt);
        const { arrivedState } = useScroll(window, {
            offset: { top: 42 },
            throttle: 100,
        });

        const scrolledMode = computed(() => !arrivedState.top && !isCheckoutMode.value);
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        const scrollbarPadding = {
            paddingRight: scrollbarWidth + 'px',
        };

        const logoStylingMobile = computed(()=> {
            return commerceEnabled.value ? 'mx-auto': 'ml-[-2.2rem]';
        });

        return {
            logoUrlNoTagline,
            isCheckoutMode,
            scrolledMode,
            isReceiptPage,
            scrollbarPadding,
            commerceEnabled,
            logoStylingMobile,
        };
    },
});
</script>

<style scoped>

    .wrapper {
        @apply z-3;
    }

    .navbar {
        @apply border-b border-b-grey-100 h-80 left-0 w-full my-0 m-auto flex items-center px-20 transition-all duration-300 ease-in-out;

        .-checkout & {
            @apply border-none;
        }

        .-scrolled & {
            @apply h-60;
        }
    }

    .logo {
        width: 9.75rem;
        transition: all 300ms ease-in-out;
    }

    .logo-tagline {
        transition: all 300ms ease-in-out;

        .-scrolled & {
            opacity: 0;
            font-size: 0;
            margin-top: 0;
        }
    }

    .usps {
        @apply items-center justify-center hidden md:flex h-60 bg-white;

        .-checkout & {
            @apply bg-transparent;
        }
    }

    @screen lg {
        .navbar {
            height: 10.3rem;

            .-scrolled & {
                height: 6.1rem;
            }
        }
        .logo {
            width: 15.8rem;

            .-scrolled &  {
                width: 9.75rem;
            }
        }
    }
</style>
