import { isDevMode, isProdMode } from './environment';

const consoleLogsEnabled = location.search.includes('enable-console-logs=true') || isDevMode();
const appInsightEnabled = location.search.includes('enable-app-insight-logs=true') || isProdMode();
const serverLogsEnabled = location.search.includes('enable-server-logs=true') || isProdMode();

let appInsights;

export async function init(): Promise<void> {
    if (!appInsightEnabled) return Promise.resolve();

    const appInsight = await import('./application-insight');
    appInsights = appInsight.initAppInsight();
}

const serverLoggingService = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debug(obj: any) {
        // Dont send debug to BE
    },
    info(obj: any) {
        appInsights.then(ai => ai.infoLog(obj));
    },
    warn(obj: any) {
        appInsights.then(ai => ai.warnLog(obj));
    },
    error(obj: any) {
        appInsights.then(ai => ai.errorLog(obj));
    },
    fatal(obj: any) {
        appInsights.then(ai => ai.fatalLog(obj));
    },
    fatalException(obj: any, ex: any) {
        appInsights.then(ai => ai && ai.trackException(obj, ex));
    },
};

class Logging {
    public debug(logObject: any): void {
        this.consoleLogging(() => console.log(logObject));
        this.serverLogging(() => serverLoggingService.debug(logObject));
    }

    public info(logObject: any) {
        this.consoleLogging(() => console.info(logObject));
        this.serverLogging(() => serverLoggingService.info(logObject));
    }

    public warn(logObject: any) {
        this.consoleLogging(() => console.warn(logObject));
        this.serverLogging(() => serverLoggingService.warn(logObject));
    }

    public error(logObject: any) {
        this.consoleLogging(() => console.error(logObject));
        this.serverLogging(() => serverLoggingService.error(logObject));
    }

    public fatal(logObject: any) {
        this.consoleLogging(() => console.error(logObject));
        this.serverLogging(() => serverLoggingService.fatal(logObject));
    }

    public exception(logObject: any, e: any) {
        this.consoleLogging(() => console.error(logObject, e));
        this.serverLogging(() => {
            try {
                serverLoggingService.fatalException(logObject, e);
            } catch {
                // It has been seen that an object (e.g. internal vue object cannot be serialized due to circular refs.)
                serverLoggingService.fatal(logObject);
            }
        });
    }

    private consoleLogging(func: () => void) {
        if (consoleLogsEnabled) {
            func();
        }
    }

    private serverLogging(func: () => void) {
        if (serverLogsEnabled) {
            func();
        }
    }
}

export default new Logging();
