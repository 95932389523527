<template>
    <vue-svgicon aria-hidden="true"
                 class="c-icon-icon c-icon-fill"
                 :data="data"
                 :title="useAutoTitle ? screenReaderFriendlyTitle : ''"
                 :fill="fill"
                 :color="color"
                 :width="width"
                 :height="height"
                 v-bind="$attrs"/>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'CIcon',
    props: {
        name: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: '',
        },
        height: {
            type: String,
            default: '',
        },
        fill: {
            type: Boolean,
            required: false,
        },
        useAutoTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Color can be manually overwritten with this prop, else just set color via css from parent
        color: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const screenReaderFriendlyTitle = props.name.replace(/-/g, ' ');

        const data = ref(undefined);
        import(`../../../assets/icons/${props.name}.svg`)
            .then(svgModule => data.value = svgModule.default);

        return { screenReaderFriendlyTitle, data };
    },
});
</script>
