<template>
    <button v-if="shouldShowBasket"
            class="block relative md:mr-10"
            :aria-label="getAriaLabelText(totalItems)"
            @click="openOverlay()">
        <div class="flex flex-col items-center w-[35px] h-32">
            <CIcon v-if="!totalItems"
                   class="block h-full"
                   name="icon-basket"
                   fill/>
            <CIcon v-else
                   class="block h-full"
                   :class="{'basket-update': showBasketUpdateAnim}"
                   name="icon-basket-full"
                   fill/>
        </div>

        <div v-if="totalItems"
             class="flex absolute top-[-.5rem] right-[-.8rem] p-2 min-w-[2rem] min-h-[2rem] font-fauli text-14 text-white bg-pink-100 rounded-full">
            <span class="block m-auto mt-4 leading-none">{{ totalItems }}</span>
        </div>
    </button>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, Ref, ref, watch } from 'vue';
import { clientBasket } from '@/project/basket/basket.service';
import { isEqual } from 'lodash-es';
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import openDialog from '@/project/dialog/dialog';
import MiniBasketDialog from '@/project/basket/MiniBasketDialog.vue';
import { RouteLocationNormalizedLoaded, useRouter } from 'vue-router';
import { PageNames } from '@/router';
import { productService } from '../product/product.service';
import { ProductType } from '@/api/content';
import dictionary from '@/core/dictionary/dictionary';


export default defineComponent({
    name: 'MiniBasket',
    setup() {
        const showBasketUpdateAnim = ref(false);
        const showOverlay = ref(false);
        let prevQuantity = clientBasket.value?.lineItems.map(q => q.quantity) ?? 0;
        const router = useRouter();

        const excludeBasketFrom: PageNames[] = [
            // Giftcard flow
            PageNames.GiftCardCheckoutInformation,
            PageNames.GiftCardCheckoutPayment,
            PageNames.GiftCardCheckoutReceipt,
            PageNames.GiftCardDetail,
        ];

        const checkForPdp = (currentRoute: RouteLocationNormalizedLoaded) => {
            return currentRoute.path.includes(productService.getProductTypeUrlPrefixFromProductType(ProductType.GiftCard));
        };

        const shouldShowBasket = computed(() => {
            const currentRouteName = router.currentRoute.value.name as PageNames;
            const isInExcludeRoute = excludeBasketFrom.includes(currentRouteName) || checkForPdp(router.currentRoute.value);

            return !isInExcludeRoute;
        });


        const lineItems = computed(() => {
            return clientBasket.value.lineItems;
        });

        function openOverlay() {
            openDialog(MiniBasketDialog, { position: 'right' });
        }

        const totalItems: ComputedRef<number | undefined> = computed(() => {
            if (!lineItems.value || lineItems.value.find(l => l.productType === ProductType.GiftCard)) { return; }
            let total = 0;

            lineItems.value.forEach((l) => {
                return total += l.quantity;
            });

            return total;
        });

        function getAriaLabelText(totalItems) {
            let ariaLabelText = dictionary.get('Accessibility.MiniBasket.Basket'); 

            const basketWithTranslation = dictionary.get('Accessibility.MiniBasket.BasketWith');
            const productTranslation = dictionary.get('Accessibility.MiniBasket.Product');
            const productsTranslation = dictionary.get('Accessibility.MiniBasket.Products');

            if(totalItems === 1){
                ariaLabelText = `${basketWithTranslation} ${totalItems} ${productTranslation}`;
            }
            else if(totalItems > 1) {
                ariaLabelText = `${basketWithTranslation} ${totalItems} ${productsTranslation}`;
            }

            return ariaLabelText;
        }

        watch(lineItems, (l) => {
            const quantity = l.flatMap(q => q.quantity);
            if (!isEqual(quantity, prevQuantity)) {
                showBasketUpdateAnim.value = true;
                setTimeout(() => {
                    showBasketUpdateAnim.value = false;
                    prevQuantity = quantity;
                }, 1000);
            }
        });

        return {
            showBasketUpdateAnim,
            totalItems,
            openOverlay,
            DrawerDialog,
            clientBasket,
            showOverlay,
            shouldShowBasket,
            getAriaLabelText,
        };
    },
});
</script>

<style scoped>
    .icon-hover {
        &:hover {
            animation: icon-hover 500ms ease-in-out infinite;
        }
    }

    .basket-update {
        animation: icon-hover 500ms ease-in-out infinite;
    }

    @keyframes icon-hover {
        0% {
            transform: rotate(0);
        }
        25% {
            transform: rotate(-10deg);
        }
        50% {
            transform: rotate(0deg);
        }
        75% {
            transform: rotate(10deg);
        }
    }
</style>
