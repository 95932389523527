<template>
    <footer class="relative w-full footer">
        <TransitionRoot :appear="true"
                        show
                        enter="transition-opacity duration-1000"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="transition-opacity duration-1000"
                        leave-from="opacity-100"
                        leave-to="opacity-0">
            <div class="px-20 text-center bg-green-200 md:px-40">
                <div class="hidden md:block">
                    <FooterLinks/>
                </div>
                <ul class="flex flex-col items-center md:hidden">
                    <Usps/>
                </ul>
            </div>
            <FooterSocialMedia class="px-20 pt-30 bg-green-100 md:px-40 md:pt-40"/>

            <div class="flex flex-col items-center py-21 px-20 bg-green-100 md:flex-row inner-height">
                <div class="mx-auto text-center md:w-1/3">
                    <div class="hidden mt-78 text-12 md:block md:w-1/3">
                        {{ footerData.copyrightText }}
                    </div>
                </div>
                <div class="mx-auto text-center md:w-1/3">
                    <FooterContactOption/>

                    <div class="md:hidden">
                        <FooterLinks/>
                    </div>
                    <div class="hidden text-12 md:block">
                        <span class="font-normal">{{ footerData.storeName }} &bull; </span>
                        <span>{{ footerData.street }} &bull; </span>
                        <span>{{ footerData.postalCode }} {{ footerData.city }} &bull; </span>
                        <span>{{ footerData.vatText }}</span>
                    </div>
                </div>
                <div class="text-right md:w-1/3">
                    <img v-for="(paymentIcon, index) in icons"
                         :key="index"
                         :src="paymentIcon.url"
                         class="inline-block py-23 mr-20 last:mr-0 text-16"
                         :alt="paymentIcon.name ?? ''"
                         :title="paymentIcon.name ?? ''"
                         width="50"
                         height="32"
                         loading="lazy">
                </div>

                <div class="mb-15 md:hidden">
                    {{ footerData.copyrightText }}
                </div>
                <div class="text-center md:hidden">
                    <span class="font-bold">{{ footerData.storeName }} &bull; </span>
                    <span>{{ footerData.street }} &bull; </span>
                    <span>{{ footerData.postalCode }} {{ footerData.city }} &bull; </span>
                    <span>{{ footerData.vatText }}</span>
                </div>
            </div>
        </TransitionRoot>
    </footer>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import FooterLinks from '@/project/navigation/footer/FooterLinks.vue';
import FooterSocialMedia from '@/project/navigation/footer/FooterSocialMedia.vue';
import FooterContactOption from './FooterContactOption.vue';
import { TransitionRoot } from '@headlessui/vue';
import Usps from '@/project/content/Usps.vue';
import contentApi from '@/project/content/api/contentApi';
import { Contact, NameAndUrl } from '@/api/content/models';
import { ContactOption } from '@/api/content/models/contact-option';

type IFooter = Pick<Contact, 'storeName' | 'street' | 'city' | 'postalCode' | 'vatText'> & {
    copyrightText: string,
    paymentIcons: NameAndUrl[]
};

export default defineComponent({
    name: 'Footer',
    components: {
        FooterLinks,
        TransitionRoot,
        Usps,
        FooterSocialMedia,
        FooterContactOption,
    },
    setup() {
        const footerData = ref<IFooter>({
            city: '',
            postalCode: '',
            street: '',
            storeName: '',
            vatText: '',
            copyrightText: '',
            paymentIcons: [],
        });

        let icons = ref<NameAndUrl[]>();

        contentApi.settings.then(settings => {
            footerData.value = {
                ...settings.contact,
                copyrightText: `Copyright © ${(new Date()).getFullYear()} Normal`,
            } as IFooter;

            icons.value = settings.paymentIcons?.map(p => p as NameAndUrl);
        });

        return {
            footerData,
            icons,
        };
    },
});
</script>

<style scoped>
.center-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 189px;
    height: 60px;
    transform: translate(-50%, -50%);
}

.inner-height {
    height: calc(100% + 8rem);
}

@screen md {
    .inner-height {
        @apply h-auto min-h-151;
    }
}
</style>
