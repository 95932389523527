import { Ref, shallowRef } from 'vue';
import { cloneDeep } from 'lodash-es';
import axios from 'axios';

export type SWRResult<T> = T & {
    dataReady: boolean,
    error?: any
};

export function useStaleWhileRevalidate<T>(defaultResponse: () => T, maxCacheEntries = 25): {
    makeReactiveResult: typeof makeReactiveResult,
    fetchResult: typeof fetchResult
} {
    const cache = new Map<string, T>();
    const filo: string[] = [];

    function makeReactiveResult(): Ref<SWRResult<T>> {
        return shallowRef<SWRResult<T>>({
            dataReady: false,
            ...defaultResponse(),
        });
    }

    function fetchResult(result: Ref<SWRResult<T>>, resultProvider: () => Promise<T>, cacheKey: string): Ref<SWRResult<T>> {
        const cachedResult = cacheKey && cache.get(cacheKey);
        if (cachedResult) {
            result.value = {
                dataReady: true,
                ...cachedResult,
            };
        } else {
            result.value = {
                dataReady: false,
                ...defaultResponse(),
            };
        }

        resultProvider()
            .then(callResult => {
                result.value = {
                    ...callResult,
                    dataReady: true,
                };
                if (cacheKey) {
                    cache.set(cacheKey, cloneDeep(callResult));
                    maintainCacheSize(cacheKey);
                }
            })
            .catch((e) => {
                // Do nothing on cancel-errors - new result will arrive. Else keep old value, but set error
                if (!axios.isCancel(e)) {
                    result.value = {
                        ...result.value,
                        error: e,
                        dataReady: true,
                    };
                }
            });
        return result;
    }

    function maintainCacheSize(cacheKey: string) {
        filo.push(cacheKey);
        if (filo.length > maxCacheEntries) {
            const removeKey = filo.shift();
            cache.delete(removeKey!);
        }
    }

    return {
        makeReactiveResult,
        fetchResult,
    };
}
