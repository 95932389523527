<template>
    <InputCheckbox :id="title" 
                   :name="name" 
                   :label="title" 
                   :placeholder="title"
                   :required="props.model.required"/>
</template>

<script lang="ts" setup>
import { CheckboxBlock } from '@/api/content/models';
import { computed } from 'vue';
import InputCheckbox from '../forms/InputCheckbox.vue';

type Props = {
    model: CheckboxBlock
}

const props = defineProps<Props>();

const title = computed(() => props.model.checkboxHeader ?? '');

const name = computed(() => handleDots(props.model.checkboxHeader) ?? '');

function handleDots(input) {
    return input.replace(/\./g, '[dot]');
}

</script>
