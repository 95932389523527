import { configureBreakpoints } from '@/core/responsive/breakpoints/breakpointsConfig';

export enum Breakpoint {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
}

export type BreakpointConfig = Partial<Record<Breakpoint, number>>;

/* REMEMBER TO KEEP IN SYNC WITH TAILWINDS screens */
const breakpoints: BreakpointConfig = {
    [Breakpoint.XS]: 0, // Pure mobile
    [Breakpoint.SM]: 415, // Mobile and tablet vertical
    [Breakpoint.MD]: 769, // Up to and including old tablet horizontal
    [Breakpoint.LG]: 1025, // Desktop,
    [Breakpoint.XL]: 1281, // Desktop,
};

configureBreakpoints(breakpoints);
