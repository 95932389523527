<template>
    <component :is="href && !disabled ? 'RouterLinkConditional' : 'button'"
               ref="ctaElement"
               class="group relative"
               :target="target"
               :href="href"
               :disabled="disabled"
               :class="[variant, isButton ? `button button-${size}`: 'link', disabled ? 'cursor-default' : 'cursor-pointer']">
        <template v-if="isButton">
            <span class="relative z-1"
                  :class="[textColorClasses, $attrs.class]">
                <slot/>
            </span>
            <svg width="186px"
                 height="100%"
                 viewBox="0 0 186 58"
                 preserveAspectRatio="none"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink"
                 class=" absolute inset-0 w-auto"
                 :class="backgroundColorClasses">
                <g id="Styleguide"
                   stroke="none"
                   stroke-width="1"
                   fill="none"
                   fill-rule="evenodd">
                    <g id="Knap"
                       transform="translate(-735.000000, -850.000000)"
                       fill="currentColor">
                        <path d="M745.742623,851.940127 C741.82468,853.416947 738.695767,858.326508 736.773897,862.584094 C734.852027,866.839212 736.13822,878.905667 735.178522,882.45201 C734.216351,886.003291 737.434308,896.763329 737.91663,898.301889 C738.396479,899.840448 739.8385,904.453657 747.046131,906.226829 C754.251288,908 766.26483,908 773.95231,908 C781.63979,908 812.105262,907.293695 819.792742,906.582451 C827.477748,905.873676 865.994297,907.646847 870.800209,907.293695 C875.601174,906.938073 900.456863,908 905.260302,908 C910.066213,908 912.834003,907.762919 915.55727,904.335117 C918.275591,900.902376 921.481181,897.709185 920.840557,888.83839 C920.199934,879.972533 921.758207,873.465143 920.47696,868.377771 C919.19324,863.295339 920.155412,856.197714 916.309199,853.004524 C912.467932,849.811334 904.619678,849.927405 900.615164,850.043476 C896.613124,850.162017 889.548953,850.643588 878.485215,850.520109 C872.506889,850.45096 845.88763,849.855787 830.359316,850.164486 C819.305472,850.384281 799.072659,850.873261 795.572728,850.873261 C781.159941,850.873261 753.269329,849.102559 745.742623,851.940127"/>
                    </g>
                </g>
            </svg>
        </template>
        <template v-else>
            <slot/>
            <div class="ml-3"
                 :class="$attrs.class">
                >
            </div>
        </template>
    </component>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
    name: 'CallToAction',
    props: {
        href: {
            default: '',
            required: false,
            type: String as PropType<RouteLocationRaw>,
        },
        target: {
            default: '_self',
            type: String,
            validator: (value: string) => ['_blank', '_self', '_parent', '_top'].includes(value),
        },
        variant: {
            default: 'PrimaryButton',
            validator: (value: string) => ['PrimaryButton', 'SecondaryButton', 'TertiaryButton'].includes(value),
            type: String,
        },
        size: {
            default: 'large',
            validator: (value: string) => ['small', 'large'].includes(value),
            type: String,
        },
        color: {
            default: '',
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },

    },
    setup(props) {
        const isButton = computed(() => props.variant.includes('Button'));

        const ctaElement = ref<HTMLAnchorElement | HTMLButtonElement | undefined>(undefined);

        const focus = () => {
            if (ctaElement.value !== undefined) {
                ctaElement.value.focus();
            }
        };

        // Backgroundcolors needs to be defined from 'text-*' classes because of svg fill = currentcolor
        const colors = new Map<string, { color: string, backgroundColor: string}>([
            ['PrimaryButton', { color: 'text-white', backgroundColor: 'text-pink-100' }],
            ['SecondaryButton', { color: 'text-white', backgroundColor: 'text-black' }],
            ['TertiaryButton', { color: 'text-black', backgroundColor: 'text-green-100' }],
        ]);
        const textColorClasses = [colors.get(props.variant)?.color ?? 'text-white', 'group-hover:text-white'];
        const backgroundColorClasses = props.color ? props.color : colors.get(props.variant)?.backgroundColor ?? 'text-black';

        return {
            isButton,
            focus,
            ctaElement,
            textColorClasses,
            backgroundColorClasses,
        };
    },
});
</script>

<style scoped>

.link {
    @apply inline-flex items-center uppercase font-bold outline-none;

    &:hover path {
        transform: translateX(34px);
    }

    &:hover svg {
        width: 80px;
    }
}

.button {
    background-size: 100% 100%;
    @apply inline-block text-white text-center border-none px-30 py-15 transition-all duration-300 ease-in-out outline-none box-border font-fauli text-16;

    &.-small {
        @apply h-auto py-10 px-20;
    }
    &.-large {
        @apply min-h-[5.8rem] h-auto text-11;
    }
    & > svg {
        @apply w-full transition-all duration-300 ease-in-out;
    }
    &:hover > svg {
        @apply text-black transition-all duration-300 ease-in-out;

    }
    .SecondaryButton& {
        &:hover > svg {
            @apply text-pink-100 transition-all duration-300 ease-in-out;
        }
    }

    &:disabled {
        cursor: default;
        & > svg {
            @apply text-black opacity-25;
        }
    }

    &.-py-10{
        padding: 1rem;
    }
}

</style>
