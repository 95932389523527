<template>
    <article>
        <ContentBlockList :blocks="model.blocks"
                          class="flex flex-col md:grid md:grid-cols-2"
                          :class="{'flex-col-reverse': model.reverseOrderOnSmallScreens}"/>
    </article>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { FiftyFiftyBlock } from '@/api/content/models/fifty-fifty-block';
import ContentBlockList from '@/core/content/ContentBlockList.vue';

export default defineComponent({
    name: 'FiftyFiftyBlock',
    components: { ContentBlockList  },
    props: {
        model: {
            required: true,
            type: Object as PropType<FiftyFiftyBlock>,
        },
    },
});
</script>
