import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { basketStateHandler } from '@/core/http/interceptors/basketState.interceptor';
import validationErrorsInterceptor from '@/core/http/interceptors/error.interceptor';
import redirectInterceptor from '@/core/http/interceptors/redirect.interceptor';
import HttpStatus from 'http-status-codes';
import { requestHandler as checkoutStepInterceptor } from '@/core/http/interceptors/checkoutStep.interceptor';

// synchroneus is not exposed in typescript...
type extendedUseFunc<T> = (onFulfilled?: (value: T) => T | Promise<T>, onRejected?: (error: any) => any, options?: { synchronous: boolean }) => number;

// Those status'es that should cause "then" to be executed (so we can have interceptors)
const handledErrorStatusCodes = [
    HttpStatus.BAD_REQUEST,
    HttpStatus.NOT_FOUND,
    HttpStatus.INTERNAL_SERVER_ERROR,
    HttpStatus.BAD_GATEWAY,
    HttpStatus.UNAUTHORIZED,
];

const responseInterceptors: Array<(value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>> = [basketStateHandler, validationErrorsInterceptor, redirectInterceptor];

const requestInterceptors: Array<
    (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>
    > = [checkoutStepInterceptor];

function registerInterceptors() {
    responseInterceptors.forEach(i => axios.interceptors.response.use(i));
    requestInterceptors.forEach(i => ((axios.interceptors.request.use) as extendedUseFunc<AxiosRequestConfig>)(i, undefined, { synchronous: true }));
}

export function setupAxiosInterceptor() {
    axios.defaults.validateStatus = status => (status >= 200 && status < 300) || handledErrorStatusCodes.includes(status);
    registerInterceptors();
}
