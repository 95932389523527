import { localStorageService, sessionStorageService } from '@/core/storage/storage.service';
import { ref } from 'vue';
import { CheckoutFactory } from '@/api/checkout/apis/checkout';
import { BasketViewModel, SetAddressRequest, ReserveBasketViewModel, LineItem, ReservationError, AmountViewObject, LineItemViewObject } from '@/api/checkout';
import { checkoutHost } from '@/core/infrastructure/environment';
import log from '@/core/infrastructure/logging';
import { debounce } from 'lodash-es';
import { trackAddToBasket, TrackingObject } from '../tracking/tracking.service';
import Deferred from '@/core/async/Deferred';
import { StatusCodes } from 'http-status-codes';
import { getCultureSettings } from '@/project/content/api/contentApi';
import { GiftCardDetailsModel, ProductType } from '@/api/content';
import { ContactInformationSchemaType } from './contact-information.service';

export type BasketSummaryType = {
    lineItems: LineItemViewObject[];
    currency: string;
    calculatedTotal: AmountViewObject;
    delivery?: {
        deleveryPrice?: AmountViewObject;
    };
    subTotal: AmountViewObject;
}

export type BasketKeys = {
    [Property in ProductType]: string;
}

export const basketKeys: BasketKeys = {
    GiftCard: 'giftcardBasketId',
    Product: 'basket-id',
};

const commerceEnabledPromise = getCultureSettings().then(settings => settings.commerceEnabled);
const culturePromise = getCultureSettings().then(settings => settings.culture);
const basketIdDeferred = new Deferred<string>();
export const basketIdKey = 'basket-id'; //TODO: Remove in favour of basketKeys
let basketId = '';

export const clientBasket = ref<BasketViewModel>({
    id: basketId,
    currency: '',
    culture: '',
    isAllProductsReserved: false,
    isAllProductsSoldOut: false,
    isCompleted: false,
    lineItems: [],
    subTotal: {
        formatted: '',
        value: 0,
    },
    total: {
        formatted: '',
        value: 0,
    },
    vat: {
        formatted: '',
        value: 0,
    },
    deliveryAddress: null,
    deliveryPrice: null,
    paymentId: null,
    selectedDropPoint: null,
    selectedShippingMethod: null,
    webOrderNumber: null,
    subscribeToNewsletter: false,
});
export const reservationErrors = ref<ReservationError[]>([]);
export const reserveBasketModel = ref<ReserveBasketViewModel>({} as ReserveBasketViewModel);

let queue: Array<{ sku: string, quantity: number }> = [];
const debouncedUpdateServer = debounce(updateServer, 100);
let updatingServer = false;

const basketApi = CheckoutFactory({
    basePath: checkoutHost,
    isJsonMime: () => true,
});

export async function getBasket(): Promise<BasketViewModel> {
    const commerceEnabled = await commerceEnabledPromise;
    const culture = await culturePromise;

    if (!commerceEnabled) { 
        return clientBasket.value; 
    }

    const basketIdToGet = getBasketIdFromStorage();

    basketId = basketIdToGet; //TODO: can this global var be omitted?

    basketApi.apiCheckoutGetBasketGet(basketIdToGet, undefined, culture).then((res) => {
        clientBasket.value = res.data.model!;
        basketIdDeferred.resolve(clientBasket.value.id);

        if (clientBasket.value.id !== basketIdToGet) {
            localStorageService.setItem(basketKeys.Product, clientBasket.value.id);
        }
    });

    return clientBasket.value;
}

function getBasketIdFromStorage(): string {
    const giftcardBasketId = sessionStorageService.getItem(basketKeys.GiftCard);
    const basketId = localStorageService.getItem(basketKeys.Product);

    return giftcardBasketId ? giftcardBasketId : basketId || '';
}

export async function formsPayloadLogging(calledFrom:string, object:any){
    await basketApi.apiCheckoutLogDataPost(calledFrom, object);
}

export function addProduct(product, quantity = 1, trackingObject: TrackingObject): void {
    // If exists already, its really an update
    const existingLineItem = clientBasket.value?.lineItems?.find(l => l.sku === product.sku);
    const increasedQuantity = true;
    if (existingLineItem) {
        return updateLineItem(product, quantity + existingLineItem.quantity, trackingObject);
    }

    // Update basket beforehand - insert as first
    clientBasket.value?.lineItems?.unshift({
        ...product,
    });

    updateQueue(product.sku, quantity);
    trackAddToBasket(product, quantity, trackingObject.productTileMode, increasedQuantity, trackingObject.productIndex, trackingObject.contentBlockIndex);
}

export function updateLineItem(product, quantity: number, trackingObject: TrackingObject): void {
    if (!clientBasket.value) {
        throw new Error('Basket not fetched yet');
    }

    // Update basket beforehand - maybe delete
    const existingLineItem = clientBasket.value?.lineItems.find(l => l.sku === product.sku);
    if (!existingLineItem) {
        throw new Error('Lineitem not in basket: ' + product.sku);
    }
    const increasedQuantity = quantity > existingLineItem.quantity;
    const quantityToTrack = increasedQuantity ? quantity - existingLineItem.quantity : existingLineItem.quantity - quantity;
    existingLineItem.quantity = quantity;
    if (existingLineItem.quantity === 0) {
        clientBasket.value.lineItems = clientBasket.value.lineItems.filter(l => l.sku !== product.sku);
    }

    updateQueue(product.sku, quantity);
    trackAddToBasket(product, quantityToTrack, trackingObject.productTileMode, increasedQuantity, trackingObject.productIndex, trackingObject.contentBlockIndex);
}
function updateQueue(sku: string, quantity: number): void {
    const existingLine = queue.find(l => l.sku === sku);
    if (existingLine) {
        existingLine.quantity = quantity;
    } else {
        queue.push({ sku, quantity });
    }
    debouncedUpdateServer();
}

async function updateServer() {
    if (updatingServer) return;
    const culture = await culturePromise;
    updatingServer = true;
    const lineItems: LineItem[] = [...queue];
    queue = [];
    basketApi.apiCheckoutAddOrUpdateLineItemsPost({
        basketId: clientBasket.value!.id,
        lineItems: lineItems,
        culture: culture,
    })
        .then(_basket => {
            // Only use result if nothing more in queue
            if (!queue.length && _basket.data.model) {
                clientBasket.value = _basket.data.model;
                setBasketId();
            } else if (_basket.status === StatusCodes.OK) {
                debouncedUpdateServer();
            }
        })
        .finally(() => {
            updatingServer = false;
        });
}

function setBasketId() {
    const basketIdFromStorage = localStorageService.getItem(basketIdKey);
    if (clientBasket.value?.id && basketIdFromStorage !== clientBasket.value.id) {
        localStorageService.setItem(basketIdKey, clientBasket.value.id);
    }
}

export function getBasketId(): string | null {
    return localStorageService.getItem(basketIdKey);
}

export function getAsyncBasketId(): Promise<string> {
    return basketIdDeferred.promise;
}

export async function reserveBasket(): Promise<ReserveBasketViewModel | void> {
    const basketIdFromStorage = localStorageService.getItem(basketIdKey) || '';
    if (!basketIdFromStorage) {
        log.warn('ReserverBasket: No basketId from storage');
        return;
    }
    const response = await basketApi.apiCheckoutReserveBasketPost({ basketId: basketIdFromStorage });
    clientBasket.value = response.data.model?.basket ?? clientBasket.value;
    reservationErrors.value = response.data.model?.reservationErrors ?? [];

    return response.data.model ?? {};
}

export async function saveAddress(address: SetAddressRequest): Promise<BasketViewModel | void> {
    const basket = await basketApi.apiCheckoutUpdateAddressPost(address);
    if (basket.data.model) {
        clientBasket.value = basket.data.model;
        return clientBasket.value;
    }
}

export async function prepareCompleteCheckout(basketKey: ProductType): Promise<BasketViewModel> {
    const basketIdFromStorage = basketKey === ProductType.Product ? localStorageService.getItem(basketKeys[basketKey]) : sessionStorageService.getItem(basketKeys[basketKey]);
    const basket = await basketApi.apiCheckoutPrepareCompleteCheckoutPost({ basketId: basketIdFromStorage || '' });
    clientBasket.value = basket.data.model as BasketViewModel;
    return clientBasket.value;
}

export async function completeCheckout(paymentId: string): Promise<BasketViewModel | undefined> {
    try {
        const response = await basketApi.apiCheckoutCompleteCheckoutPost({ paymentId: paymentId });
        if (response.data.model) {
            clientBasket.value = response.data.model;
            return response.data.model;
        }
        // } else {
        //     throw response.status;
        // }
        
    } catch (error) {       
        console.log({error});
    }
}

export const ProductBasketSummary = (isInDeleveryStep: boolean): BasketSummaryType => {
    const selectedShippingMethod = clientBasket.value.deliveryPrice?.value;

    return {
        calculatedTotal: isInDeleveryStep && selectedShippingMethod
            ? { value: clientBasket.value?.subTotal?.value + selectedShippingMethod, formatted:  clientBasket.value?.subTotal?.value.toString()}
            : { value: clientBasket.value?.subTotal?.value,  formatted:  clientBasket.value?.subTotal?.value.toString() },
        currency: clientBasket.value.currency,
        lineItems: clientBasket.value.lineItems,
        subTotal: clientBasket.value.subTotal,
        delivery: {
            deleveryPrice: {
                value: clientBasket.value.deliveryPrice?.value ?? 0,
                formatted: clientBasket.value.deliveryPrice?.formatted ?? '',
            },

        },
    };
};

export const getBasketSummary = (isDeliveryOrPaymentCheckoutStep) => {
    const selectedShippingMethod = clientBasket.value.deliveryPrice?.value;

    return {
        calculatedTotal: isDeliveryOrPaymentCheckoutStep && selectedShippingMethod
            ? { value: clientBasket.value?.subTotal?.value + selectedShippingMethod, formatted:  clientBasket.value?.subTotal?.value.toString()}
            : { value: clientBasket.value?.subTotal?.value,  formatted:  clientBasket.value?.subTotal?.value.toString() },
        currency: clientBasket.value.currency,
        lineItems: clientBasket.value.lineItems,
        subTotal: clientBasket.value.subTotal,
        delivery: {
            deleveryPrice: {
                value: clientBasket.value.deliveryPrice?.value ?? 0,
                formatted: clientBasket.value.deliveryPrice?.formatted ?? '',
            },

        },
    };
};


/**
 * 
 * GiftCard
 *  
 */
export const createGiftCardBasket = async(giftcard: GiftCardDetailsModel) => {
    const culture = await culturePromise;
   
    const giftcardBasket = await basketApi.apiCheckoutCreateGiftCardBasketPost({
        lineItem: {
            quantity: 1,
            sku: giftcard.sku,
        },
        culture,
    });

    if(!giftcardBasket.data.isSuccess) {
        return;
    }
    clientBasket.value = giftcardBasket.data.model;

    sessionStorageService.setItem(basketKeys.GiftCard, giftcardBasket.data.model.id);
};  

export const updateGiftCard = async(id: string, contactInformation: ContactInformationSchemaType) => {
    await basketApi.apiCheckoutUpdateAddressPost({
        basketId: id,
        city: contactInformation.city,
        email: contactInformation.email,
        firstName: contactInformation.firstName,
        lastName: contactInformation.lastName,
        phone: contactInformation.phone,
        postalCode: contactInformation.postalCode.toString(),
        street: contactInformation.street,
        subscribeToNewsletter: contactInformation.subscribeToNewsletter,
    });
};
