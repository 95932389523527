import { defineRule, configure } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, email, min, max, min_value, between, is, confirmed } from '@vee-validate/rules';
import { FieldContext, ValidationRule } from '@/core/forms/vee-validate.types';
import dictionary from '../dictionary/dictionary';

const isTrue: typeof is = (value: any) => {
    return Boolean(value && value.length);
};

export const rules: Record<string, ValidationRule> = {
    required: {
        validator: required,
        label: getLabel('Required', 'checkout'),
    },
    'required-checkbox': {
        validator: required,
        label: getLabel('RequiredCheckbox', 'checkout'),
    },
    formsRequired: {
        validator: required,
        label: getLabel('Required', 'forms'),
    },
    email: {
        validator: email,
        label: getLabel('Email', 'checkout'),
    },
    formsEmail: {
        validator: email,
        label: getLabel('Email', 'forms'),
    },
    confirmed: {
        validator: confirmed,
        label: getLabel('ConfirmEmail', 'checkout'),
    },
    min: {
        validator: min,
        label: getLabel('Min', 'checkout'),
    },
    'min-value': {
        validator: min_value,
        label: getLabel('MinValue', 'checkout'),
    },
    between: {
        validator: between,
        label: getLabel('Between', 'checkout'),
    },
    max: {
        validator: max,
        label: getLabel('Max', 'checkout'),
    },
    'is-true': {
        validator: isTrue,
        label: getLabel('Required', 'checkout'),
    },
};



configure({
    generateMessage,
});

setupRules();

function getLabel(rule: string, component: string): string {
    if(component === 'checkout'){
        return `Checkout.Validation.${rule}`;
    } 
    else if (component === 'forms') {
        return `Forms.Validation.${rule}`;
    }
    return `Checkout.Validation.${rule}`;
}

function setupRules(): void {
    Object.keys(rules).forEach((id: string) => {
        const validator = rules[id].validator;
        defineRule(id, validator);
    });
}

function generateMessage(context: FieldContext): string {
    if (!context.rule) {
        throw new Error(`Validation-error but no rules: ${context.field}`);
    }

    const labelKey = rules[context.rule.name].label;
    const params: string[] = [];
    if (context.rule.params) {
        // Can be array or object with key/values - e.g. { min: xx, max: yy }. Convert to array always.
        params.push(...Array.isArray(context.rule.params) ? context.rule.params : Object.values(context.rule.params));
    }

    const labelTranslate = translate(labelKey, context.field, params);

    return labelTranslate.length === 0 ? labelKey : labelTranslate;
}

function translate(label: string, field: string, params: string[]): string {
    //    return `${label}. Field: ${field}. Params: ${params.join('/')}`;
    return dictionary.get(label, ...params);
}
