import { StockStatusViewObject } from '@/api/stock/models/stock-status-view-object';
import { stockApiHost } from '@/core/infrastructure/environment';
import { useStaleWhileRevalidate } from '@/core/cache/useStaleWhileRevalidate';
import { StockFactory, StockStatus } from '@/api/stock';
import { getAsyncBasketId } from '../basket/basket.service';
import { getCultureSettings } from '@/project/content/api/contentApi';

const culturePromise = getCultureSettings().then(settings => settings.culture);
const stockApi = StockFactory({
    basePath: stockApiHost,
    isJsonMime: () => true,
});

const {
    makeReactiveResult: makeReactiveForStock,
    fetchResult: fetchStockResult,
} = useStaleWhileRevalidate<Array<StockStatusViewObject>>(() => ([
    {
        sku: '',
        status: StockStatus.Available,
    },
]));

export function useStock(skus: string[]): {
    stockResult: typeof stockResult,
} {
    const stockResult = makeReactiveForStock();

    async function getStock(skus: string[], basketId) {
        const culture = await culturePromise;
        const stockResultProvider = () => stockApi.apiStockGetStockStatusGet(skus, basketId!, culture)
            .then(stockResponse => stockResponse.data.model ?? []);

        fetchStockResult(stockResult, stockResultProvider, JSON.stringify(skus));
    }

    getAsyncBasketId().then((basketId)=> {
        getStock(skus, basketId);
    });

    return {
        stockResult,
    };
}
