import { CheckoutFactory } from '@/api/checkout/apis/checkout';
import { checkoutHost } from '@/core/infrastructure/environment';
import { basketIdKey } from '@/project/basket/basket.service';
import { ReservationStatusViewModel } from '@/api/checkout';
import { localStorageService } from '@/core/storage/storage.service';

const getCountdown = CheckoutFactory({
    basePath: checkoutHost,
    isJsonMime: () => true,
});

export async function getTimeLeft(): Promise<ReservationStatusViewModel | null> {
    const basketIdFromLocalStorage = localStorageService.getItem(basketIdKey) || undefined;
    const timeLeft = await getCountdown.apiCheckoutGetReservationStatusGet(basketIdFromLocalStorage);

    return timeLeft.data.model;
}
