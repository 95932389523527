<template>
    <Field v-slot="{ field }"
           v-model="internalValue"
           :name="name"
           type="radio"
           :value="value">
        <label :for="fieldId"
               class="cursor-pointer">
            <div class="flex items-center">
                <div class="relative mr-[12px] w-30 h-30">
                    <input :id="fieldId"
                           v-bind="{ ...$attrs, ...field }"
                           type="radio"
                           :name="name"
                           :value="value"
                           class="opacity-0">
                    <!-- Radio button SVG     -->
                    <svg width="28px"
                         height="29px"
                         preserveAspectRatio="none"
                         viewBox="0 0 28 29"
                         version="1.1"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink"
                         class="absolute inset-0">
                        <title>Radiobutton</title>
                        <g id="Styleguide"
                           stroke="none"
                           stroke-width="1.5"
                           fill="none"
                           fill-rule="evenodd">
                            <g id="Radiobutton"
                               transform="translate(-795.000000, -1667.000000)"
                               fill="#FFFFFF"
                               stroke="#000000">
                                <path vector-effect="non-scaling-stroke"
                                      d="M809,1668.19257 C814.954726,1668.19257 817.705481,1670.37507 820.134866,1674.02437 C821.619405,1676.25437 822.5,1678.72427 822.5,1683 C822.5,1686.55631 821.627068,1689.07671 818.788059,1691.56946 C815.9212,1694.08667 812.888483,1695.5 809,1695.5 C805.596782,1695.5 802.830932,1694.06167 800.459447,1691.98439 C797.626888,1689.50325 795.5,1685.06162 795.5,1681.00039 C795.5,1676.97961 797.657436,1673.72758 800.446988,1671.44072 C802.827598,1669.48912 805.571358,1668.19257 809,1668.19257 Z"/>
                            </g>
                        </g>
                    </svg>
                    <!-- Radio button SVG dot -->
                    <svg v-if="internalValue === value"
                         width="14px"
                         height="15px"
                         viewBox="0 0 14 15"
                         version="1.1"
                         xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink"
                         class="absolute top-2/4 left-2/4 z-1 mt-[-8px] ml-[-8px]">
                        <title>Radiodot</title>
                        <g id="Styleguide"
                           stroke="none"
                           stroke-width="1"
                           fill="none"
                           fill-rule="evenodd">
                            <g id="Radiodot"
                               transform="translate(-916.000000, -1674.000000)"
                               fill="#E6007E">
                                <path vector-effect="non-scaling-stroke"
                                      d="M923,1688.84629 C924.986899,1688.84629 926.538387,1688.12779 928.004036,1686.8409 C929.469686,1685.554 929.923978,1684.25399 929.923978,1682.41688 C929.923978,1680.23552 929.469686,1678.97771 928.712813,1677.84077 C927.466402,1675.96847 926.056208,1674.84629 923,1674.84629 C921.242325,1674.84629 919.834178,1675.50779 918.613163,1676.50877 C917.177731,1677.68553 916.076022,1679.3616 916.076022,1681.42793 C916.076022,1683.50537 917.164295,1685.77719 918.613163,1687.0463 C919.831271,1688.11329 921.253432,1688.84629 923,1688.84629 Z"
                                      transform="translate(923.000000, 1681.846287) scale(-1, -1) translate(-923.000000, -1681.846287) "/>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="font-fauli text-12">
                    <span v-if="label">{{ label }}</span>
                    <span v-else-if="htmlLabel"
                          v-html="htmlLabel"/>
                </div>
            </div>
        </label>
        <InputErrorMessage v-if="showErrors"
                           :name="name"/>
    </Field>
</template>

<script lang="ts">
import { computed, defineComponent, ref, Ref, toRef, watch } from 'vue';
import { Field } from 'vee-validate';
import { CustomInputFieldInternalValue } from '@/core/forms/vee-validate.types';

export default defineComponent({
    name: 'InputRadio',
    components: { Field },
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Object, Boolean, Number],
            required: false,
            default: '',
        },
        // eslint-disable-next-line vue/require-default-prop
        modelValue: {
            type: null,
        },
        label: {
            type: String,
            default: '',
        },
        htmlLabel: {
            type: String,
            default: '',
        },
        showErrors: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        'update:modelValue': (evt) => !!evt,
    },
    setup(props, { emit }) {
        const internalValue: Ref<CustomInputFieldInternalValue> = ref(null);

        const fieldId = computed(() => {
            return props.id || props.name + Math.random();
        });

        const isModelBound = computed(() => {
            return 'modelValue' in props;
        });

        if (isModelBound.value) {
            const modelValue = toRef(props, 'modelValue');
            watch(modelValue, newModelValue => {
                if (newModelValue !== internalValue.value) {
                    internalValue.value = newModelValue;
                }
            }, { immediate: true });
            watch(internalValue, newInternalValue => {
                emit('update:modelValue', newInternalValue);
            });
        }

        return {
            fieldId,
            internalValue,
        };
    },
});
</script>
