import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './styling/main.css';
import coreConfig from './core';
import projectConfig from './project';
import loadDictionary from '@/core/dictionary/dictionaryLoader';
import { getBasket } from './project/basket/basket.service';
import { createHead } from '@vueuse/head';

// Load fastlane (prefetch etc.) in own chunk and execute before / while vue initializes
import('./project/fastlane');

const app = createApp(App);
const head = createHead();

/* eslint-disable promise/no-nesting */
coreConfig(app)
    .then(() => {
        projectConfig(app)
            .then(() => [app.use(router), app.use(head)]);

        Promise.all([loadDictionary(), router.isReady()])
            .then(() => {
                getBasket();
                app.mount('#app');
            });
    });
