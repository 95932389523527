<template>
    <article v-if="HTMLElements?.length">
        <div v-html="HTMLElements[0].innerHTML"></div>
    </article>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue';
import { HtmlBlock } from '@/api/content';

export default defineComponent({
    name: 'HTMLBlock',
    props: {
        model: {
            required: true,
            type: Object as PropType<HtmlBlock>,
        },
    },
    setup(props) {
        let scripts: HTMLElement[] = [];
        let HTMLElements: HTMLElement[] = [];
        const parser = new DOMParser();
        // Create DOM Document from string of HTML and scripts
        const doc: Document | undefined = props.model.htmlContent ? parser.parseFromString(base64DecodeUnicode(props.model.htmlContent), 'text/html') : undefined;

        // Set HTMLElements so that they can be rendered in component
        HTMLElements = doc ? Array.from(doc.querySelectorAll('*')) : [];
        // Set scripts to evaluate onMounted
        scripts = doc ? Array.from(doc.querySelectorAll('script')) : [];

        function base64DecodeUnicode(str) {
        // Decode base64 to original string
            return decodeURIComponent(atob(str).split('').map(c => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        }

        onMounted(() => {
            if (scripts?.length) {
                scripts.forEach(script => {
                    //Evaluate script from content
                    Function(script.innerHTML)(); 
                });
            }
        });

        return {
            HTMLElements,
            scripts,
            doc,
        };
    },
});
</script>