<template>
    <div class="flex relative z-1 flex-col min-h-full max-page:shadow-page o-container"
         :style="backgroundColorStyle">
        <MainNavigation/>
        <RouterView v-slot="{ Component }">
            <Transition name="fade"
                        mode="out-in"
                        v-on="transitionHooks">
                <PageRenderer :key="key"
                              :page="Component"/>
            </Transition>
        </RouterView>
        <Footer :class="syncedTransitionClasses"
                class="mt-auto"/>
    </div>
    <ErrorToaster/>
    <DialogHost/>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import ErrorToaster from '@/core/messages/ErrorToaster.vue';
import MainNavigation from '@/project/navigation/main-menu/MainNavigation.vue';
import { useTransitionSync } from '@/project/animation/transitionSyncComposable';
import Footer from '@/project/navigation/footer/Footer.vue';
import { useRoute } from 'vue-router';
import DialogHost from '@/project/dialog/DialogHost.vue';
import { RouterMetaData } from './router';
import PageRenderer from './project/content/PageRenderer.vue';
import useTheme from '@/core/theme/useTheme';

export default defineComponent({
    name: 'App',
    components: {
        ErrorToaster,
        MainNavigation,
        Footer,
        DialogHost,
        PageRenderer,
    },
    setup() {
        const key = ref('');
        const route = useRoute();
        watch(route, route => {
            // Only update key if new route is server-side (thus reinitializing CmsProxyPage for every BE page)
            if ((route.meta as RouterMetaData).isServerPage) {
                key.value = route.path;
            }
        });

        const {
            transitionHooks,
            syncedTransitionClasses,
        } = useTransitionSync('fade');

        const { themeBackgroundColor } = useTheme('#FFFFFF');
        const backgroundColorStyle = computed(() => ({
            backgroundColor: themeBackgroundColor.value,
        }));

        return {
            key,
            transitionHooks,
            syncedTransitionClasses,
            backgroundColorStyle,
        };
    },
});
</script>
