import Error404Page from '@/project/pages/_404Page.vue';
import Error500Page from '@/project/pages/_500Page.vue';
import bus from '@/core/bus';
export const SHOW_ERROR_PAGE = 'ShowErrorPage';

export function show404() {
    bus.emit(SHOW_ERROR_PAGE, Error404Page);
}

export function show500() {
    bus.emit(SHOW_ERROR_PAGE, Error500Page);
}
