<template>
    <div>
        <component :is="pageComponent"
                   v-if="pageComponent && pageModel"
                   :model="pageModel.content"
                   :alias="pageModel.alias"/>
        <div v-if="pageModel?.metadata.seoJsonLd"
             v-html="pageModel?.metadata.seoJsonLd"></div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, Ref, shallowRef } from 'vue';
import { useRoute } from 'vue-router';
import contentApi from '@/project/content/api/contentApi';
import { pageResolver } from '@/core/content/componentResolver';
import HttpStatus from 'http-status-codes';
import { HttpServerError } from '@/core/http/HttpServerError';
import { HttpNoServerResponse } from '@/core/http/HttpNoServerResponse';
import logging from '@/core/infrastructure/logging';
import bus from '@/core/bus';
import { NEW_PAGE_SET } from '@/core/content/constants';
import { PageData } from '@/api/content/models';
import { show404, show500 } from '../../project/content/errorPage';
import { useHead } from '@vueuse/head';

export const pageCache = new Map<string, PageData>();

export default defineComponent({
    name: 'CmsPageProxy',
    setup() {
        const pageData: {
            pageComponent: Ref,
            pageModel: Ref<PageData | undefined>
        } = {
            pageComponent: shallowRef(undefined),
            pageModel: shallowRef(undefined),
        };

        const siteData = computed(()=> {
            return {
                title: pageData.pageModel.value?.metadata.name,
                description: pageData.pageModel.value?.metadata.seoDescription,
                image: pageData.pageModel.value?.metadata.seoImage,
                url: pageData.pageModel.value?.metadata.url,
                robots: pageData.pageModel.value?.metadata.robotMetaTags,
                lanugages: pageData.pageModel.value?.metadata.languages,
            };
        });

        const metaDataTags = computed(()=> {
            return [
                {
                    name: 'description',
                    content: siteData.value.description,
                },
                {
                    property: 'og:description',
                    content: siteData.value.description,
                },
                {
                    property: 'og:title',
                    content: siteData.value.title,
                },
                {
                    property: 'og:image',
                    content: siteData.value.image,
                },
                {
                    property: 'robots',
                    content: siteData.value.robots,
                },
            ];
        });

        const metaLinkTags = computed(()=> {
            return [
                {
                    rel: 'canonical',
                    href: siteData.value.url,
                },
            ];
        });

        const openGraphLang = computed(()=> {
            const lang = siteData.value.lanugages?.map(lang => {
                return {
                    property: 'og:url',
                    content: lang.language,
                    key: lang.language,
                };
            });
            return lang;
        });
        
        const hrefLang = computed(()=> {
            const data = siteData.value.lanugages?.map(lang => {
                return {
                    rel: 'alternate',
                    hreflang: lang.language,
                    href: lang.url,
                    key: lang.language,
                };
            });
            return data;
        });

        const meta = computed(() => {
            if (openGraphLang.value) {
                return [...metaDataTags.value, ...openGraphLang.value];
            }
            return metaDataTags;
        });

        const link = computed(() => {
            if (hrefLang.value) {
                return [...metaLinkTags.value, ...hrefLang.value];
            }
            return metaLinkTags;
        });

        const headObj = {
            title: computed(() => siteData.value.title),
            meta,
            link,
        };

        const { path } = useRoute();
        (async() => {
            try {
                let page = pageCache.get(path);
                if (page) {
                    // Stale while revalidate pattern
                    resolveAndSetPage(path, page.alias, page);
                }
                page = await contentApi.getPage(path);
                resolveAndSetPage(path, page.alias, page);
            } catch (error) {
                if (error instanceof HttpServerError) {
                    switch (error.response.status) {
                    case HttpStatus.NOT_FOUND: {
                        show404();
                        break;
                    }
                    case HttpStatus.INTERNAL_SERVER_ERROR: {
                        show500();
                        logging.exception(`http-status 500 on path: ${path}`, error);
                        break;
                    }
                    }
                } else if (error instanceof HttpNoServerResponse) {
                    show500();
                    logging.error(`No server-response on path: ${path}`);
                }
            }
        })();

        function resolveAndSetPage(path: string, alias: string, page: PageData) {
            pageData.pageComponent.value = pageResolver.resolve(alias);
            pageData.pageModel.value = page;
            pageCache.set(path, page);
            bus.emit(NEW_PAGE_SET);
        }

        useHead((headObj as any));

        return pageData;
    },
});
</script>
