<template>
    <ul role="menubar"
        class="font-fauli text-16 md:flex md:items-center md:text-14 lg:text-16 list-reset">
        <li v-for="(item, index) in menuitems"
            :key="index"
            role="menuitem"
            aria-haspopup="menu"
            :aria-expanded="menuItemsIsExpanded(item)"
            class="relative z-0 hover:z-1 md:shrink-0 md:last:pr-0 md:h-[6.3rem]">
            <component :is="getComponent(item.alias)"
                       :model="item"/>
        </li>
    </ul>
</template>

<script lang="ts">
import { defineComponent, ref, defineAsyncComponent } from 'vue';
import contentApi from '@/project/content/api/contentApi';
import ContentBlock from '@/core/content/ContentBlock.vue';
import bus from '@/core/bus';

interface IMenuItem {
    alias: string;
    name: string;
    url: string;
}

export default defineComponent({
    name: 'MenuItems',
    components: {
        ContentBlock,
    },
    setup() {
        const menuitems = ref<IMenuItem[]>([]);
        const navigationItem = defineAsyncComponent(() => import('@/project/navigation/main-menu/NavigationItem.vue'));
        const navigationItemGroup = defineAsyncComponent(() => import('@/project/navigation/main-menu/NavigationItemGroup.vue'));

        let nameOfActiveMenu = ref('');
        let isShowingSubMenu = ref(false);

        function getComponent(alias) {
            if (alias === 'navigationItem') return navigationItem;
            else if (alias === 'navigationItemGroup') return navigationItemGroup;
        }

        function menuItemsIsExpanded(item: any){
            let isExpanded = false; 
            if(nameOfActiveMenu.value === item.name && isShowingSubMenu.value === true ? true : false){
                isExpanded = true; 
            }

            return isExpanded; 
        }
        
        bus.on('IsShowingSubMenu', (showSubMenuValues) => {
            nameOfActiveMenu.value = showSubMenuValues.name; 
            isShowingSubMenu.value = showSubMenuValues.showSubMenu; 
        });

        contentApi.settings.then(settings => {
            menuitems.value = {
                ...settings.mainNavigation,
            } as IMenuItem[];
        });
        return {
            menuitems,
            getComponent,
            nameOfActiveMenu,
            isShowingSubMenu,
            menuItemsIsExpanded,
        };
    },
});
</script>

<style scoped>

</style>
