<template>
    <div>
        <div v-if="footerContactOptionData.selectedOption === 'Contact option 1' && footerContactOptionData.email !== ''">
            <div class="inline-block relative mb-20 md:mb-10">
                <MailBg/>
                <div class="flex flex-col w-full center-content">
                    <a :href="'mailto:' + footerContactOptionData.email"
                       class="mb-auto font-fauli text-11"
                       :class="[{'mt-23': footerContactOptionData.icon1 === null}]">
                        <img v-if="footerContactOptionData.icon1"
                             v-on-error
                             :src="footerContactOptionData.icon1"
                             class="block mx-auto mt-4 -mb-1 w-32 h-32"
                             loading="lazy">
                        {{ footerContactOptionData.email }}
                    </a>
                </div>
            </div>
        </div>
        <div v-if="footerContactOptionData.selectedOption === 'Contact option 2' && footerContactOptionData.link !== null">
            <div class="inline-block relative mb-20 md:mb-10">
                <MailBg/>
                <div class="flex flex-col w-full center-content">
                    <a :href="footerContactOptionData.link.url"
                       class="mb-auto font-fauli text-11"
                       :class="[{'mt-23': footerContactOptionData.icon2 === null}]">
                        <img v-if="footerContactOptionData.icon2"
                             v-on-error
                             :src="footerContactOptionData.icon2"
                             class="block mx-auto mt-4 -mb-1 w-32 h-32"
                             loading="lazy"
                             :width="40"
                             :height="40">
                        {{ getContactOption2LinkText() }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import contentApi from '@/project/content/api/contentApi';
import { ContactOption } from '@/api/content/models/contact-option';
import MailBg from '@/assets/images/MailBg.vue';
type IContactOption = Pick<ContactOption, 'email' |'icon1' | 'link' | 'icon2' | 'linkText' | 'selectedOption'>

export default defineComponent({
    name: 'FooterContactOption',
    components: {
        MailBg,
    },
    setup() {
        const footerContactOptionData = ref<IContactOption>({
            email: null,
            icon1: null,
            link: null,
            icon2: null,
            linkText: null,
            selectedOption: null,
        });

        contentApi.settings.then(settings => {
            footerContactOptionData.value = {
                ...settings.contactOption,
            } as IContactOption;
        });

        function getContactOption2LinkText(){
            return footerContactOptionData.value.linkText ? footerContactOptionData.value.linkText : footerContactOptionData.value.link?.name;
        }

        return {
            footerContactOptionData,
            getContactOption2LinkText,
        };
    },
});
</script>

<style scoped>
    .center-content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 189px;
        height: 60px;
        transform: translate(-50%, -50%);
    }
</style>
