
import { ref } from 'vue';
import { cmsHost } from '@/core/infrastructure/environment';
import { StoresApiFactory } from '@/api/content/apis/stores-api';
import { getCultureSettings } from '@/project/content/api/contentApi';
import { StoreViewObject } from '@/api/content/models';

const culturePromise = getCultureSettings().then(settings => settings.culture);

const storesApi = StoresApiFactory({
    basePath: cmsHost,
    isJsonMime: () => true,
});

const stores = ref<StoreViewObject[]>();
export async function getStores(): Promise<StoreViewObject[]> {
    

    if(!stores.value?.length) 
    {
        const culture = await culturePromise;

        stores.value = (await storesApi.storesGet(culture)).data;
        
    }

    return stores.value;

}